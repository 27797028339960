import React from 'react'
import { Header } from './Header';
import Footer from './Footer';
import { PopupWidget, InlineWidget } from 'react-calendly';





const Privacy = () => {
    return (
        <>
            <Header />
            <div className='section-term-and-conditions'>

                <div class="section-title mb-70 text-center d-flex flex-column "><h2 class="title-h2 text-upper">Privacy Policy </h2>
                </div>
                <div className='term-condition-div intoductions'>
                    <h3>
                        1. Introduction

                    </h3>
                    <h6>1.a About this Policy</h6>
                    <p>
                        "www.itreturnbharo.com" is a web domain name owned and operated by IT Return Bharo , Located at

                        B2, 204B, Kanakia Boomerang, Chandivali, Andheri East, Mumbai - 400072

                        Contact: 83808 94711
                    </p>
                    <p>

                        Email: itreturnbharo@gmail.com
                    </p>
                    <p>
                        The terms 'I' /'We' /'Us' /'Our' /'Company' /IT Return Bharo individually and collectively refer to IT Return Bharo and the terms 'You' /'Your' /'Yourself' /'User' refer to the users.
                    </p>
                    <p>
                        This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.
                    </p>
                    <p>
                        This Website "www.itreturnbharo.com" is a Paid Website and built by StepFly Innovations Pvt. Ltd. Services of Right Institute is provided by Us at cost and is intended for use as is.
                    </p>
                    <p>
                        This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                    </p>
                    <p>
                        Please read the following Privacy Policies carefully. By using the Website, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this Website.
                    </p>
                    <p>
                        The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy
                    </p>
                    <p>
                        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at "www.itreturnbharo.com" unless otherwise defined in this Privacy Policy
                    </p>

                </div>
                <div className='term-condition-div'>
                    <h4>
                        2. Information Collection and Use


                    </h4>
                    <h6>2.a User Information</h6>
                    <p>
                        For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to

                        Full Name, Phone Number, Email ID, Exam You are Preparing For, Password, Profile Image.

                        The Information as supplied by the users enables us to improve our platform and provide you the most user-friendly experience. All required information is service dependent and we may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services. Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.

                        The Website does use third party services that may collect information used to identify you.
                    </p>
                    <p>
                        Link to privacy policy of third party service providers used by the Website:
                    </p>
                    <p>
                        Google Play Services: https://www.google.com/policies/privacy/
                    </p>
                    <p>
                        Google Analytics for Firebase:https://firebase.google.com/policies/analytics
                    </p>
                    <p>
                        Firebase Crashlytics: https://firebase.google.com/support/privacy/
                    </p>
                    <h6>
                        2.b Log Data
                    </h6>
                    <p>
                        We want to inform you that whenever you use our Service, in a case of an error in the Website we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the Website when utilizing my Service, the time and date of your use of the Service, and other statistics.
                    </p>
                    <h6>
                        2.c Phone Number Use and Share
                    </h6>
                    <p>
                        Phone Number provided by you ensures that the account is uniquely created and there is not any other duplicate account with the same phone number. To facilitate this functionality we use a service called “Firebase Authentication Service” provided by Google LLC. The phone number provided by you for authentication will be sent and stored by Google to improve our spam and abuse prevention across Google services, including but not limited to Firebase. If you do not agree with the above terms of this Privacy Policy, please do not use this Website.
                    </p>
                </div>
                <div className='term-condition-div'>
                    <h4>
                        3. Cookies



                    </h4>
                    <h6>3.a What are cookies?</h6>
                    <p>
                        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                    </p>
                    <h6>3. b How IT Return Bharo use cookies?
                    </h6>
                    <p>
                        This Service does not use these “cookies” explicitly. However, the Website may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                    </p>
                </div>
                <div className='term-condition-div'>
                    <h4>
                        4. Third Party Service Providers

                    </h4>
                    <h6>                4.a Employing third-party companies and individual
                    </h6>
                    <p>
                        We may employ third-party companies and individuals due to the following reasons:
                    </p>
                    <p>
                        To facilitate our Service;
                    </p>
                    <p>
                        To provide the Service on our behalf;
                    </p>
                    <p>
                        To perform Service-related services; or
                    </p>
                    <p>
                        To assist us in analyzing how our Service is used.
                    </p>


                    <h6>   4.b How your information is shared
                    </h6>
                    <p>
                        We shares the sensitive personal information to any third party without obtaining the prior consent of the user in the following limited circumstances:
                    </p>
                    <p>
                        (a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.
                    </p>
                    <p>
                        (b) We proposes to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.

                    </p>

                </div>


                <div className='term-condition-div'>
                    <h4>
                        5. Security
                    </h4>
                    <h6>                     5.a Measures taken

                    </h6>
                    <p>
                        We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.

                    </p>
                    `       `


                    <h6> 5.b Access to information
                    </h6>
                    <p>
                        All information gathered on our Website is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access.
                    </p>



                </div>

                <div className='term-condition-div'>
                    <h4>
                        6. Links to Other Sites



                    </h4>
                    <h6>    6.a Advisory for third party platforms
                    </h6>
                    <p>
                        Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. we have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    </p>


                </div>

                <div className='term-condition-div'>
                    <h4>
                        7. Future Changes





                    </h4>
                    <h6>   7.a Incorporating Future Changes
                    </h6>
                    <p>

                        However the internet is an ever evolving medium. We may change our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. You are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>
                    <p>
                        These Privacy Policies are effective as of 1st Feb 2023                    </p>


                </div>

                <div className='term-condition-div'>
                    <h4>
                        Grievance Redressal


                    </h4>

                    <p>
                        Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email to: CA Pranay Bafna ('Grievance Officer')
                    </p>
                    <p>
                        CA Pranay Bafna (Grievance Officer)
                    </p>
                    <p>
                        Company Name & Address: IT Return Bharo, Registered Address: B2, 204B, Kanakia Boomerang, Chandivali, Andheri East, Mumbai - 400072
                    </p>
                    <p>
                        Contact: 83808 94711
                    </p>
                    <p>
                        Email: itreturnbharo@gmail.com             </p>


                </div>
            </div>
            <Footer />

        </>
    )
}

export default Privacy;