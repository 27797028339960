import React from "react";
import { Header } from "./Header";
import Footer from "./Footer";

export const PremiumPlan = () => {
  return (
    <>
      <Header />
      <header id="dsn_header" class="dsn-header-animation header-normal">
        <div class="background-overlay bg-pattern p-absolute left-0 top-0 w-100 h-100"></div>

        <div class="entry-header p-relative over-hidden">
          <div
            id="hero_content"
            class="d-flex p-relative h-100 dsn-hero-parallax-title container plan-main-card pb-section pb-0 "
          >
            <div class="content p-relative ">
              <div class="intro-project w-100">
                <div class="intro-title ">
                  {/* <p class="dsn-heading-title d-inline-block circle-before">BASIC PLAN </p> */}
                  <div id="hero_title" class="mt-10">
                    <h1 class="text-left">
                      Premium Plan:
                      <br />
                    </h1>
                    <h5>
                      Price <del>10000</del> – Dis. Price 8499 Rs
                    </h5>
                    <h5>
                      Price <del>15000</del> – Dis. Price 12749 Rs
                    </h5>
                  </div>
                  <p class="mt-20 max-w570">
                    <h4 className="mt-4">Key Features</h4>
                    <div class="list-style1">
                      <ul>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Basic
                          Plan is for the person who wants to plan his taxes.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>
                          Guide on investments products
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Ideal
                          for salaried person.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Salary
                          Unlimited Tax Planning session with CA
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Salary
                          restructuring for salary optimization.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>
                          Financial Planning and Goal Setting
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>
                          Analyzing Existing Por􀆞olio
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>
                          Advance Tax Management
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Free
                          Lancing Income
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>
                          Foreign Income/Exposure like stock units,etc
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Free
                          ITR Filing
                        </li>
                      </ul>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div class="root-blog container section-margin  dsn-blog margin-decrease-margin">
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">PURPOSE AND SCOPE </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        The Premium Plan is designed for individuals seeking not
                        only tax planning but also comprehensive financial
                        management. It caters to salaried individuals with an
                        annual income of up to 50 Lakhs who want to optimize
                        their financial por􀆞olio.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">
                        Tax Planning and Investment Guidance{" "}
                      </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        The Premium Plan covers tax planning as its core
                        offering but goes beyond by providing guidance on
                        various investment products. It offers a well-rounded
                        approach to managing your finances, considering both tax
                        efficiency and wealth creation.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">
                        Unlimited Tax Planning Sessions with CA{" "}
                      </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        One of the standout features of the Premium Plan is the
                        provision of unlimited tax planning sessions with a
                        Chartered Accountant (CA). This allows you to have
                        ongoing discussions and fine-tune your tax strategies as
                        your financial situation evolves.
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">
                        Salary Restructuring for Salary Optimization{" "}
                      </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        Similar to the other plans, the Premium Plan includes
                        salary restructuring to maximize your earnings while
                        minimizing tax liabilities.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">Advance Tax Management </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        With the Premium Plan, you benefit from advanced tax
                        management services. This can involve strategies such as
                        tax-efficient investments, deductions, and credits to
                        optimize your tax burden.
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">
                        Financial Planning and Retirement Planning{" "}
                      </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        Strategic financial planning, including comprehensive
                        retirement planning, is crucial for ensuring a stable
                        and fulfilling financial future.
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">Free ITR Filing </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        Just like the other plans, the Premium Plan includes
                        free Income Tax Return (ITR) filing services.
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              {/*<p className="mt-5 summart-text">
                <b> In summary, the Basic Plan is a comprehensive package</b>{" "}
                tailored for salaried individuals looking to proactively manage
                their taxes and improve their financial well-being. It includes
                expert guidance, salary optimization, and the convenience of
                free ITR filing, all aimed at ensuring you make the most of your
                income while complying with tax laws.
              </p>*/}
            </div>
          </div>
          <div class="container">
            <div class="d-grid grid-md-2" data-dsn-gap="30px 60px">
              <div class="left">
                <h5 class="title-h2 body-font text-upper">
                  <b>inclusion</b>
                </h5>
                <p class="mt-20">
                  <div class="list-style1">
                    <ul>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Income
                        from House Rent.
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Capital
                        gain.
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Crypto
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Salary
                        from multiple Employer
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Other
                        source income like (interest, FD, etc)
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Free
                        Lancing income
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Foreign
                        Income/Exposure like stock units,etc
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
              <div class="right align-self-end">
                <h5 class="title-h2 body-font text-upper">
                  <b> Non inclusion</b>
                </h5>
                <p class="mt-20">
                  <div class="list-style1">
                    <ul>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>CA
                        Certification
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Business
                        Statements/Books of Accounts
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#page_wrapper"
          rel="nofollow"
          class="dsn-scroll-bottom"
          data-dsn-option='{"ease": "power4.inOut" , "duration" : 1.5}'
        >
          <div class="text">SCROLL</div>
        </a>
      </header>

      <Footer />
    </>
  );
};
