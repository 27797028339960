import React from "react";
import { Header } from "../Header";
import Footer from "../Footer";
export const Page1 = ({ formData, setForm, navigation }) => {
  const { username, email } = formData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigation.next();
  };
  return (
    <>
      <Header />
      <div className="form-steppers mb-5">
        <form
          onSubmit={handleSubmit}
          // style={{ width: "30%" }}
          encType="multipart/form-data"
          autoComplete="new-password"
          className="form-floating form-auto1 mb-3 "
        >
          <div className="form-floating"></div>
          <div className="h2-file-itr">
            <h2>FILE YOUR IT RETURN IN 3 SIMPLE STEPS</h2>
          </div>
          <div className="steps-file-itr">
            <div className="main-steps-files">
              <div className="round-borders">
                <p></p>
                <p>-----------------------</p>
              </div>
              <div className="content-file-itr">
                <h2>Link PAN</h2>
                <p>
                  to get started with
                </p>
                <p>
                  your Filing</p>
              </div>
            </div>
            <div className="main-steps-files">
              <div className="round-borders">
                <p></p>
                <p>-----------------------</p>
              </div>
              <div className="content-file-itr">
                <h2>Add Details</h2>
                <p> Add income,</p>
                <p> deductions & other</p>
                <p>relevant info.</p>
              </div>
            </div>
            <div className="main-steps-files">
              <div className="round-borders">
                <p></p>
              </div>
              <div className="content-file-itr">
                <h2>File ITR
                </h2>
                <p>
                  & get</p>
                  <p>
                  acknowledgement
                  </p>
                  <p>
                  from ITD</p>
              </div>
            </div>
          </div>
          {/* <div className="form-floating mb-3">
            <input
              name="username"
              value={username}
              onChange={setForm}
              type="text"
              required
              className="form-control"
              placeholder="Your Full Name"
              autoComplete="off"
            />
            <label htmlFor="floatingInput">Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              onChange={setForm}
              type="email"
              name="email"
              required
              value={email}
              className="form-control"
              placeholder="yourmail@mail.com"
              autoComplete="new-password"
            />
            <label htmlFor="floatingInput">Email Address</label>
          </div> */}
          <input type="submit" value="next"
            className="form-control start-fil-butn"
          />
        </form>
      </div>
      <Footer />

    </>
  );
};
