import React, { useState } from 'react'
import { useForm, useStep } from "react-hooks-helper";
import PlanLife from './PlanLife';
import PlanAssets from './PlanAssets';
import PlanMonthlyIncome from './PlanMonthlyIncome';
import PlanGoals from './PlanGoals';

export const PlanYorLife = () => {
    const [defaultData, setDefaultData] = useState({
        username: "",
        email: "",
        dob: "",
        password: "",
        address: ""
      })
      const steps = [
        { id: "Page1" },
        { id: "Page2" },
        { id: "Page3" },
        { id: "Page4" },
    
      ];
      const [formData, setForm] = useForm(defaultData);
      const { step, navigation } = useStep({
        steps,
        initialStep: 0,
      });
      const props = { formData, setForm, navigation };
    
    
      switch (step.id) {
        case "Page1":
          return <PlanLife {...props} />;
        case "Page2":
          return <PlanAssets {...props} />;
        case "Page3":
          return <PlanMonthlyIncome {...props} />;
        case "Page4":
          return <PlanGoals {...props} />;
    
      }
  return (
    <div>PlanYorLife</div>
  )
}
