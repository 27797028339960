import React from "react";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
//import zanwar from "../assets/img/team/1.jpg";
//import shankar from "../assets/img/team/2.jpg";
import naik from "../assets/img/team/naik.jpg";
import chandrika from "../assets/img/team/chandrika.jpg";
import modak from "../assets/img/team/modak.jpg";
import anup from "../assets/img/team/anup.jpg";
import rode from "../assets/img/team/rode.jpg";
import rekhapalli from "../assets/img/team/6.png";

function SimpleNextArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div class="dsn-swiper-paginate  d-flex p-relative w-100 h-100 mt-30 align-items-center  justify-content-between slick-slider-next">
        <div
          class="swiper-pagination  mr-30 ml-30 heading-color"
          data-dsn-type="bullets"
        ></div>
        <div class="swiper-next" onClick={onClick}>
          <div class="next-container">
            <div class="container-inner">
              <div class="triangle"></div>
              <svg
                class="circle"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g
                  class="circle-wrap"
                  fill="none"
                  stroke-width="1"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle cx="12" cy="12" r="10.5"></circle>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SimplePrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div class="dsn-swiper-paginate  d-flex p-relative w-100 h-100 mt-30 align-items-center container justify-content-between slick-slider-prev">
        <div class="swiper-prev">
          <div class="prev-container" onClick={onClick}>
            <div class="container-inner">
              <div class="triangle"></div>
              <svg
                class="circle"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <g
                  class="circle-wrap"
                  fill="none"
                  stroke-width="1"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle cx="12" cy="12" r="10.5"></circle>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Testimonials = () => {
  const Testsliders = {
    infinite: true,
    // dots: true,
    slideToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    nextArrow: <SimpleNextArrow />,

    prevArrow: <SimplePrevArrow />,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          autoplay: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 993,
        settings: {
          autoplay: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: false,
          slidesToShow: 1,

          arrows: false,
          dots: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: true,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <>
      <div class="about-award dsn_testimonial mb-70 section-padding background-section aadil">
        <div class="container d-grid grid-half-1">
          <div class="box-left pr-lg-100">
            <Fade bottom>
              <div class="section-title d-flex flex-column">
                <h2 class="title-h2 text-upper">Client Success Stories</h2>
                <p class="max-w570 mt-10 border-section-bottom">
                  Real Dreams, Real People, Real Success
                </p>
              </div>
              <p class="mt-20 fw-bold">
                Discover how our clients have transformed their financial
                futures with our guidance. Their success stories are our
                greatest pride
              </p>
            </Fade>
          </div>
          <Fade bottom>
            <div class="box-right">
              <div
                class="elementor-widget-dsn_testimonial"
                data-widget_type="dsn_testimonial.default"
              >
                <div class="dsn-testimonials dsn-swiper p-relative has-parallax-image">
                  <div class="testimonials-content">
                    <div class="testimonial-inner">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 509 396.83"
                        enable-background="new 0 0 509 396.83"
                      >
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M105.098,396.83c-2.062,0-4.122,0-6.183,0 c0.123-48.731,0.116-97.466,0.493-146.195c0.062-7.844-3.65-8.881-10.09-8.843c-29.772,0.182-59.545,0.047-89.318,0.026 C0,161.382,0,80.947,0,0c81.742,0,163.484,0,245.227,0c0.023,83.874,0.496,167.752-0.071,251.624 c-0.436,64.131-47.354,121.936-109.503,136.974C125.404,391.076,115.279,394.073,105.098,396.83z M127.891,360.509 c6.063-1.545,10.049-2.226,13.799-3.568c42.174-15.098,74.277-58.896,74.735-104.55c0.718-71.273,0.187-142.558,0.454-213.837 c0.03-7.696-2.596-10.07-10.136-10.038c-55.961,0.236-111.927,0.287-167.887-0.042c-8.413-0.049-10.398,3.014-10.363,10.831 c0.254,54.408,0.317,108.815-0.047,163.22c-0.061,8.994,3.321,10.93,11.452,10.803c25.744-0.405,51.505,0.184,77.245-0.329 c8.78-0.174,10.986,2.931,10.911,11.301c-0.363,40.63-0.164,81.264-0.163,121.897C127.891,350.262,127.891,354.325,127.891,360.509 z"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M368.871,396.83c-2.061,0-4.122,0-6.184,0 c0.057-48.133-0.099-96.267,0.354-144.395c0.081-8.996-3.268-10.854-11.386-10.722c-26.047,0.42-52.108-0.083-78.152,0.299 c-7.577,0.108-10.083-2.194-10.053-9.942c0.294-77.357,0.262-154.713,0.322-232.07C345.353,0,426.929,0,509,0 c0,89.562,0,179.125,0,268.687c-1.256,3.464-2.793,6.854-3.73,10.401c-15.376,58.112-51.126,95.756-109.9,110.431 C386.48,391.735,377.7,394.382,368.871,396.83z M391.554,360.501c5.951-1.528,9.958-2.176,13.686-3.57 c43.103-16.135,74.464-59.44,74.864-105.611c0.616-70.931,0.138-141.869,0.404-212.803c0.03-7.722-2.58-10.037-10.111-10.005 c-55.963,0.24-111.926,0.285-167.889-0.036c-8.406-0.048-10.429,3.048-10.392,10.853c0.245,54.405,0.31,108.813-0.049,163.216 c-0.059,9.03,3.45,10.9,11.528,10.778c25.741-0.39,51.504,0.193,77.245-0.325c8.785-0.178,10.956,2.917,10.878,11.284 c-0.368,40.629-0.167,81.263-0.165,121.897C391.554,350.25,391.554,354.318,391.554,360.501z"
                          />
                        </g>
                      </svg>
                      <div class="">
                        <div class="testimonial-slider-card">
                          <Slider {...Testsliders}>
                            <div class=" testimonial-inner-item background-main">
                              <div class="testimonial-item">
                                <div class="testimonial-content mb-25">
                                  <div class="quote">
                                    <p class="max-w750 testimonial-content p-large">
                                      I express my sincere gratitude for the
                                      outstanding professional and helpful
                                      service I received. The support surpassed
                                      my expectations, showcasing a commitment
                                      to excellence. I highly recommend this
                                      service for its dedication to providing
                                      top-notch assistance. Thank you for an
                                      exceptional experience
                                    </p>
                                  </div>
                                  <div class="content-inner border-top">
                                    <div class="d-flex align-items-center ">
                                      <div class="avatar box-img dsn-auto">
                                        <img
                                          src={naik}
                                          className="cover-bg-img"
                                          alt=""
                                        />
                                      </div>
                                      <div class="label box-text">
                                        <h4 class="testimonial-name sm-title-block">
                                          Onkar Naik
                                        </h4>
                                        <h5 class="testimonial-position">
                                          Google
                                        </h5>
                                        <span className="social-icons">
                                          <a
                                            href="https://www.linkedin.com/in/onkar17/"
                                            target="_blank"
                                            rel=""
                                            class=""
                                          >
                                            <span class="dsn-icon">
                                              <i class="fab fa-linkedin-in"></i>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class=" testimonial-inner-item background-main">
                              <div class="testimonial-item">
                                <div class="testimonial-content mb-25">
                                  <div class="quote">
                                    <p class="max-w750 testimonial-content p-large">
                                      Pranay's meticulous planning and clear
                                      explanations make him stand out. He is
                                      readily accessible, ensuring effective
                                      communication. His commitment to detail
                                      and accessibility enhance the overall
                                      experience, making him a reliable and
                                      valuable professional
                                    </p>
                                  </div>
                                  <div class="content-inner border-top">
                                    <div class="d-flex align-items-center ">
                                      <div class="avatar box-img dsn-auto">
                                        <img
                                          src={chandrika}
                                          className="cover-bg-img"
                                          alt=""
                                        />
                                      </div>
                                      <div class="label box-text">
                                        <h4 class="testimonial-name sm-title-block">
                                          Chandrika
                                        </h4>
                                        <h5 class="testimonial-position">
                                          Microsoft
                                        </h5>
                                        <span className="social-icons">
                                          <a
                                            href="https://www.linkedin.com/in/kurla-chandrika-70084417a"
                                            target="_blank"
                                            rel=""
                                            class=""
                                          >
                                            <span class="dsn-icon">
                                              <i class="fab fa-linkedin-in"></i>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class=" testimonial-inner-item background-main">
                              <div class="testimonial-item">
                                <div class="testimonial-content mb-25">
                                  <div class="quote">
                                    <p class="max-w750 testimonial-content p-large">
                                      Your service is a constant source of
                                      relief for me. Each consultation lifts a
                                      burden from my shoulders. Your consistent
                                      helpfulness and trustworthiness have
                                      earned my deep appreciation. Thank you for
                                      your invaluable support.
                                    </p>
                                  </div>
                                  <div class="content-inner border-top">
                                    <div class="d-flex align-items-center ">
                                      <div class="avatar box-img dsn-auto">
                                        <img
                                          src={modak}
                                          className="cover-bg-img"
                                          alt=""
                                        />
                                      </div>
                                      <div class="label box-text">
                                        <h4 class="testimonial-name sm-title-block">
                                          Neha Modak
                                        </h4>
                                        <h5 class="testimonial-position">
                                          Google
                                        </h5>
                                        <span className="social-icons">
                                          <a
                                            href="https://www.linkedin.com/in/neha-modak-b3a1a8175/"
                                            target="_blank"
                                            rel=""
                                            class=""
                                          >
                                            <span class="dsn-icon">
                                              <i class="fab fa-linkedin-in"></i>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class=" testimonial-inner-item background-main">
                              <div class="testimonial-item">
                                <div class="testimonial-content mb-25">
                                  <div class="quote">
                                    <p class="max-w750 testimonial-content p-large">
                                      Outstanding investment guidance! The
                                      team's expertise and insightful advice
                                      have proven invaluable. They provide clear
                                      direction, empowering informed decisions.
                                      Grateful for their commitment to financial
                                      success. Highly recommend their services
                                      for anyone seeking reliable and strategic
                                      investment guidance.
                                    </p>
                                  </div>
                                  <div class="content-inner border-top">
                                    <div class="d-flex align-items-center ">
                                      <div class="avatar box-img dsn-auto">
                                        <img
                                          src={anup}
                                          className="cover-bg-img"
                                          alt=""
                                        />
                                      </div>
                                      <div class="label box-text">
                                        <h4 class="testimonial-name sm-title-block">
                                          Anup Pathak
                                        </h4>
                                        <h5 class="testimonial-position">
                                          Google
                                        </h5>
                                        <span className="social-icons">
                                          <a
                                            href="https://www.linkedin.com/in/anup-pathak-63126b81/"
                                            target="_blank"
                                            rel=""
                                            class=""
                                          >
                                            <span class="dsn-icon">
                                              <i class="fab fa-linkedin-in"></i>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class=" testimonial-inner-item background-main">
                              <div class="testimonial-item">
                                <div class="testimonial-content mb-25">
                                  <div class="quote">
                                    <p class="max-w750 testimonial-content p-large">
                                      Exceptional financial and tax planning
                                      services! The team's expertise shines
                                      through detailed strategies. Clear
                                      communication and personalized guidance
                                      make navigating complexities seamless.
                                      Grateful for their commitment to securing
                                      financial well-being through effective
                                      planning. Highly recommend their services
                                    </p>
                                  </div>
                                  <div class="content-inner border-top">
                                    <div class="d-flex align-items-center ">
                                      <div class="avatar box-img dsn-auto">
                                        <img
                                          src={rode}
                                          className="cover-bg-img"
                                          alt=""
                                        />
                                      </div>
                                      <div class="label box-text">
                                        <h4 class="testimonial-name sm-title-block">
                                          Madhuri Rode
                                        </h4>
                                        <h5 class="testimonial-position">
                                          BNY Mellon
                                        </h5>
                                        <span className="social-icons">
                                          <a
                                            href="https://www.linkedin.com/in/madhuri-rode-3249723a/"
                                            target="_blank"
                                            rel=""
                                            class=""
                                          >
                                            <span class="dsn-icon">
                                              <i class="fab fa-linkedin-in"></i>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class=" testimonial-inner-item background-main">
                              <div class="testimonial-item">
                                <div class="testimonial-content mb-25">
                                  <div class="quote">
                                    <p class="max-w750 testimonial-content p-large">
                                      I extend my gratitude for the outstanding
                                      service provided by the premium plan.
                                      Every consultation brings relief, and the
                                      consistent support, helpfulness, and
                                      trustworthiness elevate the experience.
                                      Thank you for the exceptional service
                                    </p>
                                  </div>
                                </div>
                                <div class="content-inner border-top">
                                  <div class="d-flex align-items-center ">
                                    <div class="avatar box-img dsn-auto">
                                      <img
                                        src={rekhapalli}
                                        className="cover-bg-img"
                                        alt=""
                                      />
                                    </div>
                                    <div class="label box-text">
                                      <h4 class="testimonial-name sm-title-block">
                                        Sai Vishnu Rekhapalli
                                      </h4>
                                      <h5 class="testimonial-position">
                                        Intuit
                                      </h5>
                                      <span className="social-icons">
                                        <a
                                          href="https://www.linkedin.com/in/vishnuteja28"
                                          target="_blank"
                                          rel=""
                                          class=""
                                        >
                                          <span class="dsn-icon">
                                            <i class="fab fa-linkedin-in"></i>
                                          </span>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div class="section-title mb-70 text-center d-flex flex-column">
        <h2 class="title-h2 text-upper">Facts about us</h2>
        {/*<p class="max-w570 mt-20 border-section-bottom">
          Tailored Solutions for Every Milestone
        </p>*/}
      </div>
      <div class="dsn-award text-center p-relative mb-section background-section">
        <div class="dsn-bg-mask w-100 h-50 bottom-0  background-main z-index-1"></div>
        <div class="section-padding container background-main border-style p-relative z-index-2">
          <div class="d-grid grid-md-4 num-font">
            <div class="award-item">
              <Fade bottom>
                <div className="counter-img">
                  <i class="fa-regular fa-file"></i>
                </div>
                <h5 class="font-number dsn-animate-number">
                  <span class="animate-number">5000</span>+
                </h5>
                <h4 class="title-block mt-10">Returns filed</h4>
              </Fade>
              {/*<p class="sm-p mt-10">
                A diverse and global community of independent thinkers make up
                our inclusive workplace.
              </p>*/}
            </div>
            <div class="award-item">
              <Fade bottom>
                <div className="counter-img">
                  <i class="fa-solid fa-hand-holding-heart"></i>
                </div>
                <h5 class="font-number dsn-animate-number">
                  <span class="animate-number">100</span>+
                </h5>
                <h4 class="title-block mt-10">Subscription Plans Customers</h4>
              </Fade>
              {/*<p class="sm-p mt-10">
                A diverse and global community of independent thinkers make up
                our inclusive workplace.
              </p>*/}
            </div>
            <div class="award-item">
              <Fade bottom>
                <div className="counter-img">
                  <i class="fa-regular fa-user"></i>
                </div>
                <h5 class="font-number dsn-animate-number">
                  <span class="animate-number">5000</span>+
                </h5>
                <h4 class="title-block mt-10">Satisfied Clients</h4>
              </Fade>
            </div>
            <div class="award-item">
              <Fade bottom>
                <div className="counter-img">
                  <i class="fa-solid fa-money-bill"></i>
                </div>
                <h5 class="font-number dsn-animate-number">
                  <span class="animate-number">95</span>+
                </h5>
                <h4 class="title-block mt-10">Lakhs Taxes saved</h4>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
