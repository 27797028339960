import React from "react";
import Fade from "react-reveal/Fade";
// import "../assets/js/plugins.min.js"
// import "../assets/js/plugins/dsn-grid.min.js"
// import "../assets/js/custom.js"
import About from "./About.js";
import Services from "./Services.js";
import Testimonials from "./Testimonials.js";
import Footer from "./Footer.js";
import bg from "../assets/img/bg.jpg";
import WhyChooseUs from "./why-choose-us.js";
import OurClient from "./OurClient.js";
import { useState } from "react";
import { Header } from "./Header.js";

export const Home = () => {

  const [addClass, setAddClass] = useState(false);
  const toggleBodyClass = () => {
    setAddClass(prevState => !prevState);
  };

  // Add or remove class based on state
  if (addClass) {
    document.body.classList.add('v-light');
    document.body.classList.remove('v-dark');
  } else {
    document.body.classList.remove('v-light');
    document.body.classList.add('v-dark');
  }
  return (
    <>
      {" "}
      {/*<Header />*/}
      <header
        id="dsn_header"
        className="dsn-header-animation dsn-container section-padding v-dark-head"
      >
        <div className="entry-header p-relative over-hidden">
          <div
            id="hero_image"
            className="p-absolute dsn-hero-parallax-img over-hidden"
            data-dsn-ajax="img"
            data-overlay="1"
          >
            <img src={bg} className="cover-bg-img transform-3d" alt="" />
          </div>

          <div
            id="hero_content"
            className="hero-padding d-flex p-relative h-100 dsn-hero-parallax-title container align-items-center"
          >
            <div className="content p-relative ">
              <div className="intro-project w-100">
                <div className="intro-title ">
                  <div id="hero_title">
                    <h5 className="title " data-dsn-ajax="title">
                      <Fade top>
                        <strong className="theme-color">ZroTax</strong>
                        <b />
                      </Fade>
                    </h5>

                    <h5 className="subHeader-animation ">
                      Empowering Your Financial and Tax Journey
                      <br />
                      <span>Set yourself financially free</span>
                    </h5>
                  </div>
                  <Fade bottom>
                    <p className="subtitle-meta metas p-relative mt-30 w-70 p-3 fw-bold">
                      Provide comprehensive tax and financial planning services
                      tailored to the unique needs of the salaried class. With a
                      team of experienced tax professionals and financial
                      planners, we are committed to helping you optimise your
                      finances and achieve your dreams.
                    </p>

                    <div class="dsn-def-btn dsn-icon-heading-color mt-30 d-flex">
                      <a
                        className="dsn-btn dsn-border border-color-default   effect-ajax effect-ajax  move-circle has-icon-left"
                        href="#"
                        data-dsn-text="STUDIO"
                        data-dsn="parallax"
                      >
                        <span class="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                          <i class="fas fa-angle-right"></i>
                        </span>
                        <span class="title-btn p-relative  z-index-1 heading-color">
                          PLAN NOW
                        </span>
                      </a>
                    </div>
                  </Fade>
                  {/*<Fade top>
                    <div className="dsn-def-btn dsn-icon-heading-color mt-30 d-flex">
                      <a
                        className="dsn-btn dsn-border border-color-default  background-section effect-ajax effect-ajax  move-circle has-icon-left"
                        href="plan-now.php"
                        data-dsn-text="STUDIO"
                        data-dsn="parallax"
                      >
                        <span className="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                          <i className="fas fa-angle-right"></i>
                        </span>
                        <span className="title-btn p-relative  z-index-1 heading-color">
                          PLAN NOW
                        </span>
                      </a>
                    </div>
                  </Fade>*/}
                </div>
              </div>
            </div>
          </div>
          <a
            href="#page_wrapper"
            rel="nofollow"
            className="dsn-scroll-bottom"
            data-dsn-option='{"ease": "power4.inOut" , "duration" : 1.5}'
          >
            <div className="text">SCROLL</div>
          </a>
        </div>
      </header>
      <div id="dsn_box_options" class="day-night" onClick={toggleBodyClass}>
        <div class="night active" data-dsn-theme="dark">
          <svg width="48" height="48" viewBox="0 0 48 48">
            <rect x="12.3" y="23.5" width="2.6" height="1"></rect>
            <rect x="16.1" y="15.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.8871 16.5732)" width="1" height="2.6"></rect>
            <rect x="23.5" y="12.3" width="1" height="2.6"></rect>
            <rect x="30.1" y="16.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.5145 27.0538)" width="2.6" height="1"></rect>
            <rect x="33.1" y="23.5" width="2.6" height="1"></rect>
            <rect x="30.9" y="30.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.9952 31.4264)" width="1" height="2.6"></rect>
            <rect x="23.5" y="33.1" width="1" height="2.6"></rect>
            <rect x="15.3" y="30.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.3677 20.9457)" width="2.6" height="1"></rect>
            <path d="M24,18.7c-2.9,0-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3S26.9,18.7,24,18.7z M24,28.3c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3S26.4,28.3,24,28.3z">
            </path>
          </svg>

        </div>
        <div class="moon" data-dsn-theme="night">
          <svg width="48" height="48" viewBox="0 0 48 48">
            <path d="M24,33.9c-5.4,0-9.9-4.4-9.9-9.9c0-4.3,2.7-8,6.8-9.4l0.8-0.3l-0.1,0.9c-0.2,0.6-0.2,1.3-0.2,1.9c0,5.2,4.3,9.5,9.5,9.5c0.6,0,1.3-0.1,1.9-0.2l0.8-0.2L33.3,27C32,31.1,28.3,33.9,24,33.9z M20.4,15.9c-3.2,1.4-5.3,4.5-5.3,8.1c0,4.9,4,8.9,8.9,8.9c3.6,0,6.7-2.1,8.1-5.3c-0.4,0-0.8,0.1-1.3,0.1c-5.8,0-10.5-4.7-10.5-10.5C20.4,16.7,20.4,16.3,20.4,15.9z">
            </path>
          </svg>
        </div>
      </div>
      <div class="parents-margin">
        <About />
        <Services />
        <WhyChooseUs />
        <Testimonials />
        <OurClient />
        <Footer />
      </div>
    </>
  );
};
