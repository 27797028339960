import React from 'react'

export const Business = () => {
  return (
    <><div id="dsn_preloader" className="preloader">

    <div className="dsnload p-absolute">
        <span className="dsnload__row">
            <span className="dsnload__img">
            <img src="assets/img/logo-light.png" className="logo-dark" alt="Blackdsn" decoding="async" loading="lazy"/>
            <img src="assets/img/logo.png" className="logo-light" alt="Blackdsn" decoding="async" loading="lazy"/>
            </span>
        </span>
        <span className="dsnload__row dsnload__row--sibling">
            <span className="dsnload__img">
                 <img src="assets/img/logo-light.png" className="logo-dark" alt="Blackdsn" decoding="async" loading="lazy"/>
            <img src="assets/img/logo.png" className="logo-light" alt="Blackdsn" decoding="async" loading="lazy"/>
            </span>
        </span>
        <span className="dsnload__row dsnload__row--sibling">
            <span className="dsnload__img">
                <img src="assets/img/logo-light.png" className="logo-dark" alt="Blackdsn" decoding="async" loading="lazy"/>
            <img src="assets/img/logo.png" className="logo-light" alt="Blackdsn" decoding="async" loading="lazy"/>
            </span>
        </span>
        <span className="dsnload__row dsnload__row--sibling">
            <span className="dsnload__img">
               <img src="assets/img/logo-light.png" className="logo-dark" alt="Blackdsn" decoding="async" loading="lazy"/>
            <img src="assets/img/logo.png" className="logo-light" alt="Blackdsn" decoding="async" loading="lazy"/>
            </span>
        </span>


    </div>
    <svg width="100%" height="100%" viewBox="0 0 100 100" className="v-middle" preserveAspectRatio="xMinYMin meet"
         fill="none">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stop-color="var(--theme-color)"></stop>
            <stop offset="50%" stop-color="var(--border-color)"></stop>
            <stop offset="100%" stop-color="var(--assistant-color)"></stop>
        </linearGradient>
        <path className="dsn-progress-path" d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
    </svg>
    <div className="loading-circle v-middle">
        <p className="loading-count v-middle">0</p>
    </div>

    <span className="loading-text text-uppercase mt-30 dsn-container">Loading ...</span>
    <div className="bg-load background-section d-flex align-items-end">
        <svg className="dsn-separator-bottom dsn-icon-assistant-color" width="100%" height="100%" viewBox="0 0 100 10"
             preserveAspectRatio="none">
            <path className="path-anim separator__path" data-dsn-to="M 0 0 C 40 0 55 0 100 0 L 0 0 Z"
                  vector-effect="non-scaling-stroke" d="M 0 0 C 40 16 75 10 100 0 L 0 0 Z"></path>
        </svg>
    </div>

</div>

<main id="main_root" className="main-root">

    {/* <!-- ========== Menu ========== --> */}
    <header id="site_menu_header" className="site-header  dsn-container d-none dsn-hamburger">
        <div className="main-logo">
            <a href="index.html" data-dsn-text="Blackdsn" className="custom-logo-link main-brand effect-ajax" rel="home"
               aria-current="page">
                <img src="assets/img/logo-light.png" className="custom-logo logo-light" alt="Blackdsn"/>
                <img src="assets/img/logo.png" className="custom-logo logo-dark" alt="Blackdsn"/>
            </a>
        </div>
        <nav className="main-navigation ">
            <div className="menu-cover-title header-container dsn-container">MENU</div>
            <ul id="dsn-primary-list" className="primary-nav h2">
                <li className="nav-item has-sub-menu">
                    <a title="Home" href="#0">
                        <span className="overflow ">Home</span>
                    </a>
                    <ul className="nav-item">
                        <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004"
                                        //  
                                          >
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Home</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                        </li>
                        <li className="nav-item ">
                            <a title="Home" href="index.html">
                                <span className="overflow">Main Demo</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Business" href="business.html" data-dsn-text="Business">
                                <span className="overflow">Business</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Architecture" href="architecture.html" data-dsn-text="Architecture">
                                <span className="overflow">Architecture</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Personal" href="personal.html" data-dsn-text="Personal">
                                <span className="overflow">Personal</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Photographer" href="photographer.html" data-dsn-text="Photographer">
                                <span className="overflow">Photographer</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Resume" href="resume.html" data-dsn-text="Resume">
                                <span className="overflow">Resume</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Portfolio" href="metro-portfolio-1.html" data-dsn-text="Our Portfolio">
                                <span className="overflow">Metro Portfolio 1</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Portfolio" href="metro-portfolio-2.html" data-dsn-text="Our Portfolio">
                                <span className="overflow">Metro Portfolio 2</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="nav-item has-sub-menu">
                    <a title="Home" href="#0">
                        <span className="overflow ">Portfolio</span>
                    </a>
                    <ul className="nav-item">
                        <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Portfolio</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                        </li>
                        <li className="nav-item has-sub-menu">
                            <a title="Carousel" href="#0" data-dsn-text="Business">
                                <span className="overflow">Carousel</span>
                            </a>
                            <ul className="nav-item">
                                <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Carousel</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                </li>
                                <li className="nav-item ">
                                    <a title="Carousel Portfolio 1" href="carousel-portfolio-1.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Carousel Portfolio 1</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a title="Carousel Portfolio" href="carousel-portfolio-2.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Carousel Portfolio 2</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a title="Carousel Portfolio 3" href="carousel-portfolio-3.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Carousel Portfolio 3</span>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item has-sub-menu">
                            <a title="Carousel" href="#0" data-dsn-text="Business">
                                <span className="overflow">Sliders</span>
                            </a>
                            <ul className="nav-item">
                                <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Sliders</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                </li>
                                <li className="nav-item has-sub-menu">
                                    <a title="Slider" href="#0" data-dsn-text="Our Portfolio">
                                        <span className="overflow">Fullscreen</span>
                                    </a>
                                    <ul className="nav-item">
                                        <li className="dsn-back">
                                            <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Fullscreen</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                        </li>
                                        <li className="nav-item ">
                                            <a title="Slider" href="fullscreen-distortion-horizontal.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Distortion / H</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="fullscreen-distortion-vertical.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Distortion / V</span>
                                            </a>

                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="fullscreen-parallax-horizontal.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Parallax / H</span>
                                            </a>

                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="fullscreen-parallax-vertical.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Parallax / V</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-sub-menu">
                                    <a title="Slider" href="#0" data-dsn-text="Our Portfolio">
                                        <span className="overflow">Padding</span>
                                    </a>
                                    <ul className="nav-item">
                                        <li className="dsn-back">
                                            <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Padding</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                        </li>
                                        <li className="nav-item ">
                                            <a title="Slider" href="padding-distortion-horizontal.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Distortion / H</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="padding-distortion-vertical.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Distortion / V</span>
                                            </a>

                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="padding-parallax-horizontal.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Parallax / H</span>
                                            </a>

                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="padding-parallax-vertical.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Parallax / V</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-sub-menu">
                                    <a title="Slider" href="#0" data-dsn-text="Our Portfolio">
                                        <span className="overflow">Halfscreen</span>
                                    </a>
                                    <ul className="nav-item">
                                        <li className="dsn-back">
                                            <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Halfscreen</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                        </li>
                                        <li className="nav-item ">
                                            <a title="Slider" href="half-distortion-horizontal.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Distortion / H</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="half-distortion-vertical.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Distortion / V</span>
                                            </a>

                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="half-parallax-horizontal.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Parallax / H</span>
                                            </a>

                                        </li>
                                        <li className="nav-item">

                                            <a title="Slider" href="half-parallax-vertical.html" data-dsn-text="Our Portfolio">
                                                <span className="overflow">Slider Parallax / V</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                        </li>

                        <li className="nav-item has-sub-menu">
                            <a title="Card" href="#0" data-dsn-text="Portfolio">
                                <span className="overflow">Card</span>
                            </a>
                            <ul className="nav-item">
                                <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Card</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                </li>
                                <li className="nav-item ">
                                    <a title="work card 1" href="work-card-1.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work Card 1</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a title="work card 2" href="work-card-2.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work Card 2</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                        <li className="nav-item has-sub-menu">
                            <a title="Card" href="#0" data-dsn-text="Portfolio">
                                <span className="overflow">classNameic</span>
                            </a>
                            <ul className="nav-item">
                                <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">classNameic</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                </li>
                                <li className="nav-item ">
                                    <a title="work classNameic 1" href="work-classNameic-1.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work classNameic 1</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a title="work classNameic 2" href="work-classNameic-2.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work classNameic 2</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                        <li className="nav-item has-sub-menu">
                            <a title="List" href="#0" data-dsn-text="Portfolio">
                                <span className="overflow">List</span>
                            </a>
                            <ul className="nav-item">
                                <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">List</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                </li>
                                <li className="nav-item ">
                                    <a title="work list 1" href="work-list-1.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work List 1</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a title="work list 2" href="work-list-2.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work List 2</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                        <li className="nav-item has-sub-menu">
                            <a title="Metro" href="#0" data-dsn-text="Portfolio">
                                <span className="overflow">Metro</span>
                            </a>
                            <ul className="nav-item">
                                <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Metro</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                                </li>
                                <li className="nav-item ">
                                    <a title="work Metro 1" href="metro-portfolio-1.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work Metro 1</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a title="work Metro 2" href="metro-portfolio-2.html"
                                       data-dsn-text="Our Portfolio">
                                        <span className="overflow">Work Metro 2</span>
                                    </a>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </li>
                <li className="nav-item has-sub-menu">
                    <a title="Home" href="#0">
                        <span className="overflow ">Works</span>
                    </a>
                    <ul className="nav-item">
                        <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Works</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                        </li>
                        <li className="nav-item ">
                            <a title="VISIONAID" href="project-1.html" data-dsn-text="VISIONAID">
                                <span className="overflow">VISIONAID</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="MEN FASHION" href="project-2.html" data-dsn-text="MEN FASHION">
                                <span className="overflow">MEN FASHION</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="AUDI RS" href="project-3.html" data-dsn-text="AUDI RS">
                                <span className="overflow">AUDI RS</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="OPEN RUN" href="project-4.html" data-dsn-text="OPEN RUN">
                                <span className="overflow">OPEN RUN</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="DOG CHOW" href="project-5.html" data-dsn-text="DOG CHOW">
                                <span className="overflow">DOG CHOW</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="HEADPHONES" href="project-6.html" data-dsn-text="HEADPHONES">
                                <span className="overflow">HEADPHONES</span>
                            </a>
                        </li>

                    </ul>
                </li>
                <li className="nav-item has-sub-menu">
                    <a title="Home" href="#0">
                        <span className="overflow ">Blog</span>
                    </a>
                    <ul className="nav-item">
                        <li className="dsn-back">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px"
                                         viewBox="0 0 400.004 400.004">
                                        <g>
                                            <path
                                                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                                        </g>
                                    </svg>
                                    <span className="text-toggle-back">
                                        <span className="text-name">Blog</span>
                                        <span className="text-back">Back</span>
                                    </span>
                                </span>
                        </li>
                        <li className="nav-item ">
                            <a title="Stories" href="stories.html" data-dsn-text="Stories">
                                <span className="overflow">Stories</span>
                            </a>
                        </li>
                        <li className="nav-item ">
                            <a title="Single Post" href="single-post.html" data-dsn-text="Single Post">
                                <span className="overflow">Single Post</span>
                            </a>
                        </li>


                    </ul>
                </li>
                <li className="nav-item ">
                    <a title="Service" href="service.html" >
                        <span className="overflow">Service</span>
                    </a>
                </li>
                <li className="nav-item ">
                    <a title="Home" href="about-us.html">
                        <span className="overflow">About</span>
                    </a>
                </li>
                <li className="nav-item ">
                    <a title="Home" href="contact.html">
                        <span className="overflow">Contact</span>
                    </a>
                </li>
            </ul>
            <div className="container-content  d-flex flex-column justify-content-center section-margin">
                <div className="nav__info">
                    <div className="nav-content">
                        <h5 className="sm-title-block mb-10">Studio</h5>
                        26-30 New Damietta
                        El-Mahalla El-Kubra, SK1 66LM

                    </div>
                    <div className="nav-content mt-30">
                        <h5 className="sm-title-block mb-10">Contact</h5>
                        <p className="links over-hidden mb-1">
                            <a className="link-hover d-block" href="tel:00201004392260" data-hover-text="+00 (2)012 3321">+00 (2)01004392260</a>
                            <a className="link-hover" href="tel:00201024552406" data-hover-text="+00 (2)012 3321">+00 (2)01024552406</a>
                        </p>
                        <p className="links over-hidden"><a className="link-hover" href="mailto:info@dsngrid.com"
                                                        data-hover-text="info@dsngrid.com">info@dsngrid.com</a></p>

                    </div>
                </div>
                <div className="nav-social nav-content mt-30">
                    <div className="nav-social-inner p-relative">
                        <h5 className="sm-title-block mb-10">Follow us</h5>
                        <ul style={{"--dsn-li-name":" dsn6"}}>
                            <li style={{"--dsn-li-index":" 0"}}><a href="#0" target="_blank"
                                                              rel="nofollow noopener noreferrer">Dribbble.</a></li>
                            <li style={{"--dsn-li-index":" 1"}}><a href="#0" target="_blank"
                                                              rel="nofollow noopener noreferrer">Behance.</a></li>
                            <li style={{"--dsn-li-index":" 2"}}><a href="#0" target="_blank"
                                                              rel="nofollow noopener noreferrer">Linkedin.</a></li>
                            <li style={{"--dsn-li-index":" 3"}}><a href="#0" target="_blank"
                                                              rel="nofollow noopener noreferrer">Twitter.</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <div id="navbar_toggle" className="navbar-toggle">
            <div className="toggle-icon">
                <div className="toggle-line"></div>
                <div className="toggle-line"></div>
                <div className="toggle-line"></div>
            </div>
            <div className="toggle-text">
                <div className="text-menu">Menu</div>
                <div className="text-open">Open</div>
                <div className="text-close">Close</div>
            </div>
        </div>
        <div className="bg-load background-main"></div>

        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none"
             className="bg-load dsn-svg-transition">
            <path vector-effect="non-scaling-stroke" d="M 0 100 V 100 Q 50 100 100 100 V 100 z"/>
        </svg>

    </header>
    {/* <!-- ========== End Menu ========== --> */}


    {/* <!-- ========== Slider Parallax ========== --> */}
    <header className="v-dark-head dsn-slider-width-circle dsn-header-animation">
        <div className="main-slider v-dark-head-mobile has-horizontal  active-nav-slider dsn-webgl has-paginate"
             data-dsn-webgl='{"displacement" : "assets/img/displacement/8.jpg"}'
             data-dsn-option='{"direction":"horizontal","allowTouchMove":true,"watchSlidesProgress":true,"grabCursor":true,"mousewheel":false}'>


            <div className="content-slider slide-inner h-100 over-hidden">
                <div className="bg-container p-absolute  dsn-hero-parallax-img  w-100 h-100 z-index-1 dsn-hero-parallax-img">
                    <div className="swiper-container h-100">
                        <div className="swiper-wrapper">
                            <div className="slide-item swiper-slide over-hidden">
                                <div className="image-bg cover-bg w-100 h-100 before-z-index" data-overlay="4">
                                    <img className="cover-bg-img dsn-swiper-parallax-transform"
                                         src="assets/img/portfolio/project6/1.jpg" alt="" data-dsn-position="50% 50%"/>
                                </div>
                                <div className="slide-content p-absolute">
                                    <div className="p-relative head-meta swiper-animate-head  d-flex justify-content-center mb-20">
                                        <div className="circle-before metas mb-10">
                                            <span>Production</span>
                                        </div>
                                    </div>
                                    <h2 className="title has-box-mod p-relative">
                                        <a href="project-6.html" data-dsn-split="chars" className="effect-ajax"
                                           data-dsn-ajax="slider">
                                            HEADPHONES
                                        </a>
                                    </h2>

                                </div>
                            </div>
                            <div className="slide-item swiper-slide over-hidden">
                                <div className="image-bg cover-bg w-100 h-100 before-z-index" data-overlay="3">
                                    <img className="cover-bg-img dsn-swiper-parallax-transform"
                                         src="assets/img/portfolio/project5/1.jpg" alt="" data-dsn-position="50% 50%"/>
                                </div>
                                <div className="slide-content p-absolute">
                                    <div className="p-relative head-meta swiper-animate-head  d-flex justify-content-center mb-20">
                                        <div className="circle-before metas mb-10">
                                            <span>Photography</span>
                                            <span> - </span>
                                            <span>Production</span>
                                        </div>
                                    </div>
                                    <h2 className="title has-box-mod p-relative">
                                        <a href="project-5.html" data-dsn-split="chars" className="effect-ajax"
                                           data-dsn-ajax="slider">
                                            DOG CHOW
                                        </a>
                                    </h2>

                                </div>
                            </div>
                            <div className="slide-item swiper-slide over-hidden">
                                <div className="image-bg cover-bg w-100 h-100 before-z-index" data-overlay="2">
                                    <img className="cover-bg-img dsn-swiper-parallax-transform"
                                         src="assets/img/portfolio/project4/1.jpg" alt="" data-dsn-position="50% 50%"/>
                                </div>
                                <div className="slide-content p-absolute">
                                    <div className="p-relative head-meta swiper-animate-head  d-flex justify-content-center mb-20">
                                        <div className="circle-before metas mb-10">

                                            <span>Production</span>
                                        </div>
                                    </div>
                                    <h2 className="title has-box-mod p-relative">
                                        <a href="project-4.html" data-dsn-split="chars" className="effect-ajax"
                                           data-dsn-ajax="slider">
                                            OPEN RUN
                                        </a>
                                    </h2>

                                </div>
                            </div>
                            <div className="slide-item swiper-slide over-hidden">
                                <div className="image-bg cover-bg w-100 h-100 before-z-index" data-overlay="2">
                                    <img className="cover-bg-img dsn-swiper-parallax-transform"
                                         src="assets/img/portfolio/project3/1.jpg" alt="" data-dsn-position="50% 50%"/>
                                </div>
                                <div className="slide-content p-absolute">
                                    <div className="p-relative head-meta swiper-animate-head  d-flex justify-content-center mb-20">
                                        <div className="circle-before metas mb-10">
                                            <span>Photography</span>
                                            <span> - </span>
                                            <span>Production</span>
                                        </div>
                                    </div>
                                    <h2 className="title has-box-mod p-relative">
                                        <a href="project-3.html" data-dsn-split="chars" className="effect-ajax"
                                           data-dsn-ajax="slider">
                                            AUDI RS
                                        </a>
                                    </h2>

                                </div>
                            </div>
                            <div className="slide-item swiper-slide over-hidden">
                                <div className="image-bg cover-bg w-100 h-100 before-z-index" data-overlay="4">
                                    <img className="cover-bg-img dsn-swiper-parallax-transform"
                                         src="assets/img/portfolio/project2/1.jpg" alt="" data-dsn-position="50% 50%"/>
                                </div>
                                <div className="slide-content p-absolute">
                                    <div className="p-relative head-meta swiper-animate-head  d-flex justify-content-center mb-20">
                                        <div className="circle-before metas mb-10">
                                            <span>Photography</span>
                                        </div>
                                    </div>
                                    <h2 className="title has-box-mod p-relative">
                                        <a href="project-2.html" data-dsn-split="chars" className="effect-ajax"
                                           data-dsn-ajax="slider">
                                            MEN FASHION
                                        </a>
                                    </h2>

                                </div>
                            </div>
                            <div className="slide-item swiper-slide over-hidden">
                                <div className="image-bg cover-bg w-100 h-100 before-z-index" data-overlay="4">
                                    <img className="cover-bg-img dsn-swiper-parallax-transform"
                                         src="assets/img/portfolio/project1/1.jpg" alt="" data-dsn-position="50% 50%"/>
                                </div>
                                <div className="slide-content p-absolute">
                                    <div className="p-relative head-meta swiper-animate-head  d-flex justify-content-center mb-20">
                                        <div className="circle-before metas mb-10">
                                            <span>CREATIVE</span>
                                            <span>-</span>
                                            <span>Photography</span>
                                        </div>
                                    </div>
                                    <h2 className="title has-box-mod p-relative">
                                        <a href="project-1.html" data-dsn-split="chars" className="effect-ajax"
                                           data-dsn-ajax="slider">
                                            VISIONAID
                                        </a>
                                    </h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dsn-container d-flex align-items-center justify-content-center text-center dsn-slider-content dsn-hero-parallax-title"></div>
            </div>


            <div className="dsn-swiper-paginate">
                <div className="swiper-pagination dsn-swiper-circle mr-30 ml-30 heading-color"></div>
            </div>

            <div className="control-nav w-100  d-flex dsn-container  align-items-center justify-content-between">
                <div className="prev-paginate dsn-paginate-arrow box-shadow"></div>


                <div className="dsn-pagination p-relative d-flex align-items-end">
                    <span className="slider-current-index h2">01</span>
                    <span className="sm-title-block">/ </span><span
                        className="total sm-title-block"> 6</span>
                </div>

                <div className="next-paginate dsn-paginate-arrow box-shadow"></div>


            </div>


        </div>
    </header>
    {/* <!-- ========== End Slider Parallax ========== --> */}


    <div id="page_wrapper" className="wrapper">
        {/* <!-- ========== About Section ========== --> */}
        <div className="about-section about-3 container d-grid grid-md-2 section-margin" data-dsn-gap="30px 60px">
            <div className="box-left">
                <div className="section-title d-flex">
                    <div className="sub-section-title">
                        <p className="description d-inline-block p-relative square-before mb-10 dsn-up">ABOUT US</p>
                        <h2 className="title-h2 dsn-heading-title p-relative title-block-lg d-block dsn-up">WHO WE ARE</h2>
                    </div>
                </div>
                <div className="dsn-accordion mt-30" role="tablist" data-dsn-iconsize="35px">
                    <div className="accordion__wrapper">

                        <div className="accordion__item  active">
                            <div className="accordion__question user-no-selection expanded d-flex align-items-center  accordion-icon">
					                        <span className="dsn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                     id="Layer_1" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50"
                                    ><g>	<path fill-rule="evenodd" clip-rule="evenodd"
                                                                       d="M18.072,40.553c0-0.932-0.062-1.868,0.013-2.794  c0.146-1.81-0.475-3.234-1.633-4.647c-1.268-1.547-2.404-3.274-3.239-5.089c-2.214-4.811-1.168-9.314,1.994-13.308  c3.294-4.164,7.795-5.363,12.816-4.145c5.08,1.233,8.364,4.637,9.375,9.721c0.67,3.365,0.388,6.819-2.079,9.689  c-0.868,1.011-1.687,2.122-2.249,3.318c-0.621,1.318-1.179,2.764-1.305,4.193c-0.222,2.52-0.048,5.071-0.133,7.605  c-0.068,2.067-2.064,4.004-4.106,4.059c-1.696,0.045-3.395,0.022-5.093,0.01c-2.438-0.018-4.307-1.811-4.394-4.262  c-0.052-1.448-0.009-2.9-0.009-4.351C18.044,40.553,18.058,40.553,18.072,40.553z M29.94,41.686c0-1.752-0.168-3.318,0.051-4.828  c0.194-1.339,0.652-2.706,1.297-3.897c0.728-1.344,1.849-2.469,2.705-3.752c3.775-5.662,2.175-12.419-3.692-15.824  c-4.225-2.452-9.512-1.524-12.7,1.058c-2.954,2.392-5.222,7.669-3.05,12.026c0.78,1.565,1.752,3.034,2.618,4.558  c0.761,1.339,1.444,2.724,2.27,4.019c0.262,0.412,0.863,0.845,1.318,0.856c1.754,0.044,3.512-0.096,5.27-0.13  c0.693-0.015,1.626-0.207,1.623,0.894c-0.003,1.088-0.926,0.904-1.626,0.908c-2.005,0.013-4.01,0.005-6.139,0.005  c0,2.522-0.13,5.002,0.05,7.458c0.103,1.41,0.977,2.499,2.635,2.528c1.346,0.024,2.692,0.075,4.039,0.059  c2.395-0.029,3.558-1.525,3.075-4.293c-2.051,0-4.161,0.048-6.266-0.036c-0.464-0.018-0.91-0.496-1.364-0.763  c0.47-0.281,0.931-0.785,1.412-0.807C25.527,41.633,27.596,41.686,29.94,41.686z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M12.989,10.347c-0.546,0.164-1.233,0.569-1.437,0.398  C10.38,9.76,9.294,8.665,8.257,7.536C8.094,7.359,8.201,6.679,8.414,6.484c0.216-0.197,0.87-0.252,1.07-0.074  c1.073,0.952,2.07,1.99,3.078,3.015C12.695,9.56,12.735,9.785,12.989,10.347z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M37.373,10.994c-0.099-0.492-0.441-1.185-0.246-1.411  c1.004-1.159,2.125-2.219,3.264-3.25c0.158-0.143,0.743-0.042,0.94,0.146c0.202,0.194,0.346,0.792,0.214,0.937  c-1.069,1.167-2.205,2.273-3.336,3.383C38.117,10.89,37.918,10.873,37.373,10.994z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M25.748,3.132c-0.001,0.618,0.112,1.265-0.041,1.842  c-0.103,0.389-0.58,0.678-0.889,1.013c-0.276-0.315-0.765-0.613-0.792-0.947c-0.096-1.227-0.11-2.473,0.001-3.697  c0.033-0.363,0.578-0.679,0.888-1.017c0.277,0.37,0.69,0.704,0.799,1.119C25.854,1.973,25.749,2.566,25.748,3.132z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M43.725,23.819c-0.406-0.043-0.854,0.003-1.205-0.158  c-0.268-0.123-0.608-0.546-0.565-0.769c0.06-0.315,0.435-0.796,0.692-0.807c1.327-0.057,2.663-0.028,3.989,0.072  c0.305,0.022,0.58,0.445,0.869,0.684c-0.339,0.265-0.655,0.713-1.021,0.758c-0.905,0.11-1.835,0.036-2.755,0.036  C43.728,23.696,43.726,23.758,43.725,23.819z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M3.5,24.01c-0.448-0.367-0.896-0.733-1.343-1.101  c0.493-0.293,0.963-0.779,1.482-0.837c0.958-0.106,1.954-0.026,2.915,0.112c0.416,0.061,0.783,0.457,1.172,0.701  c-0.37,0.255-0.72,0.687-1.113,0.731c-0.962,0.107-1.945,0.035-2.92,0.035C3.629,23.771,3.564,23.89,3.5,24.01z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M25.306,16.083c3.552,0.405,6.106,2.751,6.305,6.291  c0.022,0.39-0.47,0.808-0.723,1.212c-0.27-0.31-0.708-0.583-0.783-0.935c-0.595-2.77-2.146-4.448-5.048-4.813  c-0.33-0.04-0.586-0.662-0.876-1.014C24.64,16.521,25.099,16.218,25.306,16.083z"></path></g></svg>                        </span>

                                <p className="sm-title-block tab-title heading-color" role="tab" data-tab="0">OUR VISION</p>
                            </div>
                            <div className="accordion__answer tab-description active accordion-icon" role="tabpanel"
                                 data-tab="0">
                                <p>Cepteur sint occaecat cupidatat proident, taken possession of my entire soul, like
                                    these sweet mornings of spring which I enjoy with my whole.</p>
                            </div>
                        </div>
                        <div className="accordion__item  ">
                            <div className="accordion__question user-no-selection  d-flex align-items-center  accordion-icon">
					            <span className="dsn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                     id="Layer_1" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50"
                                    ><g>	<path fill-rule="evenodd" clip-rule="evenodd"
                                                                       d="M25.515,27.051c-0.371-0.159-0.756-0.298-1.117-0.483  c-6.626-3.398-13.254-6.794-19.865-10.221c-0.416-0.215-1.031-0.76-0.972-1.019c0.102-0.446,0.593-0.919,1.04-1.152  c6.651-3.461,13.312-6.904,20.007-10.279c0.516-0.259,1.414-0.177,1.955,0.099c3.589,1.828,7.11,3.788,10.689,5.634  c3.028,1.562,6.114,3.01,9.149,4.558c0.454,0.232,0.77,0.738,1.149,1.116c-0.358,0.331-0.665,0.76-1.082,0.977  c-6.629,3.457-13.272,6.887-19.914,10.319C26.234,26.765,25.895,26.888,25.515,27.051z M44.76,15.372  c-0.513-0.362-0.775-0.596-1.078-0.752c-5.791-2.994-11.577-5.999-17.402-8.927c-0.479-0.24-1.32-0.129-1.835,0.131  c-5.546,2.792-11.063,5.643-16.583,8.488c-0.508,0.262-0.983,0.59-1.696,1.021c6.433,3.309,12.539,6.471,18.68,9.562  c0.432,0.217,1.206,0.052,1.695-0.189c2.922-1.443,5.806-2.963,8.705-4.454C38.347,18.659,41.449,17.07,44.76,15.372z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M6.153,25.292c1.245,0.667,2.225,1.209,3.22,1.722  c5.014,2.583,10.02,5.182,15.065,7.701c0.544,0.272,1.446,0.323,1.973,0.059c5.826-2.926,11.61-5.933,17.403-8.924  c0.257-0.133,0.479-0.329,0.912-0.632c-1.152-0.605-2.191-1.057-3.114-1.681c-0.401-0.271-0.563-0.897-0.833-1.363  c0.501-0.038,1.084-0.26,1.489-0.081c1.456,0.645,2.868,1.395,4.266,2.163c1.333,0.732,1.365,1.37,0.078,2.036  c-6.755,3.495-13.519,6.975-20.308,10.403c-0.455,0.229-1.24,0.185-1.71-0.053c-6.743-3.407-13.462-6.859-20.174-10.326  c-1.297-0.67-1.284-1.349,0.015-2.057c1.454-0.792,2.922-1.563,4.431-2.241c0.35-0.157,0.885,0.097,1.334,0.162  c-0.185,0.413-0.256,0.999-0.575,1.206C8.602,24.052,7.487,24.577,6.153,25.292z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        d="M44.767,35.25c-1.224-0.643-2.298-1.123-3.27-1.759  c-0.362-0.237-0.485-0.838-0.718-1.272c0.493-0.05,1.072-0.29,1.465-0.115c1.51,0.674,2.977,1.452,4.43,2.247  c1.189,0.651,1.184,1.312-0.063,1.957c-6.71,3.476-13.429,6.936-20.176,10.341c-0.502,0.253-1.354,0.251-1.857-0.002  c-6.748-3.402-13.468-6.859-20.181-10.33c-1.246-0.645-1.257-1.307-0.06-1.968c1.496-0.824,3.012-1.618,4.566-2.322  c0.339-0.154,0.877,0.129,1.323,0.211c-0.203,0.407-0.299,0.974-0.627,1.189c-0.968,0.637-2.028,1.135-3.331,1.838  c0.666,0.42,1.023,0.683,1.412,0.884c5.565,2.872,11.125,5.757,16.72,8.57c0.548,0.275,1.453,0.354,1.977,0.091  c5.83-2.921,11.619-5.925,17.416-8.912C44.055,35.763,44.287,35.572,44.767,35.25z"></path></g></svg>                        </span>
                                <p className="sm-title-block tab-title heading-color" role="tab" data-tab="1">OUR GOALS</p>
                            </div>
                            <div className="accordion__answer tab-description  accordion-icon" role="tabpanel" data-tab="1">
                                <p>
                                    Cepteur sint occaecat cupidatat proident, taken possession of my entire soul, like
                                    these sweet mornings of spring which I enjoy with my whole.
                                </p>
                            </div>
                        </div>
                        <div className="accordion__item  ">
                            <div className="accordion__question user-no-selection  d-flex align-items-center  accordion-icon">
					                        <span className="dsn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                     id="Layer_1" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50"
                                    ><g>	<path fill-rule="evenodd" clip-rule="evenodd"
                                                                       d="M20.944,14.012c-4.79,0-9.443,0-14.079,0c-0.452,1.854-1.236,2.1-4.715,1.769  c-1.399-0.134-1.274-1.091-1.086-1.813c0.312-1.194-1.164-3.078,1.064-3.523c3.126-0.625,4.292-0.086,4.752,1.688  c4.629,0,9.276,0,13.868,0c0.118-0.163,0.229-0.245,0.233-0.331c0.09-2.241-0.195-2.33,2.678-2.304  c0.658,0.006,1.317,0.002,1.977,0.001c2.706-0.002,2.706-0.002,3.098,2.574c4.658,0,9.343,0,14.028,0  c0.466-1.725,1.538-2.251,4.723-1.621c2.313,0.458,0.729,2.421,1.086,3.657c0.234,0.809,0.101,1.608-1.242,1.682  c-3.792,0.208-4.104,0.136-4.549-1.773c-4.639,0-9.293,0-13.927,0c-0.084,0.115-0.196,0.196-0.201,0.283  c-0.151,2.852-0.224,2.339-2.926,2.396c-0.933,0.02-1.873-0.059-2.8,0.018c-1.438,0.122-2.06-0.415-1.887-1.776  C21.072,14.691,20.992,14.435,20.944,14.012z M22.922,14.82c1.354,0,2.532,0,3.76,0c0-1.218,0-2.326,0-3.482  c-1.313,0-2.524,0-3.76,0C22.922,12.551,22.922,13.624,22.922,14.82z M2.87,13.928c0.7,0,1.222,0,1.799,0c0-0.619,0-1.152,0-1.81  c-0.681,0.039-1.241,0.071-1.799,0.104C2.87,12.868,2.87,13.322,2.87,13.928z M44.924,14.051c0.724-0.057,1.244-0.097,1.8-0.141  c0-0.62,0-1.11,0-1.667c-0.65,0-1.208,0-1.8,0C44.924,12.848,44.924,13.344,44.924,14.051z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.02,36.725c0.056-4.04-0.59-3.539,3.75-3.533  c4.271,0.006,3.804-0.574,3.787,3.667c-0.017,4.106,0.509,3.49-3.765,3.521C1.019,40.406,1.019,40.385,1.02,36.725z M6.617,38.565  c0-1.231,0-2.34,0-3.511c-1.272,0-2.45,0-3.699,0c0,1.21,0,2.318,0,3.511C4.168,38.565,5.308,38.565,6.617,38.565z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        d="M48.61,36.829c-0.063,4.104,0.604,3.548-3.725,3.545  c-4.384-0.005-3.819,0.6-3.825-3.618c-0.005-4.118-0.47-3.565,3.739-3.564C49.19,33.191,48.553,32.618,48.61,36.829z M42.997,38.54  c1.304,0,2.48,0,3.705,0c0-1.218,0-2.325,0-3.477c-1.287,0-2.464,0-3.705,0C42.997,36.242,42.997,37.313,42.997,38.54z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M3.907,30.129c0.707-4.828,2.875-8.57,6.166-11.771  c0.722-0.703,1.485-1.393,2.334-1.945c0.389-0.254,1.032-0.162,1.56-0.227c-0.164,0.507-0.158,1.205-0.519,1.491  c-3.182,2.508-5.611,5.502-6.952,9.222c-0.351,0.972-0.454,2.024-0.816,2.99c-0.182,0.484-0.693,0.857-1.055,1.282  C4.338,30.754,4.049,30.335,3.907,30.129z"></path>	<path
                                        fill-rule="evenodd" clip-rule="evenodd"
                                        style={{"--dsn-color-icon" : "var(--heading-color)"}}
                                        d="M45.727,30.116c-0.137,0.204-0.422,0.629-0.708,1.055  c-0.359-0.42-0.962-0.804-1.04-1.267c-0.854-5.004-3.428-8.997-7.643-12.118c-0.414-0.307-0.467-1.046-0.688-1.585  c0.575,0.087,1.295-0.005,1.704,0.287C42.037,19.831,44.871,24.229,45.727,30.116z"></path></g></svg>                        </span>
                                <p className="sm-title-block tab-title heading-color" role="tab" data-tab="2">OUR
                                    MISSION</p>
                            </div>
                            <div className="accordion__answer tab-description  accordion-icon" role="tabpanel" data-tab="2">
                                <p>
                                    Cepteur sint occaecat cupidatat proident, taken possession of my entire soul, like
                                    these sweet mornings of spring which I enjoy with my whole.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="box-right">
                <h3 className="title-block heading-color body-font text-upper">Hello we, are Dsn Grid</h3>
                <p className="mt-30">
                    Founded in 2000, Dsn Grid has become one of
                    the best Digital Agency in Themeforest. Blue money going forward, but deploy to production.
                    First-order optimal strategies build on a culture of contribution and inclusion so those options
                </p>
                <div className="mt-30">
                    <h6 className="sm-title-block body-font heading-color  square-after d-inline-block">SALVADOR DALI</h6>
                    <span className="d-block">Digital Artisit</span>
                </div>

                <div className="experience mt-30">
                    <span className="exp-number dsn-animate-number exp-font-2">
                        <span className="animate-number">25</span>
                    </span>
                    <h5 className="title-block mt-20">
                        YEARS OF <br/>DIGITAL EXPERIENCE
                    </h5>
                </div>
            </div>
        </div>
        {/* <!-- ========== End About Section ========== --> */}

        {/* <!-- ========== Service ========== --> */}
        <div className="mt-section background-section section-padding">
            <div className="container">
                <div className="section-title mb-70 d-flex">
                    <div className="sub-section-title ">
                        <p className="description d-inline-block p-relative square-before mb-10">advantages</p>
                        <h2 className="title-h2 body-font title-block-lg d-block heading-color">Let’s Check <b>Our
                            Services</b></h2>
                    </div>
                </div>
                <div className="icon-top dsn-icon-heading-color">
                    <div className="dsn-service d-grid grid-sm-2 "  data-dsn-iconsize="20px">

                        <div className="dsn-up service-item p-relative grid-item">
                            <div className="service-item-inner  number-item h-100">
                                <div className="dsn-icon"><i className="far fa-window-minimize" aria-hidden="true"></i></div>
                                <div className="service-content p-relative">
                                    <h4 className="service_title  title-block mb-20">DIGITAL PRODUCTS</h4>
                                    <div className="service_description max-w570 dsn-auto">
                                        <p>
                                            Web design encompasses many different skills and disciplines in the
                                            production of all web.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="dsn-up service-item p-relative grid-item">
                            <div className="service-item-inner  number-item h-100">
                                <div className="dsn-icon"><i className="far fa-window-minimize" aria-hidden="true"></i></div>
                                <div className="service-content p-relative">
                                    <h4 className="service_title  title-block mb-20">WEB DEVELOPMENT</h4>
                                    <div className="service_description max-w570 dsn-auto">
                                        <p>
                                            Web design encompasses many different skills and disciplines in the
                                            production of all web.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="dsn-up service-item p-relative grid-item">
                            <div className="service-item-inner  number-item h-100">
                                <div className="dsn-icon"><i className="far fa-window-minimize" aria-hidden="true"></i></div>
                                <div className="service-content p-relative">
                                    <h4 className="service_title  title-block mb-20">UI-UX DESIGN</h4>
                                    <div className="service_description max-w570 dsn-auto">
                                        <p>
                                            Web design encompasses many different skills and disciplines in the
                                            production of all web.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="dsn-up service-item p-relative grid-item">
                            <div className="service-item-inner  number-item h-100">
                                <div className="dsn-icon"><i className="far fa-window-minimize" aria-hidden="true"></i></div>
                                <div className="service-content p-relative">
                                    <h4 className="service_title  title-block mb-20">WEB DEVELOPMENT</h4>
                                    <div className="service_description max-w570 dsn-auto">
                                        <p>
                                            Web design encompasses many different skills and disciplines in the
                                            production of all web.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </div>
        {/* <!-- ========== End Service ========== --> */}

        {/* <!-- ========== Awarded ========== --> */}
        <div className="dsn-award text-center p-relative mb-section background-section">
            <div className="dsn-bg-mask w-100 h-50 bottom-0  background-main z-index-1"></div>
            <div className="section-padding container background-main border-style p-relative z-index-2">
                <div className="d-grid grid-md-3">
                    <div className="award-item">
                        <h5 className="font-number dsn-animate-number">
                            <span className="animate-number">200</span>+
                        </h5>
                        <h4 className="title-block mt-10">Team Members</h4>
                        <p className="sm-p mt-10">
                            A diverse and global community of independent thinkers make up our inclusive workplace.
                        </p>
                    </div>
                    <div className="award-item">
                        <h5 className="font-number dsn-animate-number">
                            <span className="animate-number">60</span>+
                        </h5>
                        <h4 className="title-block mt-10">Portfolio Managers</h4>
                        <p className="sm-p mt-10">
                            A diverse and global community of independent thinkers make up our inclusive workplace.
                        </p>
                    </div>
                    <div className="award-item">
                        <h5 className="font-number dsn-animate-number">
                            <span className="animate-number">7</span>+
                        </h5>
                        <h4 className="title-block mt-10">Offices</h4>
                        <p className="sm-p mt-10">
                            A diverse and global community of independent thinkers make up our inclusive workplace.
                        </p>
                    </div>

                </div>
            </div>
        </div>
        {/* <!-- ========== End Awarded ========== --> */}

        {/* <!-- ========== Portfolio ========== --> */}
        <div className="section-margin">
            <div className="container section-title mb-70 d-flex">
                <div className="sub-section-title ">
                    <p className="description d-inline-block p-relative square-before mb-10">OUR WORKS </p>
                    <h2 className="title-h2 body-font title-block-lg d-block heading-color">
                        Our latest <br/><b>AWESOME DESIGNS </b>
                    </h2>
                </div>
            </div>
            <div className="p-relative dsn-style-cards">
                <div className="root-posts">
                    <div className="dsn-posts dsn-post-type-cards box-image-normal box-image-hover ">
                        <div className="has-parallax-image dsn-swiper p-relative"
                             data-dsn-option='{"slidesPerView":3,"spaceBetween":60}'>
                            <div className="swiper-container ">
                                <div className="swiper-wrapper v-dark-head">
                                    <article
                                            className="dsn-item-post grid-item over-hidden p-relative box-hover-image swiper-slide">
                                        <div className="box-content d-flex ">
                                            <a className="effect-ajax box-image-link bg-shadow" href="project-6.html"
                                               data-dsn-ajax="work" title="HEADPHONES">
                                                <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                                     data-swiper-parallax-scale="1.2"
                                                     data-overlay="4">
                                                    <img className="cover-bg-img" src="assets/img/portfolio/project6/1.jpg"
                                                         alt=""/>
                                                </div>
                                            </a>
                                            <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column text-center"
                                                 data-swiper-parallax-opacity="0">
                                                <div className="post-title-info">
                                                    <div className="post-meta max-w750">
                                                        <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                            <span data-separator="&">Production</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="post-title title-h2">
                                                        <a href="project-6.html" className="effect-ajax"
                                                           data-dsn-ajax="work">
                                                            HEADPHONES
                                                        </a>
                                                    </h2>
                                                </div>


                                            </div>
                                        </div>


                                    </article>
                                    <article
                                            className="dsn-item-post grid-item over-hidden p-relative box-hover-image swiper-slide">
                                        <div className="box-content d-flex ">
                                            <a className="effect-ajax box-image-link bg-shadow" href="project-5.html"
                                               data-dsn-ajax="work" title="DOG CHOW">
                                                <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                                     data-swiper-parallax-scale="1.2"
                                                     data-overlay="3">
                                                    <img className="cover-bg-img" src="assets/img/portfolio/project5/1.jpg"
                                                         alt=""/>
                                                </div>
                                            </a>
                                            <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column text-center"
                                                 data-swiper-parallax-opacity="0">
                                                <div className="post-title-info">
                                                    <div className="post-meta max-w750">
                                                        <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                            <span data-separator=" & ">Photography</span>
                                                            <span data-separator=" & ">Production</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="post-title title-h2">
                                                        <a href="project-5.html" className="effect-ajax"
                                                           data-dsn-ajax="work">
                                                            DOG CHOW
                                                        </a>
                                                    </h2>
                                                </div>


                                            </div>
                                        </div>


                                    </article>
                                    <article
                                            className="dsn-item-post grid-item over-hidden p-relative box-hover-image swiper-slide">
                                        <div className="box-content d-flex ">
                                            <a className="effect-ajax box-image-link bg-shadow" href="project-4.html"
                                               data-dsn-ajax="work" title="OPEN RUN">
                                                <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                                     data-swiper-parallax-scale="1.2"
                                                     data-overlay="2">
                                                    <img className="cover-bg-img" src="assets/img/portfolio/project4/1.jpg"
                                                         alt=""/>
                                                </div>
                                            </a>
                                            <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column text-center"
                                                 data-swiper-parallax-opacity="0">
                                                <div className="post-title-info">
                                                    <div className="post-meta max-w750">
                                                        <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                            <span data-separator=" & ">Production</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="post-title title-h2">
                                                        <a href="project-4.html" className="effect-ajax"
                                                           data-dsn-ajax="work">
                                                            OPEN RUN
                                                        </a>
                                                    </h2>
                                                </div>


                                            </div>
                                        </div>


                                    </article>
                                    <article
                                            className="dsn-item-post grid-item over-hidden p-relative box-hover-image swiper-slide">
                                        <div className="box-content d-flex ">
                                            <a className="effect-ajax box-image-link bg-shadow" href="project-3.html"
                                               data-dsn-ajax="work" title="AUDI RS">
                                                <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                                     data-swiper-parallax-scale="1.2"
                                                     data-overlay="2">
                                                    <img className="cover-bg-img" src="assets/img/portfolio/project3/1.jpg"
                                                         alt=""/>
                                                </div>
                                            </a>
                                            <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column text-center"
                                                 data-swiper-parallax-opacity="0">
                                                <div className="post-title-info">
                                                    <div className="post-meta max-w750">
                                                        <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                            <span data-separator=" & ">Photography</span>
                                                            <span data-separator=" & ">Production</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="post-title title-h2">
                                                        <a href="project-3.html" className="effect-ajax"
                                                           data-dsn-ajax="work">
                                                            AUDI RS
                                                        </a>
                                                    </h2>
                                                </div>


                                            </div>
                                        </div>


                                    </article>
                                    <article
                                            className="dsn-item-post grid-item over-hidden p-relative box-hover-image swiper-slide">
                                        <div className="box-content d-flex ">
                                            <a className="effect-ajax box-image-link bg-shadow" href="project-2.html"
                                               data-dsn-ajax="work" title="MEN FASHION">
                                                <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                                     data-swiper-parallax-scale="1.2"
                                                     data-overlay="4">
                                                    <img className="cover-bg-img" src="assets/img/portfolio/project2/1.jpg"
                                                         alt=""/>
                                                </div>
                                            </a>
                                            <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column text-center"
                                                 data-swiper-parallax-opacity="0">
                                                <div className="post-title-info">
                                                    <div className="post-meta max-w750">
                                                        <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                            <span data-separator=" & ">Photography</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="post-title title-h2">
                                                        <a href="project-2.html" className="effect-ajax"
                                                           data-dsn-ajax="work">
                                                            MEN FASHION
                                                        </a>
                                                    </h2>
                                                </div>


                                            </div>
                                        </div>


                                    </article>
                                    <article
                                            className="dsn-item-post grid-item over-hidden p-relative box-hover-image swiper-slide">
                                        <div className="box-content d-flex ">
                                            <a className="effect-ajax box-image-link bg-shadow" href="project-1.html"
                                               data-dsn-ajax="work" title="VISIONAID">
                                                <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                                     data-swiper-parallax-scale="1.2"
                                                     data-overlay="4">
                                                    <img className="cover-bg-img" src="assets/img/portfolio/project1/1.jpg"
                                                         alt=""/>
                                                </div>
                                            </a>
                                            <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column text-center"
                                                 data-swiper-parallax-opacity="0">
                                                <div className="post-title-info">
                                                    <div className="post-meta max-w750">
                                                        <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                            <span data-separator=" & ">Creative</span>
                                                            <span data-separator=" & ">Photography</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="post-title title-h2">
                                                        <a href="project-1.html" className="effect-ajax"
                                                           data-dsn-ajax="work">
                                                            VISIONAID
                                                        </a>
                                                    </h2>
                                                </div>


                                            </div>
                                        </div>


                                    </article>
                                </div>
                                <div className="dsn-swiper-paginate  d-flex p-relative w-100 h-100 mt-50 align-items-center dsn-container justify-content-between">
                                    <div className="swiper-prev">
                                        <div className="prev-container">
                                            <div className="container-inner">
                                                <div className="triangle"></div>
                                                <svg className="circle" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 24 24">
                                                    <g className="circle-wrap" fill="none" stroke-width="1"
                                                       stroke-linejoin="round" stroke-miterlimit="10">
                                                        <circle cx="12" cy="12" r="10.5"></circle>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-pagination  mr-30 ml-30 heading-color"
                                         data-dsn-type="progressbar"></div>
                                    <div className="swiper-next">
                                        <div className="next-container">
                                            <div className="container-inner">
                                                <div className="triangle"></div>
                                                <svg className="circle" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" viewBox="0 0 24 24">
                                                    <g className="circle-wrap" fill="none" stroke-width="1"
                                                       stroke-linejoin="round" stroke-miterlimit="10">
                                                        <circle cx="12" cy="12" r="10.5"></circle>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- ========== End Portfolio ========== --> */}


        {/* <!-- ========== Play Video ========== --> */}
        <div className="section-video  section-margin background-section p-relative section-padding hv-100 dsn-animate-video v-dark-head" data-dsn-option='{"speed":5}' >
                <div className="container d-flex align-items-center h-100">
                    <div className="content d-grid grid-3 justify-content-between align-items-center w-100">
                        <h6 className="title animate-left text-left">PLAY</h6>
                        <div className="dsn-normal-btn dsn-icon-heading-color d-flex justify-content-center">
                            <a className="dsn-btn dsn-border border-color-default vid background-section move-circle has-icon-left"
                               href="https://vimeo.com/175353205" target="_blank" rel="nofollow" data-dsn="parallax">
                            <span className="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                                <i className="fas fa-play"></i>
                            </span>
                                <span className="title-btn p-relative z-index-1">PLAY</span>
                            </a>
                        </div>
                        <h6 className="title animate-right text-right">REEL</h6>
                    </div>
                </div>
                <div className="dsn-bg-section p-absolute w-100 h-100 over-hidden top-0 left-0">
                    <div className="h-100 img-box-parallax before-z-index section-margin w-100"  data-overlay="4">
                        <img src="assets/img/about-4.jpg" className="cover-bg-img has-direction" alt=""/>
                    </div>

                </div>
        </div>
        {/* <!-- ========== End Play Video ========== --> */}

        {/* <!-- ========== testimonials ========== --> */}
        <div className="p-relative container section-margin">
            <div className="section-title mb-70 d-flex">
                <div className="sub-section-title ">
                    <p className="description d-inline-block p-relative square-before mb-10">testimonials</p>
                    <h2 className="title-h2 body-font title-block-lg d-block heading-color">What People <b>are Saying</b></h2>
                </div>
            </div>
            <div className="filter-swiper-blur image-left-style quote-box-style elementor-align-center text-center">
                <div className="dsn-testimonials dsn-swiper p-relative has-parallax-image"
                     data-dsn-option='{"spaceBetween":30,"slidesPerView":1}'>

                    <div className="testimonials-content">
                        <div className="testimonial-inner">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 viewBox="0 0 509 396.83"
                                 enable-background="new 0 0 509 396.83">
					        <g>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M105.098,396.83c-2.062,0-4.122,0-6.183,0 c0.123-48.731,0.116-97.466,0.493-146.195c0.062-7.844-3.65-8.881-10.09-8.843c-29.772,0.182-59.545,0.047-89.318,0.026 C0,161.382,0,80.947,0,0c81.742,0,163.484,0,245.227,0c0.023,83.874,0.496,167.752-0.071,251.624 c-0.436,64.131-47.354,121.936-109.503,136.974C125.404,391.076,115.279,394.073,105.098,396.83z M127.891,360.509 c6.063-1.545,10.049-2.226,13.799-3.568c42.174-15.098,74.277-58.896,74.735-104.55c0.718-71.273,0.187-142.558,0.454-213.837 c0.03-7.696-2.596-10.07-10.136-10.038c-55.961,0.236-111.927,0.287-167.887-0.042c-8.413-0.049-10.398,3.014-10.363,10.831 c0.254,54.408,0.317,108.815-0.047,163.22c-0.061,8.994,3.321,10.93,11.452,10.803c25.744-0.405,51.505,0.184,77.245-0.329 c8.78-0.174,10.986,2.931,10.911,11.301c-0.363,40.63-0.164,81.264-0.163,121.897C127.891,350.262,127.891,354.325,127.891,360.509 z"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M368.871,396.83c-2.061,0-4.122,0-6.184,0 c0.057-48.133-0.099-96.267,0.354-144.395c0.081-8.996-3.268-10.854-11.386-10.722c-26.047,0.42-52.108-0.083-78.152,0.299 c-7.577,0.108-10.083-2.194-10.053-9.942c0.294-77.357,0.262-154.713,0.322-232.07C345.353,0,426.929,0,509,0 c0,89.562,0,179.125,0,268.687c-1.256,3.464-2.793,6.854-3.73,10.401c-15.376,58.112-51.126,95.756-109.9,110.431 C386.48,391.735,377.7,394.382,368.871,396.83z M391.554,360.501c5.951-1.528,9.958-2.176,13.686-3.57 c43.103-16.135,74.464-59.44,74.864-105.611c0.616-70.931,0.138-141.869,0.404-212.803c0.03-7.722-2.58-10.037-10.111-10.005 c-55.963,0.24-111.926,0.285-167.889-0.036c-8.406-0.048-10.429,3.048-10.392,10.853c0.245,54.405,0.31,108.813-0.049,163.216 c-0.059,9.03,3.45,10.9,11.528,10.778c25.741-0.39,51.504,0.193,77.245-0.325c8.785-0.178,10.956,2.917,10.878,11.284 c-0.368,40.629-0.167,81.263-0.165,121.897C391.554,350.25,391.554,354.318,391.554,360.501z"/>
					        </g>
				        </svg>
                            <div className="swiper-container">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide testimonial-inner-item border-style">
                                        <div className="avatar box-img dsn-auto">
                                            <img src='assets/img/team/1.jpg' className="cover-bg-img" alt=''/>
                                        </div>
                                        <div className="testimonial-item">
                                            <div className="testimonial-content mb-25">
                                                <div className="quote">
                                                    <p className="max-w750 testimonial-content p-large">
                                                        This theme is awesome and the designer is very helpful. I
                                                        had a
                                                        few
                                                        questions
                                                        purchase. He/She helped me with all the doubts. Also, they
                                                        provide
                                                        quick
                                                        support. Thank you so much for a beautiful theme
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="content-inner border-top justify-content-center">
                                                <div className="d-flex align-items-center ">
                                                    <div className="avatar box-img dsn-auto">
                                                        <img src='assets/img/team/1.jpg'
                                                             className="cover-bg-img" alt=''/>
                                                    </div>
                                                    <div className="label box-text">
                                                        <h4 className="testimonial-name sm-title-block">MTL Graphic</h4>
                                                        <h5 className="testimonial-position">Graphic Design</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide testimonial-inner-item border-style">
                                        <div className="avatar box-img dsn-auto">
                                            <img src='assets/img/team/2.jpg' className="cover-bg-img" alt=''/>
                                        </div>
                                        <div className="testimonial-item">
                                            <div className="testimonial-content mb-25">
                                                <div className="quote">
                                                    <p className="max-w750 testimonial-content p-large">
                                                        This theme is awesome and the designer is very helpful. I
                                                        had a
                                                        few
                                                        questions
                                                        purchase. He/She helped me with all the doubts. Also, they
                                                        provide
                                                        quick
                                                        support. Thank you so much for a beautiful theme
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="content-inner border-top justify-content-center">
                                                <div className="d-flex align-items-center ">
                                                    <div className="avatar box-img dsn-auto">
                                                        <img src='assets/img/team/2.jpg'
                                                             className="cover-bg-img" alt=''/>
                                                    </div>
                                                    <div className="label box-text">
                                                        <h4 className="testimonial-name sm-title-block">Jeremy
                                                            Smith</h4>
                                                        <h5 className="testimonial-position">Creative Studio Head</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide testimonial-inner-item border-style">
                                        <div className="avatar box-img dsn-auto">
                                            <img src='assets/img/team/3.jpg' className="cover-bg-img" alt=''/>
                                        </div>
                                        <div className="testimonial-item">
                                            <div className="testimonial-content mb-25">
                                                <div className="quote">
                                                    <p className="max-w750 testimonial-content p-large">
                                                        This theme is awesome and the designer is very helpful. I
                                                        had a
                                                        few
                                                        questions
                                                        purchase. He/She helped me with all the doubts. Also, they
                                                        provide
                                                        quick
                                                        support. Thank you so much for a beautiful theme
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="content-inner border-top justify-content-center">
                                                <div className="d-flex align-items-center ">
                                                    <div className="avatar box-img dsn-auto">
                                                        <img src='assets/img/team/3.jpg'
                                                             className="cover-bg-img" alt=''/>
                                                    </div>
                                                    <div className="label box-text">
                                                        <h4 className="testimonial-name sm-title-block">Angelo
                                                            Walking</h4>
                                                        <h5 className="testimonial-position">Developer Lead</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide testimonial-inner-item border-style">
                                        <div className="avatar box-img dsn-auto">
                                            <img src='assets/img/team/4.jpg' className="cover-bg-img" alt=''/>
                                        </div>
                                        <div className="testimonial-item">
                                            <div className="testimonial-content mb-25">
                                                <div className="quote">
                                                    <p className="max-w750 testimonial-content p-large">
                                                        This theme is awesome and the designer is very helpful. I
                                                        had a
                                                        few
                                                        questions
                                                        purchase. He/She helped me with all the doubts. Also, they
                                                        provide
                                                        quick
                                                        support. Thank you so much for a beautiful theme
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="content-inner border-top justify-content-center">
                                                <div className="d-flex align-items-center ">
                                                    <div className="avatar box-img dsn-auto">
                                                        <img src='assets/img/team/4.jpg'
                                                             className="cover-bg-img" alt=''/>
                                                    </div>
                                                    <div className="label box-text">
                                                        <h4 className="testimonial-name sm-title-block">Bill
                                                            Gardner</h4>
                                                        <h5 className="testimonial-position">Web designer</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="dsn-swiper-paginate  d-flex p-relative w-100 h-100 mt-30 align-items-center container justify-content-between">
                            <div className="swiper-prev">
                                <div className="prev-container">
                                    <div className="container-inner">
                                        <div className="triangle"></div>
                                        <svg className="circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <g className="circle-wrap" fill="none" stroke-width="1"
                                               stroke-linejoin="round" stroke-miterlimit="10">
                                                <circle cx="12" cy="12" r="10.5"></circle>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination  mr-30 ml-30 heading-color" data-dsn-type="bullets"></div>
                            <div className="swiper-next">
                                <div className="next-container">
                                    <div className="container-inner">
                                        <div className="triangle"></div>
                                        <svg className="circle" xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24">
                                            <g className="circle-wrap" fill="none" stroke-width="1"
                                               stroke-linejoin="round" stroke-miterlimit="10">
                                                <circle cx="12" cy="12" r="10.5"></circle>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        {/* <!-- ========== End testimonials ========== --> */}

        {/* <!-- ========== Stories ========== --> */}
        <div className="p-relative background-section section-margin section-padding">
            <div className="container">
                <div className="section-title mb-70 d-flex">
                    <div className="sub-section-title ">
                        <p className="description d-inline-block p-relative square-before mb-10">Latest News</p>
                        <h2 className="title-h2 d-block heading-color body-font">Latest and <b>greatest post</b></h2>
                    </div>
                </div>
                <div className="p-relative dsn-style-classNameic box-image-transform">
                    <div className="root-posts ">
                        <div className="dsn-posts dsn-post-type-classNameic h-350 d-grid grid-lg-3 grid-sm-2">
                            <article className="dsn-item-post grid-item over-hidden p-20 border-style">
                                <div className="box-content d-flex ">
                                    <a className="effect-ajax box-image-link bg-shadow" href="single-post.html"
                                       data-dsn-text="Our Stories">
                                        <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                             data-overlay="4">
                                            <img src="assets/img/blog/1.jpg" className="cover-bg-img" alt=""/>
                                        </div>
                                    </a>
                                    <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column">

                                        <div className="post-title-info">
                                            <div className="post-meta max-w750">
                                                <div className="entry-date d-inline-block entry-meta mb-10">
                                                    June 12, 2022
                                                </div>
                                                <span className="mr-5 ml-5 separator-between"> ..</span>
                                                <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                    <span data-separator=" & ">Uncategorized</span>
                                                </div>
                                            </div>
                                            <h2 className="post-title dsn-bg title-block">
                                                <a className="effect-ajax" href="single-post.html"
                                                   data-dsn-text="Our Stories">
                                                    Wildlife could be amazing, see why.
                                                </a>
                                            </h2>
                                        </div>

                                        <div className="post-description-info ">
                                            <div className="d-flex mt-20 dsn-def-btn dsn-hover-icon">
                                                <a href="single-post.html"
                                                   className="effect-ajax dsn-btn dsn-border border-color-default  background-section has-icon-left">
                                            <span className="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                                            <i className="fas fa-angle-right"></i>
                                        </span>
                                                    <span className="title-btn p-relative  z-index-1 heading-color">READ ARTICLE</span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </article>
                            <article className="dsn-item-post grid-item over-hidden p-20 border-style">
                                <div className="box-content d-flex ">
                                    <a className="effect-ajax box-image-link bg-shadow" href="single-post.html"
                                       data-dsn-text="Our Stories">
                                        <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                             data-overlay="4">
                                            <img src="assets/img/blog/2.jpg" className="cover-bg-img" alt=""/>
                                        </div>
                                    </a>
                                    <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column">

                                        <div className="post-title-info">
                                            <div className="post-meta max-w750">
                                                <div className="entry-date d-inline-block entry-meta mb-10">
                                                    Marc 15, 2022
                                                </div>
                                                <span className="mr-5 ml-5 separator-between"> ..</span>
                                                <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                    <span data-separator=" & ">Editorial</span>
                                                </div>
                                            </div>
                                            <h2 className="post-title dsn-bg title-block">
                                                <a className="effect-ajax" href="single-post.html"
                                                   data-dsn-text="Our Stories">
                                                    Quick tips for an effective styled wedding
                                                </a>
                                            </h2>
                                        </div>

                                        <div className="post-description-info ">
                                            <div className="d-flex mt-20 dsn-def-btn dsn-hover-icon">
                                                <a href="single-post.html"
                                                   className="effect-ajax dsn-btn dsn-border border-color-default  background-section has-icon-left">
                                            <span className="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                                            <i className="fas fa-angle-right"></i>
                                        </span>
                                                    <span className="title-btn p-relative  z-index-1 heading-color">READ ARTICLE</span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </article>
                            <article className="dsn-item-post grid-item over-hidden p-20 border-style">
                                <div className="box-content d-flex ">
                                    <a className="effect-ajax box-image-link bg-shadow" href="single-post.html"
                                       data-dsn-text="Our Stories">
                                        <div className="box-image-bg before-z-index dsn-swiper-parallax-transform"
                                             data-overlay="4">
                                            <img src="assets/img/blog/3.jpg" className="cover-bg-img" alt=""/>
                                        </div>
                                    </a>
                                    <div className="post-content dsn-bg p-relative z-index-1 d-flex flex-column">

                                        <div className="post-title-info">
                                            <div className="post-meta max-w750">
                                                <div className="entry-date d-inline-block entry-meta mb-10">
                                                    Oct 23, 2022
                                                </div>
                                                <span className="mr-5 ml-5 separator-between"> ..</span>
                                                <div className="p-relative d-inline-block dsn-category dsn-bg metas mb-10 entry-meta">
                                                    <span data-separator=" & ">Editorial</span>
                                                </div>
                                            </div>
                                            <h2 className="post-title dsn-bg title-block">
                                                <a className="effect-ajax" href="single-post.html"
                                                   data-dsn-text="Our Stories">
                                                    Soul Artwork from Lizzy styled wedding
                                                </a>
                                            </h2>
                                        </div>

                                        <div className="post-description-info ">
                                            <div className="d-flex mt-20 dsn-def-btn dsn-hover-icon">
                                                <a href="single-post.html"
                                                   className="effect-ajax dsn-btn dsn-border border-color-default  background-section has-icon-left">
                                            <span className="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                                            <i className="fas fa-angle-right"></i>
                                        </span>
                                                    <span className="title-btn p-relative  z-index-1 heading-color">READ ARTICLE</span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        {/* <!-- ========== End Stories ========== --> */}

        {/* <!-- ========== Brand ========== --> */}
        <div className="p-relative container section-margin">
            <div className="section-title mb-70 d-flex">
                <div className="sub-section-title ">
                    <p className="description d-inline-block p-relative square-before mb-10">Our Clients </p>
                    <h2 className="title-h2 d-block heading-color body-font">Your successful,  <b>our reputation</b></h2>
                </div>
            </div>
            <div className="d-grid grid-md-4 grid-sm-2 d-grid-no-space dsn-brand border-style" >
                <div className="brand-item border-bottom border-right">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/1.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item border-bottom border-right">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/2.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item border-bottom border-right">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/3.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item border-bottom">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/4.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item border-right">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/5.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item border-right">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/6.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item border-right">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/1.svg" alt="" />
                    </div>
                </div>
                <div className="brand-item">
                    <div className="brand-item-inner  over-hidden p-relative ">
                        <img src="assets/img/brand/4.svg" alt="" />
                    </div>
                </div>

            </div>
        </div>
        {/* <!-- ========== End Brand ========== --> */}

        {/* <!-- ========== Footer ========== --> */}
        <footer id="dsn_footer" className="dsn-footer p-relative footer-animate svg-animate background-section">
            <svg className="dsn-separator dsn-separator-up  dsn-icon-main-color" width="100%" height="100%"
                 viewBox="0 0 100 10" preserveAspectRatio="none">
                <path className="path-anim separator__path" data-dsn-to="M 0 0 C 40 0 55 0 100 0 L 0 0 Z"
                      vector-effect="non-scaling-stroke" d="M 0 0 C 40 16 75 10 100 0 L 0 0 Z"></path>
            </svg>
            <div className="footer-content p-relative mt-section">

                <div className="text-center container">
                    <h2 className="title-h2 ">
                        LET'S BUILD SOMETHING
                        <span className="d-block"><b><i>AMAZING </i></b>TOGETHER</span>
                    </h2>
                    <div className="dsn-icon-heading-color dsn-def-btn d-flex justify-content-center mt-30">
                        <a className="dsn-btn dsn-border border-color-default  background-main  effect-ajax has-icon-left"
                           href="contact.html" data-dsn-text="START A PROJECT">
                        <span className="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                            <i className="fas fa-angle-right" aria-hidden="true"></i>
                        </span>
                            <span className="title-btn p-relative  z-index-1 ">START A PROJECT</span>
                        </a>
                    </div>

                </div>
                <div className="dsn-container pt-60">
                    <div className="d-grid grid-lg-4 grid-md-2 ">
                        <div className="grid-col-1">
                            <div className="dsn-logo main-logo">
                                <div className="main-logo">
                                    <a href="https://dsngrid.com/blackdsn/" data-dsn-text="Blackdsn"
                                       className="custom-logo-link main-brand effect-ajax" rel="home">
                                        <img className="custom-logo logo-light" src="assets/img/logo-light.png"
                                             alt="Blackdsn"/>
                                        <img className="custom-logo logo-dark" src="assets/img/logo.png"
                                             alt="Blackdsn"/>
                                    </a>
                                </div>
                            </div>
                            <ul className="dsn-socials box-social mt-20 dsn-icon-heading-color">
                                <li><a href="#0" target="_blank" rel="nofollow"
                                       className="init-color move-circle border-color-default" data-dsn="parallax"><span
                                        className="dsn-icon"><i className="fab fa-facebook-f"></i></span><span>FB</span></a>
                                </li>
                                <li><a href="#0" target="_blank" rel="nofollow"
                                       className="init-color move-circle border-color-default" data-dsn="parallax"><span
                                        className="dsn-icon"><i className="fab fa-twitter"></i></span><span>TW</span></a>
                                </li>
                                <li><a href="#0" target="_blank" rel="nofollow"
                                       className="init-color move-circle border-color-default" data-dsn="parallax"><span
                                        className="dsn-icon"><i
                                        className="fab fa-linkedin-in"></i></span><span>LN</span></a></li>
                                <li><a href="#0" target="_blank" rel="nofollow"
                                       className="init-color move-circle border-color-default" data-dsn="parallax"><span
                                        className="dsn-icon"><i className="fab fa-instagram"></i></span><span>IN</span></a>
                                </li>
                            </ul>
                        </div>
                        <div className="grid-col-2">
                            <h4 className="subtitle p-relative line-shape  line-shape-after "><span
                                    className="background-revere ">NAVIGATION</span>
                            </h4>
                            <div className="menu-footer-menu-container">
                                <ul id="menu-footer-menu" className="menu">
                                    <li className="menu-item">
                                        <a href="home.html" className="effect-ajax">HOME</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="work-classNameic-2.html" className="effect-ajax">PORTFOLIO</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="stories.html" className="effect-ajax">NEWS</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="about-us.html" className="effect-ajax">ABOUT</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="contact.html" className="effect-ajax">CONTACT</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="grid-col-3">
                            <h4 className="subtitle p-relative line-shape  line-shape-after "><span
                                    className="background-revere ">CONTACT</span>
                            </h4>

                            <div className="col-contact">
                                <p><strong>T</strong> : +001 225 3351</p>
                                <p className="mt-10"><strong>F</strong> :+001 225 3351</p>
                                <p className="over-hidden mt-10">
                                    <strong>E</strong> : <a className="link-hover" href="#"
                                                            data-hover-text="info@dsngrid.com">info@dsngrid.com</a>
                                </p>
                            </div>

                        </div>
                        <div className="grid-col-4">
                            <h4 className="subtitle p-relative line-shape  line-shape-after "><span
                                    className="background-revere ">ADDRESS</span>
                            </h4>
                            <div className="col-address">
                                <p>
                                    El-Mahalla El-Kubra 37<br/> 1776 Damietta<br/>Egypt
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom mt-60 pt-30 border-top pb-30 text-center">
                        <p className="over-hidden">
                            © 2022 Digital Agency Designed by
                            <a href="#0" className="link-hover" data-hover-text="DSN Grid" target="_blank">DSN Grid</a>
                        </p>
                    </div>
                </div>


            </div>
        </footer>
        {/* <!-- ========== End Footer ========== --> */}

    </div>




</main>


{/* // <!-- ========== Cursor ========== --> */}
<div id="dsn_cursor" className="ball"></div>
<div id="dsn_cursor_inner" className="ball"></div>
{/* <!-- ========== End Cursor ========== --> */}


{/* <!-- ==========  Social ========== --> */}
<div className="social-side d-flex align-items-center flex-column justify-content-center">

    <ul className="socials d-flex flex-column-reverse">
        <li style={{"--dsn-index-animate" : "0"}}>
            <a href="#0" target="_blank" rel="nofollow">
                <i className="fab fa-facebook-f" aria-hidden="true"></i> <span>Fb</span>
            </a>
        </li>
        <li style={{"--dsn-index-animate" : "1"}}>
            <a href="#0" target="_blank" rel="nofollow">
                <i className="fab fa-instagram" aria-hidden="true"></i><span>Instagram</span>
            </a>
        </li>
        <li style={{"--dsn-index-animate" : "2"}}>
            <a href="#0" target="_blank" rel="nofollow">
                <i className="fab fa-twitter" aria-hidden="true"></i><span>Tw</span>
            </a>
        </li>
        <li style={{"--dsn-index-animate" : "3"}}>
            <a href="#0" target="_blank" rel="nofollow">
                <i className="fab fa-linkedin-in" aria-hidden="true"></i><span>Linkedin</span>
            </a>
        </li>
    </ul>
    <div className="icon">FOLLOW US</div>
</div>


</>
  )
}
