import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import aboutimg6 from "../assets/img/about-6.jpg";
import aboutimg7 from "../assets/img/about-7.jpg";
import ourpassion from "../assets/img/our-passion.jpg";
import Lightbox from "react-awesome-lightbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

const About = () => {
  const [current, setcurrent] = useState([{
    url: "",
    title: "",
  },]);
  let images = [
    {
      url: aboutimg6,
      title: "image title 1",
    },
    {
      url: aboutimg7,
      title: "image title 2",
    },
  ];
  const handleClickImage = (image) => {
    setcurrent([{
      url: image.url,
      title: "image title 1",
    }]);
    console.log(current);
  };
  const handleCloseModal = () => {
    setcurrent("");
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div class="about-section about-arc section-margin">
        <div class="container d-grid grid-md-2 d-grid-no-space">
          <div class="box-left box-padding">
            <Fade top>
              <h2 class="title-h2 p-relative body-font border-section-bottom heading-color text-upper">
                Your Financial Goals, Our Passion
              </h2>
            </Fade>

            <Fade top>
              <p class="max-w570 mt-20">
                At ZroTax, we believe in turning financial aspirations into
                reality. Our dedicated team, with its rich blend of expertise
                and empathy, is committed to crafting bespoke financial
                strategies that resonate with your personal goals, from securing
                your family's future to realizing your dream home.
              </p>
            </Fade>

            <div class="dsn-def-btn dsn-icon-heading-color mt-30 d-flex">
              <Fade top>
                <a
                  class="dsn-btn dsn-border border-color-default  background-section effect-ajax effect-ajax  move-circle has-icon-left"
                  href="#"
                  data-dsn-text="STUDIO"
                  data-dsn="parallax"
                >
                  <span class="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                    <i class="fas fa-angle-right"></i>
                  </span>
                  <span class="title-btn p-relative  z-index-1 heading-color">
                    BOOK AN APPOINTMENT
                  </span>
                </a>
              </Fade>
            </div>
          </div>

          <div class="box-right box-creative p-relative">
            <div class="p-relative border-rdu over-hidden h-100 About-center-img">
              <Fade top>
                <div class="img-box-parallax h-100 w-100 before-z-index dsn-animate dsn-effect-left border-rdu">
                  <img
                    src={ourpassion}
                    class="cover-bg-img has-direction"
                    alt=""
                  />
                </div>
              </Fade>
            </div>

            <div class="img-top">
              <Fade top>
                <img src={aboutimg6} class="cover-bg-img border-rdu" alt="" />
              </Fade>
            </div>
            <div class="img-bottom">
              <Fade top>
                <img src={aboutimg7} class="cover-bg-img border-rdu" alt="" />
              </Fade>
            </div>
          </div>
        </div>
  
      </div>
    </>
  );
};

export default About;
