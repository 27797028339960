import React from "react";
import { Header } from "./Header";
import Footer from "./Footer";
import Fade from "react-reveal/Fade";
import taxPlanning from "../assets/img/s1.jpg";
import financialPlanning from "../assets/img/personal.jpg";
export const Service = () => {
  return (
    <>
      {" "}
      {/*<Header />*/}
      <header id="dsn_header" class="dsn-header-animation header-normal">
        <div class="background-overlay bg-pattern p-absolute left-0 top-0 w-100 h-100"></div>

        <div class="entry-header p-relative over-hidden">
          <div
            id="hero_content"
            class="d-flex p-relative h-100 dsn-hero-parallax-title container align-items-center pb-section "
          >
            <div class="content p-relative ">
              <div class="intro-project w-100">
                <div class="intro-title ">
                  {/*<Fade bottom>
                    <p class="dsn-heading-title d-inline-block circle-before">OUR SERVICES </p>
                  </Fade>*/}
                  <div id="hero_title" class="mt-10">
                    <Fade bottom>
                      <h1 class="title text-upper">OUR SERVICES</h1>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#page_wrapper"
          rel="nofollow"
          class="dsn-scroll-bottom"
          data-dsn-option='{"ease": "power4.inOut" , "duration" : 1.5}'
        >
          <div class="text">SCROLL</div>
        </a>
      </header>
      <div class="parent-service-section">
        <div class="pt-section mb-section background-section service-section">
          <div class="container">
            <div class="d-grid grid-md-2" data-dsn-gap="30px 60px">
              <div class="left">
                <Fade bottom>
                  <h5 class="title-h2 body-font text-upper">
                    <b>Tax planning</b>
                  </h5>
                </Fade>
                <Fade bottom>
                  <p class="mt-20">
                    Taxes are an inevitable part of life, but they don’t have to
                    be a major burden. With careful planning, you can minimise
                    your tax liability and keep more of your hard-earned money.
                  </p>
                </Fade>
                <div class="service-content p-relative mt-20">
                  <Fade bottom>
                    <h4 class="service_title title-block">
                      Our tax planning services can help you:{" "}
                    </h4>
                  </Fade>
                  <Fade bottom>
                    <ul class="d-grid grid-sm-2 mt-20 dsn-list grid-row-gap-10 service-lists-all">
                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Understand your current tax situation
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Identify tax-saving opportunities
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Develop a tax-efficient plan for your future
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Prepare and file your tax returns accurately and on
                          time{" "}
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Resolve tax issues with the Income Tax
                        </p>
                      </li>
                    </ul>
                  </Fade>
                  {/* <!--<div class="service_description mt-10 max-w570 dsn-auto">
                    <div class="right align-self-end">
                        <p>
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration, Injected humour words web page editors now use as their.
                        </p>
                        <p class="mt-20">
                            Default and a search for will uncover many web sites still in their infancy.suffered
                            alteration, by injected humour words web page.
                        </p>
                    </div>
                </div>--> */}
                </div>
              </div>

              <div class="p-relative box-img">
                <Fade bottom>
                  <div class=" mt-50 z-index-2">
                    <img src={taxPlanning} alt="" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-section mb-section background-section service-section">
          <div class="container">
            <div class="d-grid grid-md-2 mt-40" data-dsn-gap="30px 60px">
              <div class="p-relative box-img">
                <Fade bottom>
                  <div class="mt-50 z-index-2">
                    <img src={financialPlanning} alt="" />
                  </div>
                </Fade>
              </div>
              <div class="left">
                <Fade bottom>
                  <h5 class="title-h2 body-font text-upper">
                    <b>Financial Planning</b>
                  </h5>

                  <p class="mt-20">
                    Everyone has different financial goals, whether it’s saving
                    for retirement, buying a home, or paying for college for
                    their children. Whatever your goals are, we can help you
                    develop a plan to achieve them.
                  </p>
                </Fade>
                <Fade bottom>
                  <div class="mt-20 service-content p-relative">
                    <h4 class="service_title title-block">
                      Our financial planning services can help you:{" "}
                    </h4>
                    <ul class="d-grid grid-sm-2 mt-20 dsn-list grid-row-gap-10 service-lists-all">
                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Set realistic financial goals
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Create a budget that works for you
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Develop a savings plan
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Invest your money wisely{" "}
                        </p>
                      </li>

                      <li class="list-item grid-item d-flex align-items-center">
                        <span class="dsn-icon mr-15">
                          <i class="far fa-circle" aria-hidden="true"></i>
                        </span>
                        <p class="dsn-heading-title  ">
                          Protect your assets with insurance
                        </p>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-section mb-section background-section service-section">
          <div class="container section-margin">
            <div class="section-title mb-70 text-center d-flex flex-column">
              <h2 class="title-h2 text-upper">Our Services</h2>
              <p class="max-w570 mt-20 border-section-bottom">
                In addition to tax and financial planning, we also offer a
                variety of other services, including:
              </p>
            </div>
            <div class="list-with-number icon-top dsn-icon-theme-color text-center">
              <div
                class="dsn-service d-grid grid-md-4 grid-sm-2 dsn-masonry-grid "
                data-dsn-iconsize="80px"
              >
                <Fade bottom>
                  <div class="dsn-up service-item p-relative grid-item ">
                    <div class="service-item-inner number-item h-100 background-section border-rdu">
                      <div class="dsn-icon">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="50px"
                          height="50px"
                          viewBox="0 0 50 50"
                        >
                          <g>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.072,40.553c0-0.932-0.062-1.868,0.013-2.794  c0.146-1.81-0.475-3.234-1.633-4.647c-1.268-1.547-2.404-3.274-3.239-5.089c-2.214-4.811-1.168-9.314,1.994-13.308  c3.294-4.164,7.795-5.363,12.816-4.145c5.08,1.233,8.364,4.637,9.375,9.721c0.67,3.365,0.388,6.819-2.079,9.689  c-0.868,1.011-1.687,2.122-2.249,3.318c-0.621,1.318-1.179,2.764-1.305,4.193c-0.222,2.52-0.048,5.071-0.133,7.605  c-0.068,2.067-2.064,4.004-4.106,4.059c-1.696,0.045-3.395,0.022-5.093,0.01c-2.438-0.018-4.307-1.811-4.394-4.262  c-0.052-1.448-0.009-2.9-0.009-4.351C18.044,40.553,18.058,40.553,18.072,40.553z M29.94,41.686c0-1.752-0.168-3.318,0.051-4.828  c0.194-1.339,0.652-2.706,1.297-3.897c0.728-1.344,1.849-2.469,2.705-3.752c3.775-5.662,2.175-12.419-3.692-15.824  c-4.225-2.452-9.512-1.524-12.7,1.058c-2.954,2.392-5.222,7.669-3.05,12.026c0.78,1.565,1.752,3.034,2.618,4.558  c0.761,1.339,1.444,2.724,2.27,4.019c0.262,0.412,0.863,0.845,1.318,0.856c1.754,0.044,3.512-0.096,5.27-0.13  c0.693-0.015,1.626-0.207,1.623,0.894c-0.003,1.088-0.926,0.904-1.626,0.908c-2.005,0.013-4.01,0.005-6.139,0.005  c0,2.522-0.13,5.002,0.05,7.458c0.103,1.41,0.977,2.499,2.635,2.528c1.346,0.024,2.692,0.075,4.039,0.059  c2.395-0.029,3.558-1.525,3.075-4.293c-2.051,0-4.161,0.048-6.266-0.036c-0.464-0.018-0.91-0.496-1.364-0.763  c0.47-0.281,0.931-0.785,1.412-0.807C25.527,41.633,27.596,41.686,29.94,41.686z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M12.989,10.347c-0.546,0.164-1.233,0.569-1.437,0.398  C10.38,9.76,9.294,8.665,8.257,7.536C8.094,7.359,8.201,6.679,8.414,6.484c0.216-0.197,0.87-0.252,1.07-0.074  c1.073,0.952,2.07,1.99,3.078,3.015C12.695,9.56,12.735,9.785,12.989,10.347z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M37.373,10.994c-0.099-0.492-0.441-1.185-0.246-1.411  c1.004-1.159,2.125-2.219,3.264-3.25c0.158-0.143,0.743-0.042,0.94,0.146c0.202,0.194,0.346,0.792,0.214,0.937  c-1.069,1.167-2.205,2.273-3.336,3.383C38.117,10.89,37.918,10.873,37.373,10.994z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M25.748,3.132c-0.001,0.618,0.112,1.265-0.041,1.842  c-0.103,0.389-0.58,0.678-0.889,1.013c-0.276-0.315-0.765-0.613-0.792-0.947c-0.096-1.227-0.11-2.473,0.001-3.697  c0.033-0.363,0.578-0.679,0.888-1.017c0.277,0.37,0.69,0.704,0.799,1.119C25.854,1.973,25.749,2.566,25.748,3.132z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M43.725,23.819c-0.406-0.043-0.854,0.003-1.205-0.158  c-0.268-0.123-0.608-0.546-0.565-0.769c0.06-0.315,0.435-0.796,0.692-0.807c1.327-0.057,2.663-0.028,3.989,0.072  c0.305,0.022,0.58,0.445,0.869,0.684c-0.339,0.265-0.655,0.713-1.021,0.758c-0.905,0.11-1.835,0.036-2.755,0.036  C43.728,23.696,43.726,23.758,43.725,23.819z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M3.5,24.01c-0.448-0.367-0.896-0.733-1.343-1.101  c0.493-0.293,0.963-0.779,1.482-0.837c0.958-0.106,1.954-0.026,2.915,0.112c0.416,0.061,0.783,0.457,1.172,0.701  c-0.37,0.255-0.72,0.687-1.113,0.731c-0.962,0.107-1.945,0.035-2.92,0.035C3.629,23.771,3.564,23.89,3.5,24.01z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M25.306,16.083c3.552,0.405,6.106,2.751,6.305,6.291  c0.022,0.39-0.47,0.808-0.723,1.212c-0.27-0.31-0.708-0.583-0.783-0.935c-0.595-2.77-2.146-4.448-5.048-4.813  c-0.33-0.04-0.586-0.662-0.876-1.014C24.64,16.521,25.099,16.218,25.306,16.083z"
                            ></path>
                          </g>
                        </svg> */}
                        <i class="fa-solid fa-money-bill"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  title-block">
                          Passive Source Income Planning
                        </h4>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade top>
                  <div class="dsn-up service-item p-relative grid-item style-box">
                    <div class="service-item-inner number-item h-100 background-section border-rdu">
                      <div class="dsn-icon">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="50px"
                          height="50px"
                          viewBox="0 0 50 50"
                        >
                          <g>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M25.515,27.051c-0.371-0.159-0.756-0.298-1.117-0.483  c-6.626-3.398-13.254-6.794-19.865-10.221c-0.416-0.215-1.031-0.76-0.972-1.019c0.102-0.446,0.593-0.919,1.04-1.152  c6.651-3.461,13.312-6.904,20.007-10.279c0.516-0.259,1.414-0.177,1.955,0.099c3.589,1.828,7.11,3.788,10.689,5.634  c3.028,1.562,6.114,3.01,9.149,4.558c0.454,0.232,0.77,0.738,1.149,1.116c-0.358,0.331-0.665,0.76-1.082,0.977  c-6.629,3.457-13.272,6.887-19.914,10.319C26.234,26.765,25.895,26.888,25.515,27.051z M44.76,15.372  c-0.513-0.362-0.775-0.596-1.078-0.752c-5.791-2.994-11.577-5.999-17.402-8.927c-0.479-0.24-1.32-0.129-1.835,0.131  c-5.546,2.792-11.063,5.643-16.583,8.488c-0.508,0.262-0.983,0.59-1.696,1.021c6.433,3.309,12.539,6.471,18.68,9.562  c0.432,0.217,1.206,0.052,1.695-0.189c2.922-1.443,5.806-2.963,8.705-4.454C38.347,18.659,41.449,17.07,44.76,15.372z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M6.153,25.292c1.245,0.667,2.225,1.209,3.22,1.722  c5.014,2.583,10.02,5.182,15.065,7.701c0.544,0.272,1.446,0.323,1.973,0.059c5.826-2.926,11.61-5.933,17.403-8.924  c0.257-0.133,0.479-0.329,0.912-0.632c-1.152-0.605-2.191-1.057-3.114-1.681c-0.401-0.271-0.563-0.897-0.833-1.363  c0.501-0.038,1.084-0.26,1.489-0.081c1.456,0.645,2.868,1.395,4.266,2.163c1.333,0.732,1.365,1.37,0.078,2.036  c-6.755,3.495-13.519,6.975-20.308,10.403c-0.455,0.229-1.24,0.185-1.71-0.053c-6.743-3.407-13.462-6.859-20.174-10.326  c-1.297-0.67-1.284-1.349,0.015-2.057c1.454-0.792,2.922-1.563,4.431-2.241c0.35-0.157,0.885,0.097,1.334,0.162  c-0.185,0.413-0.256,0.999-0.575,1.206C8.602,24.052,7.487,24.577,6.153,25.292z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M44.767,35.25c-1.224-0.643-2.298-1.123-3.27-1.759  c-0.362-0.237-0.485-0.838-0.718-1.272c0.493-0.05,1.072-0.29,1.465-0.115c1.51,0.674,2.977,1.452,4.43,2.247  c1.189,0.651,1.184,1.312-0.063,1.957c-6.71,3.476-13.429,6.936-20.176,10.341c-0.502,0.253-1.354,0.251-1.857-0.002  c-6.748-3.402-13.468-6.859-20.181-10.33c-1.246-0.645-1.257-1.307-0.06-1.968c1.496-0.824,3.012-1.618,4.566-2.322  c0.339-0.154,0.877,0.129,1.323,0.211c-0.203,0.407-0.299,0.974-0.627,1.189c-0.968,0.637-2.028,1.135-3.331,1.838  c0.666,0.42,1.023,0.683,1.412,0.884c5.565,2.872,11.125,5.757,16.72,8.57c0.548,0.275,1.453,0.354,1.977,0.091  c5.83-2.921,11.619-5.925,17.416-8.912C44.055,35.763,44.287,35.572,44.767,35.25z"
                            ></path>
                          </g>
                        </svg> */}
                        <i class="fa-solid fa-business-time"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  title-block">
                          Business Set Up Planning
                        </h4>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade top>
                  <div class="dsn-up service-item p-relative grid-item ">
                    <div class="service-item-inner number-item h-100 background-section border-rdu">
                      <div class="dsn-icon">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="50px"
                          height="50px"
                          viewBox="0 0 50 50"
                        >
                          <g>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.072,40.553c0-0.932-0.062-1.868,0.013-2.794  c0.146-1.81-0.475-3.234-1.633-4.647c-1.268-1.547-2.404-3.274-3.239-5.089c-2.214-4.811-1.168-9.314,1.994-13.308  c3.294-4.164,7.795-5.363,12.816-4.145c5.08,1.233,8.364,4.637,9.375,9.721c0.67,3.365,0.388,6.819-2.079,9.689  c-0.868,1.011-1.687,2.122-2.249,3.318c-0.621,1.318-1.179,2.764-1.305,4.193c-0.222,2.52-0.048,5.071-0.133,7.605  c-0.068,2.067-2.064,4.004-4.106,4.059c-1.696,0.045-3.395,0.022-5.093,0.01c-2.438-0.018-4.307-1.811-4.394-4.262  c-0.052-1.448-0.009-2.9-0.009-4.351C18.044,40.553,18.058,40.553,18.072,40.553z M29.94,41.686c0-1.752-0.168-3.318,0.051-4.828  c0.194-1.339,0.652-2.706,1.297-3.897c0.728-1.344,1.849-2.469,2.705-3.752c3.775-5.662,2.175-12.419-3.692-15.824  c-4.225-2.452-9.512-1.524-12.7,1.058c-2.954,2.392-5.222,7.669-3.05,12.026c0.78,1.565,1.752,3.034,2.618,4.558  c0.761,1.339,1.444,2.724,2.27,4.019c0.262,0.412,0.863,0.845,1.318,0.856c1.754,0.044,3.512-0.096,5.27-0.13  c0.693-0.015,1.626-0.207,1.623,0.894c-0.003,1.088-0.926,0.904-1.626,0.908c-2.005,0.013-4.01,0.005-6.139,0.005  c0,2.522-0.13,5.002,0.05,7.458c0.103,1.41,0.977,2.499,2.635,2.528c1.346,0.024,2.692,0.075,4.039,0.059  c2.395-0.029,3.558-1.525,3.075-4.293c-2.051,0-4.161,0.048-6.266-0.036c-0.464-0.018-0.91-0.496-1.364-0.763  c0.47-0.281,0.931-0.785,1.412-0.807C25.527,41.633,27.596,41.686,29.94,41.686z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M12.989,10.347c-0.546,0.164-1.233,0.569-1.437,0.398  C10.38,9.76,9.294,8.665,8.257,7.536C8.094,7.359,8.201,6.679,8.414,6.484c0.216-0.197,0.87-0.252,1.07-0.074  c1.073,0.952,2.07,1.99,3.078,3.015C12.695,9.56,12.735,9.785,12.989,10.347z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M37.373,10.994c-0.099-0.492-0.441-1.185-0.246-1.411  c1.004-1.159,2.125-2.219,3.264-3.25c0.158-0.143,0.743-0.042,0.94,0.146c0.202,0.194,0.346,0.792,0.214,0.937  c-1.069,1.167-2.205,2.273-3.336,3.383C38.117,10.89,37.918,10.873,37.373,10.994z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M25.748,3.132c-0.001,0.618,0.112,1.265-0.041,1.842  c-0.103,0.389-0.58,0.678-0.889,1.013c-0.276-0.315-0.765-0.613-0.792-0.947c-0.096-1.227-0.11-2.473,0.001-3.697  c0.033-0.363,0.578-0.679,0.888-1.017c0.277,0.37,0.69,0.704,0.799,1.119C25.854,1.973,25.749,2.566,25.748,3.132z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M43.725,23.819c-0.406-0.043-0.854,0.003-1.205-0.158  c-0.268-0.123-0.608-0.546-0.565-0.769c0.06-0.315,0.435-0.796,0.692-0.807c1.327-0.057,2.663-0.028,3.989,0.072  c0.305,0.022,0.58,0.445,0.869,0.684c-0.339,0.265-0.655,0.713-1.021,0.758c-0.905,0.11-1.835,0.036-2.755,0.036  C43.728,23.696,43.726,23.758,43.725,23.819z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M3.5,24.01c-0.448-0.367-0.896-0.733-1.343-1.101  c0.493-0.293,0.963-0.779,1.482-0.837c0.958-0.106,1.954-0.026,2.915,0.112c0.416,0.061,0.783,0.457,1.172,0.701  c-0.37,0.255-0.72,0.687-1.113,0.731c-0.962,0.107-1.945,0.035-2.92,0.035C3.629,23.771,3.564,23.89,3.5,24.01z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M25.306,16.083c3.552,0.405,6.106,2.751,6.305,6.291  c0.022,0.39-0.47,0.808-0.723,1.212c-0.27-0.31-0.708-0.583-0.783-0.935c-0.595-2.77-2.146-4.448-5.048-4.813  c-0.33-0.04-0.586-0.662-0.876-1.014C24.64,16.521,25.099,16.218,25.306,16.083z"
                            ></path>
                          </g>
                        </svg> */}
                        <i class="fa-regular fa-file"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  title-block">
                          Retirement Planning
                        </h4>
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade top>
                  <div class="dsn-up service-item p-relative grid-item ">
                    <div class="service-item-inner number-item h-100 background-section border-rdu">
                      <div class="dsn-icon">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="50px"
                          height="50px"
                          viewBox="0 0 50 50"
                        >
                          <g>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.072,40.553c0-0.932-0.062-1.868,0.013-2.794  c0.146-1.81-0.475-3.234-1.633-4.647c-1.268-1.547-2.404-3.274-3.239-5.089c-2.214-4.811-1.168-9.314,1.994-13.308  c3.294-4.164,7.795-5.363,12.816-4.145c5.08,1.233,8.364,4.637,9.375,9.721c0.67,3.365,0.388,6.819-2.079,9.689  c-0.868,1.011-1.687,2.122-2.249,3.318c-0.621,1.318-1.179,2.764-1.305,4.193c-0.222,2.52-0.048,5.071-0.133,7.605  c-0.068,2.067-2.064,4.004-4.106,4.059c-1.696,0.045-3.395,0.022-5.093,0.01c-2.438-0.018-4.307-1.811-4.394-4.262  c-0.052-1.448-0.009-2.9-0.009-4.351C18.044,40.553,18.058,40.553,18.072,40.553z M29.94,41.686c0-1.752-0.168-3.318,0.051-4.828  c0.194-1.339,0.652-2.706,1.297-3.897c0.728-1.344,1.849-2.469,2.705-3.752c3.775-5.662,2.175-12.419-3.692-15.824  c-4.225-2.452-9.512-1.524-12.7,1.058c-2.954,2.392-5.222,7.669-3.05,12.026c0.78,1.565,1.752,3.034,2.618,4.558  c0.761,1.339,1.444,2.724,2.27,4.019c0.262,0.412,0.863,0.845,1.318,0.856c1.754,0.044,3.512-0.096,5.27-0.13  c0.693-0.015,1.626-0.207,1.623,0.894c-0.003,1.088-0.926,0.904-1.626,0.908c-2.005,0.013-4.01,0.005-6.139,0.005  c0,2.522-0.13,5.002,0.05,7.458c0.103,1.41,0.977,2.499,2.635,2.528c1.346,0.024,2.692,0.075,4.039,0.059  c2.395-0.029,3.558-1.525,3.075-4.293c-2.051,0-4.161,0.048-6.266-0.036c-0.464-0.018-0.91-0.496-1.364-0.763  c0.47-0.281,0.931-0.785,1.412-0.807C25.527,41.633,27.596,41.686,29.94,41.686z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M12.989,10.347c-0.546,0.164-1.233,0.569-1.437,0.398  C10.38,9.76,9.294,8.665,8.257,7.536C8.094,7.359,8.201,6.679,8.414,6.484c0.216-0.197,0.87-0.252,1.07-0.074  c1.073,0.952,2.07,1.99,3.078,3.015C12.695,9.56,12.735,9.785,12.989,10.347z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M37.373,10.994c-0.099-0.492-0.441-1.185-0.246-1.411  c1.004-1.159,2.125-2.219,3.264-3.25c0.158-0.143,0.743-0.042,0.94,0.146c0.202,0.194,0.346,0.792,0.214,0.937  c-1.069,1.167-2.205,2.273-3.336,3.383C38.117,10.89,37.918,10.873,37.373,10.994z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M25.748,3.132c-0.001,0.618,0.112,1.265-0.041,1.842  c-0.103,0.389-0.58,0.678-0.889,1.013c-0.276-0.315-0.765-0.613-0.792-0.947c-0.096-1.227-0.11-2.473,0.001-3.697  c0.033-0.363,0.578-0.679,0.888-1.017c0.277,0.37,0.69,0.704,0.799,1.119C25.854,1.973,25.749,2.566,25.748,3.132z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M43.725,23.819c-0.406-0.043-0.854,0.003-1.205-0.158  c-0.268-0.123-0.608-0.546-0.565-0.769c0.06-0.315,0.435-0.796,0.692-0.807c1.327-0.057,2.663-0.028,3.989,0.072  c0.305,0.022,0.58,0.445,0.869,0.684c-0.339,0.265-0.655,0.713-1.021,0.758c-0.905,0.11-1.835,0.036-2.755,0.036  C43.728,23.696,43.726,23.758,43.725,23.819z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M3.5,24.01c-0.448-0.367-0.896-0.733-1.343-1.101  c0.493-0.293,0.963-0.779,1.482-0.837c0.958-0.106,1.954-0.026,2.915,0.112c0.416,0.061,0.783,0.457,1.172,0.701  c-0.37,0.255-0.72,0.687-1.113,0.731c-0.962,0.107-1.945,0.035-2.92,0.035C3.629,23.771,3.564,23.89,3.5,24.01z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              style={{
                                "--dsn-color-icon": "var(--heading-color)",
                              }}
                              d="M25.306,16.083c3.552,0.405,6.106,2.751,6.305,6.291  c0.022,0.39-0.47,0.808-0.723,1.212c-0.27-0.31-0.708-0.583-0.783-0.935c-0.595-2.77-2.146-4.448-5.048-4.813  c-0.33-0.04-0.586-0.662-0.876-1.014C24.64,16.521,25.099,16.218,25.306,16.083z"
                            ></path>
                          </g>
                        </svg> */}
                        <i class="fa-solid fa-vr-cardboard"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  title-block">
                          Virtual CFO for Startups
                        </h4>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              <div class="text-calls">
                <h3>Contact us today to schedule a consultation.</h3>
                <p>
                  We look forward to helping you achieve your financial goals!
                </p>
                <div class="d-flex dsn-up justify-content-center">
                  <div
                    class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                    data-dsn="parallax"
                  >
                    <a href="">Book Appointment</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
