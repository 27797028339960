import React from "react";
import Fade from "react-reveal/Fade";

import ClientBg from "../assets/img/client-bg.png";



const OurClient = () => {
    return (
        <>
            <div class="about-section about-arc section-margin">
                <div class="p-relative container section-margin">
                    <div class="section-title d-flex justify-content-center  mb-70">
                        <Fade bottom>
                            <div class="sub-section-title text-center">

                                <h2 class="title-h2 dsn-heading-title p-relative title-block-lg d-block    heading-color">
                                    Our Clients
                                </h2>
                            </div>
                        </Fade>
                    </div>
                    <Fade bottom>
                        <div class="clients-images w-100">
                            {/* <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client1} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client2} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client3} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client4} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client5} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client6} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                        <img src={Client1} alt="" />
                    </div>
                </div>
                <div class="brand-item border-style">
                    <div class="brand-item-inner  over-hidden p-relative ">
                    <img src={Client1} alt="" />
                    </div>
                </div> */}
                            <img src={ClientBg} alt="" className="w-100" />

                        </div>
                    </Fade>
                </div>
            </div>
        </>
    );
};

export default OurClient;
