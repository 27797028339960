import React, { useState } from 'react'
import { Header } from './Header';
import Footer from './Footer';
import { PopupWidget, InlineWidget } from 'react-calendly';
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasFormSubmit } from '@testing-library/user-event/dist/utils';




const PlanLife = ({ formData, setForm, navigation }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(navigation);
        navigation.next();
      };
      const [isChecked, setIsChecked] = useState("");

      const handleRadioChange = (e) => {
        
        setIsChecked(e.target.value);
        console.log(e.target.value);
      };
      console.log(isChecked);
    return (
        <>
            <Header />
            <div className='main-user-forms'>
                <div className='container'>
                    <div className='row'>
                    <div class="step step-1">
                    <h4>
                        Grow your wealth & set the goals
                    </h4>
                    <p>
                        Let us determine your risk-taking ability
                    </p>
                    {/* Step 1 form fields here */}

<form onSubmit={handleSubmit}>

                    <div class="input__wrap controls">
                        <div class="d-grid grid-md-2" data-dsn-gap="0px 30px">
                            <div class="form-group dsn-up">

                                <div class="entry-box">

                                    <label for="">YOUR AGE *</label>
                                    <select name="" id="">
                                        <option disabled>Select Your Age</option>
                                        <option value="10">10 years</option>
                                        <option value="10">11 years</option>
                                        <option value="10">12 years</option>
                                        <option value="10">13 years</option>
                                        <option value="10">14 years</option>
                                    </select>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group dsn-up">
                                <div class="entry-box">

                                    <label for="">NO. OF DEPENDENTS * </label>
                                    <select name="" id="">
                                        <option disabled>Select no of People</option>
                                        <option value="10">2 People</option>
                                        <option value="10">3 People</option>
                                        <option value="10">4 People</option>

                                    </select>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>


                        <div class="form-group dsn-up">
                            <div class="grid-capacity">
                                <div class={` ${isChecked == `1` ? 'add-radio' : ''} return-card `}
                                >
                                    <div class="return-heads">
                                        <div class="return-radio">
                                            <h3>
                                                7 % Return
                                            </h3>
                                            <p>
                                                <input
          type="radio"
          checked={isChecked}
          onChange={handleRadioChange}
          value={1}
        />
                                                <i class="fa-solid fa-check">
                                                </i>
                                            </p>

                                        </div>
                                        <h5>
                                            Risk free
                                        </h5>
                                        <p>
                                            Return of 7 % with no <br />Risk
                                        </p>
                                    </div>

                                </div>

                                 <div class={` ${isChecked == `2` ? 'add-radio' : ''} return-card `}
                                >
                                    <div class="return-heads">
                                        <div class="return-radio">
                                            <h3>
                                                12 % Return
                                            </h3>
                                            <p>
                                                <input
          type="radio"
          checked={isChecked}
          onChange={handleRadioChange}
          value={2}
        />
                                                <i class="fa-solid fa-check">
                                                </i>
                                            </p>

                                        </div>
                                        <h5>
                                            7 % Risk
                                        </h5>
                                        <p>
                                            Return of 12%, with a <br /> chance of 7% Loss
                                        </p>
                                    </div>
                                </div>
                                 <div class={` ${isChecked ==`3` ? 'add-radio' : ''} return-card `}
                                >
                                    <div class="return-heads">
                                        <div class="return-radio">
                                            <h3>
                                                20 % Return
                                            </h3>
                                            <p>
                                                <input
          type="radio"
          checked={isChecked}
          onChange={handleRadioChange}
          value={3}
        />
                                                <i class="fa-solid fa-check">
                                                </i>
                                            </p>

                                        </div>
                                        <h5>
                                            14 % Risk
                                        </h5>
                                        <p>
                                            Return of 20%, with a <br /> chance of 14% Loss
                                        </p>
                                    </div>
                                </div>
                                 <div class={` ${isChecked ==`4` ? 'add-radio' : ''} return-card `}
                                >
                                    <div class="return-heads">
                                        <div class="return-radio">
                                            <h3>
                                                30 % Return
                                            </h3>
                                            <p>
                                                <input
          type="radio"
          checked={isChecked}
          onChange={handleRadioChange}
          value={4}
        />
                                                <i class="fa-solid fa-check">
                                                </i>
                                            </p>

                                        </div>
                                        <h5>
                                            21 % Risk
                                        </h5>
                                        <p>
                                            You are an <br /> adventurous person!
                                        </p>
                                    </div>
                                </div>
                                 <div class={` ${isChecked ==`5` ? 'add-radio' : ''} return-card `}
                                >
                                    <div class="return-heads">
                                        <div class="return-radio">
                                            <h3>
                                                40 % Return
                                            </h3>
                                            <p>
                                                <input
          type="radio"
          checked={isChecked}
          onChange={handleRadioChange}
          value={5}
          />
          <i class="fa-solid fa-check">
                                                </i>
                                            </p>

                                        </div>
                                        <h5>
                                            27 % Risk
                                        </h5>
                                        <p>
                                            Return of 40%, with a <br /> chance of 27% Loss
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex dsn-up">
                            <div class="step-btn steps-all-btn" data-dsn="parallax">
                                {/* <input type="submit" value="Send Message" /> */}
                               <input type="submit"  class="btn  next-step" value="Next Step"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-next-btn">

                    </div>
                    </form>

                </div>
                    </div>
                </div>
             
            </div>
            <Footer />

        </>
    )
}

export default PlanLife;