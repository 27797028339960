import React from "react";
import { Header } from "./Header";
import Footer from "./Footer";

export const Contactus = () => {
  return (
    <>
      <Header />
      <div id="page_wrapper" class="wrapper">
        {/* <!-- ========== Contact  ========== --> */}
        <div class="p-relative contact-form over-hidden section-padding">
          <div class="dsn-mask p-absolute w-70 left-0 top-0 h-100 background-section"></div>
          <div
            class="p-relative container d-grid grid-1-half"
            data-dsn-gap="30px 60px"
          >
            <div class="box-form">
              <h2 class="title-h2 ">
                IF YOU HAVE QUESTIONS PLEASE <br />
                CONTACT US
              </h2>

              <div class="dsn-form mt-20 form-box d-flex flex-column">
                <form
                  id="contact-form"
                  class="form w-100"
                  method="post"
                  action="#"
                  data-toggle="validator"
                >
                  <div class="messages"></div>
                  <div class="input__wrap controls">
                    <div class="d-grid grid-md-2" data-dsn-gap="0px 30px">
                      <div class="form-group dsn-up">
                        <div class="entry-box">
                          <input
                            id="form_name"
                            type="text"
                            name="name"
                            placeholder="Type your name"
                            required="required"
                            data-error="name is required."
                          />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
                      <div class="form-group dsn-up">
                        <div class="entry-box">
                          <input
                            id="form_email"
                            type="email"
                            name="email"
                            placeholder="Type your Email Address"
                            required="required"
                            data-error="Valid email is required."
                          />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="form-group dsn-up">
                      <div class="entry-box">
                        <textarea
                          id="form_message"
                          class="form-control"
                          name="message"
                          rows="7"
                          placeholder="Message here"
                          required="required"
                          data-error="Please,leave us a message."
                        ></textarea>
                      </div>
                      <div class="help-block with-errors"></div>
                    </div>

                    <div class="d-flex dsn-up">
                      <div
                        class="image-zoom move-circle border-color-default border-style border-rdu"
                        data-dsn="parallax"
                      >
                        <input type="submit" value="Send Message" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="box-info p-30 background-theme ">
              <div class="icon-left dsn-icon-heading-color d-flex align-items-center h-100">
                <div class="d-grid dsn-service icon-left align-items-center">
                  <div class="service-item p-relative grid-item style-box  background-transparent">
                    <div class="service-item-inner number-item h-100">
                      <div class="dsn-icon">
                        <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  sm-title-block ">
                          Head Office:
                        </h4>
                        <div class="service_description mt-10 max-w570 dsn-auto">
                          <p>address will be here</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="service-item p-relative grid-item style-box  background-transparent">
                    <div class="service-item-inner number-item h-100">
                      <div class="dsn-icon">
                        <i class="fas fa-phone-volume" aria-hidden="true"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  sm-title-block ">Phones:</h4>
                        <div class="service_description mt-10 max-w570 dsn-auto">
                          <p>+1 (850) 344 0 66</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="service-item p-relative grid-item style-box  background-transparent">
                    <div class="service-item-inner number-item h-100">
                      <div class="dsn-icon">
                        <i class="fas fa-envelope" aria-hidden="true"></i>
                      </div>
                      <div class="service-content p-relative">
                        <h4 class="service_title  sm-title-block ">
                          Write Us:
                        </h4>
                        <div class="service_description mt-10 max-w570 dsn-auto">
                          <p>info@zrotax.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ========== End Contact  ========== --> */}
        {/* <!-- ========== Map  ========== --> */}
        <div
          class="map-custom "
          data-dsn-lat="51.5073509"
          data-dsn-len="-0.1277583"
          data-dsn-zoom="14"
        ></div>
      </div>
      <Footer />
    </>
  );
};
