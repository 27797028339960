import React from "react";

import { Header } from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
export const CreateAccounts = () => {
    return (
        <>
            <Header />


            <div className="form-login-main">
                <div className="container">
                    <div className="row">
                        <form className="form-page-login plan-shadow-border">
                            <div className="header-login">
                                <h2>
                                   Please fill form to create Account
                                </h2>
                            </div>
                            <div className="input-login1">
                                <input type="text" placeholder="Enter Your Name"
                                    className="form-control"
                                />
                            </div>
                            <div className="input-login1">
                                <input type="text" placeholder="Username"
                                    className="form-control"
                                />
                            </div>
                            <div className="input-login1">
                                <input type="text" placeholder="Enter Your Password"
                                    className="form-control"
                                />
                            </div>

                            <div className="input-login1">
                                <input type="text" placeholder="Confirm  Password"
                                    className="form-control"
                                />
                            </div>
                        
                            <div className="input-login-button">
                                <a href=""
                                    className="form-control start-fil-login-btn"
                                >Registered Now</a>
                            </div>
                            <div className="create-accounts">
                            <NavLink to="/Login">Already account ? Please login </NavLink>
                            

                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
};
