import React from 'react'
import Footer from './Footer'
import Fade from "react-reveal/Fade";
import { Header } from './Header'
import { NavLink } from 'react-router-dom'

export const Plannow = () => {
    return (
        <>
            <Header />

            <header id="dsn_header" class="dsn-header-animation header-normal ">
                <div class="background-overlay bg-pattern p-absolute left-0 top-0 w-100 h-100"></div>

                <div class="entry-header p-relative over-hidden">

                    <div id="hero_content" class="d-flex p-relative h-100 dsn-hero-parallax-title container align-items-center pb-section ">
                        <div class="content p-relative ">
                            <div class="intro-project w-100">
                                <div class="intro-title ">
                                    <Fade bottom>
                                        <div id="hero_title" class="mt-20">
                                            <h1 class="title text-upper">Plans Comparison </h1>
                                        </div>
                                    </Fade>
                                    <Fade bottom>
                                        <p class="mt-20 max-w570">
                                            Annual Subscription Plan
                                        </p>
                                    </Fade>
                                    <div class="container">
                                        <div className="row justify-content-center plan-rows">
                                            <ul class="intro-project-list mt-30 d-grid grid-md-2 grid-sm-2 plan-shadow ">
                                                <Fade bottom>
                                                    <li class="dsn-up grid-item number-item plans-tag-heading">
                                                        <h5 class="title-list-project sm-title-block"><b>BASIC PLAN</b> </h5>
                                                        <ul className='inner-list'>
                                                            <li class="description-list-project dsn-auto">10* Saving Guaranteed on plan value</li>
                                                            <li class="description-list-project dsn-auto">2 FREE Tax Planning sessions with CA Over video call</li>
                                                            <li class="description-list-project dsn-auto">Salary Restructuring for Tax Optimization & Planning</li>
                                                            <li class="description-list-project dsn-auto"> Information on Tax Saving Products</li>
                                                            <li class="description-list-project dsn-auto">Advance Tax Management</li>
                                                            <li class="description-list-project dsn-auto"> NO Financial Planning and Goal Management</li>
                                                            <li class="description-list-project dsn-auto">NO Current Portfolio check & Diagnosis</li>

                                                            <li class="description-list-project dsn-auto"> FREE ITR Filling</li>
                                                        </ul>

                                                        <div class="dsn-def-btn dsn-icon-heading-color mt-30 d-flex">
                                                            <NavLink class="dsn-btn dsn-border border-color-default  background-section effect-ajax effect-ajax  move-circle has-icon-left " to="/BasicPlan" >


                                                                <span class="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                                                                    <i class="fas fa-angle-right"></i>
                                                                </span>
                                                                <span class="title-btn p-relative  z-index-1 heading-color">VIEW MORE</span>
                                                            </NavLink>
                                                        </div>
                                                        <div class="d-flex dsn-up justify-content-center">
                                                            <div
                                                                class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                                                                data-dsn="parallax"
                                                            >
                                                                <a href="">Buy Now</a>
                                                            </div>
                                                        </div>

                                                    </li>
                                                </Fade>

                                                <Fade bottom>
                                                    <li class="dsn-up grid-item number-item plans-tag-heading ">
                                                        <h5 class="title-list-project sm-title-block bg-gold"><b>PREMIUM PLAN</b></h5>
                                                        <ul className='inner-list'>
                                                            <li class="description-list-project dsn-auto">10* Saving Guaranteed on plan value</li>
                                                            <li class="description-list-project dsn-auto"> UNLIMITED Tax Planning sessions with CA Over video call</li>
                                                            <li class="description-list-project dsn-auto">Salary Restructuring for Tax Optimization & Planning</li>

                                                            <li class="description-list-project dsn-auto">Information on Tax Saving Products</li>
                                                            <li class="description-list-project dsn-auto">Advance Tax Management</li>
                                                            <li class="description-list-project dsn-auto">Financial Planning and Goal Management &nbsp; &nbsp;</li>
                                                            <li class="description-list-project dsn-auto"> Current Portfolio check & Diagnosis</li>
                                                            <li class="description-list-project dsn-auto"> FREE ITR Filling</li>

                                                        </ul>
                                                        <div class="dsn-def-btn dsn-icon-heading-color mt-30 d-flex">
                                                            <NavLink class="dsn-btn dsn-border border-color-default  background-section effect-ajax effect-ajax  move-circle has-icon-left " to="/PremiumPlan" >


                                                                <span class="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                                                                    <i class="fas fa-angle-right"></i>
                                                                </span>
                                                                <span class="title-btn p-relative  z-index-1 heading-color">VIEW MORE</span>
                                                            </NavLink>
                                                        </div>
                                                        <div class="d-flex dsn-up justify-content-center">
                                                            <div
                                                                class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                                                                data-dsn="parallax"
                                                            >
                                                                <a href="">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </Fade>
                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#page_wrapper" rel="nofollow" class="dsn-scroll-bottom" data-dsn-option='{"ease": "power4.inOut" , "duration" : 1.5}'>
                    <div class="text">SCROLL</div>
                </a>
                {/* <!-- ========== End Header Normal ========== --> */}
            </header>


            <Footer />
        </>
    )
}
