// import './App.css';
import React from "react";
import { Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import { Home } from "./Component/Home";
import { Business } from "./Component/Business";

import "./assets/css/style.css";

import "./assets/css/custom.css";
import "./assets/css/new-style.css";
import "slick-carousel/slick/slick.css";

// import "./assets/css/plugins/fontawesome-all.min.css"
// import "./assets/css/plugins/swiper.min.css"
// import "./assets/css/plugins/jquery.animatedheadline.css"
// import "./assets/css/plugins/fancybox.css"
// import "./assets/css/global.css"
// import "./assets/css/latest-style.css"
import "./assets/css/kstyle.css"

import "./assets/css/plugins/fontawesome-all.min.css";
import "./assets/css/plugins/swiper.min.css";
import "./assets/css/plugins/jquery.animatedheadline.css";
import "./assets/css/plugins/fancybox.css";
import "./assets/css/global.css";
import "./assets/css/latest-style.css";

import { Architecture } from "./Component/Architecture.js";
import { Plannow } from "./Component/Plannow.js";
import { BasicPlan } from "./Component/BasicPlan.js";
import { PremiumPlan } from "./Component/PremiumPlan.js";
import { AdvancePlan } from "./Component/AdvancePlan.js";
import { Service } from "./Component/Service.js";
import { Contactus } from "./Component/Contactus.js";
import { Userform } from "./Component/Userform.js";
import { Header } from "./Component/Header.js";
import { FileItr } from "./Component/FileItr.js";
import { Stepform } from "./Component/Stepform/Stepform.js";
import { BasicItrCapitalGains } from "./Component/BasicItrCapitalGains.js";
import { HighNetWorthIndividualItr } from "./Component/HighNetWorthIndividualItr.js";
import { SalaryFutureOptionItr } from "./Component/SalaryFutureOptionItr.js";
import { SalaryForeignIncome } from "./Component/SalaryForeignIncome.js";
import { ItrPlanning } from "./Component/ItrPlanning.js";
import { BasicItrPlan } from "./Component/BasicItrPlan.js";
import { Login } from "./Component/Login.js";
import { CreateAccounts } from "./Component/CreateAccounts.js";
import BookAppointment from "./Component/BookAppointment.js";
import AboutUs from "./Component/AboutUs.js";
import About from "./Component/About.js";
import TermCondition from "./Component/TermCondition.js";
import Privacy from "./Component/Privacy.js";
import PlanLife from "./Component/PlanLife.js";
import PlanAssets from "./Component/PlanAssets.js";
import PlanMonthlyIncome from "./Component/PlanMonthlyIncome.js";
import PlanGoals from "./Component/PlanGoals.js";
import { PlanYorLife } from "./Component/PlanYorLife.js";
import { BasicItrAbovePlan } from "./Component/BasicItrAbovePlan.js";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Business" element={<Business />} />
        <Route path="/Architecture" element={<Architecture />} />
        <Route path="/PlanNow" element={<Plannow />} />
        <Route path="/BasicPlan" element={<BasicPlan />} />
        <Route path="/PremiumPlan" element={<PremiumPlan />} />
        <Route path="/AdvancePlan" element={<AdvancePlan />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Contact-us" element={<Contactus />} />
        <Route path="/FileItr" element={<FileItr />} />
        <Route path="/stepform" element={<Stepform />} />
        <Route path="/BasicItrCapitalGains" element={<BasicItrCapitalGains />} />
        <Route path="/HighNetWorthIndividualItr" element={<HighNetWorthIndividualItr />} />
        <Route path="/SalaryFutureOptionItr" element={<SalaryFutureOptionItr />} />
        <Route path="/SalaryForeignIncome" element={<SalaryForeignIncome />} />
        <Route path="/ItrPlanning" element={<ItrPlanning />} />
        <Route path="/BasicItrPlan" element={<BasicItrPlan />} />
        <Route path="/About" element={<About />} />

        <Route path="/Login" element={<Login />} />
        <Route path="/CreateAccounts" element={<CreateAccounts />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/TermCondition" element={<TermCondition />} />
        <Route exact path="/BookAppointment" element={<BookAppointment />} />
        <Route path="/PlanLife" element={<PlanYorLife />} />
        <Route path="/PlanAssets" element={<PlanAssets />} />
        <Route path="/PlanMonthlyIncome" element={<PlanMonthlyIncome />} />
        <Route path="/BasicItrAbovePlan" element={<BasicItrAbovePlan />} />
        <Route path="/PlanGoals" element={<PlanGoals />} />

        
      </Routes>
    </>
  );
}

export default App;
