import React from "react";
import Slider from "react-slick";
import { Header } from "./Header";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import { Fade } from "react-reveal";
function SimpleNextArrow(props) {
  const { onClick } = props;

  return (
    <>
      <div class="dsn-swiper-paginate  d-flex  w-100 h-100 mt-30 align-items-center  justify-content-between slick-slider-next">
        <div
          class="swiper-pagination  mr-30 ml-30 heading-color"
          data-dsn-type="bullets"
        ></div>
        <div class="swiper-next" onClick={onClick}>
          <div class="next-container">
            <div class="container-inner">
              <div class="triangle"></div>
              <svg
                class="circle"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g
                  class="circle-wrap"
                  fill="none"
                  stroke-width="1"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle cx="12" cy="12" r="10.5"></circle>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SimplePrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div class="dsn-swiper-paginate  d-flex  w-100 h-100 mt-30 align-items-center container justify-content-between slick-slider-prev">
        <div class="swiper-prev">
          <div class="prev-container" onClick={onClick}>
            <div class="container-inner">
              <div class="triangle"></div>
              <svg
                class="circle"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <g
                  class="circle-wrap"
                  fill="none"
                  stroke-width="1"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle cx="12" cy="12" r="10.5"></circle>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export const FileItr = () => {
  const SliderItr = {
    infinite: true,
    // dots: true,
    slideToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    nextArrow: <SimpleNextArrow />,

    prevArrow: <SimplePrevArrow />,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 993,
        settings: {
          autoplay: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: false,
          slidesToShow: 1,

          arrows: false,
          dots: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: true,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <div class="parent-service-section">
        <div class="pt-section mb-section background-section service-section">
          <div class="container section-margin">
            <div className="row justify-content-center">
              <div class="section-title mb-70 text-center d-flex flex-column">
                <Fade bottom>
                  <h2 class="title-h2 text-upper">File ITR with us</h2>
                </Fade>
              </div>
              <div class="col-lg-4 col-xl-4 list-with-number icon-top dsn-icon-theme-color text-center">
                <div
                  class="dsn-service  dsn-masonry-grid slider-grid "
                  data-dsn-iconsize="80px"
                >  <Fade bottom>

                    <div class="dsn-up service-item p-relative grid-item slider-cards ">
                      <div class="service-item-inner itr-borders number-item h-100 background-section border-rdu bg-change-one plan-height">
                        <div class="dsn-icon">
                          <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service_title  title-block">Basic ITR </h4>
                          <h6>Total Income Upto 25 Lakhs</h6>
                          <div className="plan-main">
                            <div className="offers">
                              <p>25%</p>
                              <p>
                                <span> ₹ 1499
                                  <del>1999</del>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div class="d-flex dsn-up justify-content-center">
                            <div
                              class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                              data-dsn="parallax"
                            >
                              <NavLink to="/BasicItrPlan"> File Now</NavLink>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Fade>
                </div>
                <div class="text-calls">
                  <h3>Contact us today to schedule a consultation.</h3>
                  <p>
                    We look forward to helping you achieve your financial goals!
                  </p>
                  <div class="d-flex dsn-up justify-content-center">
                    <div
                      class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                      data-dsn="parallax"
                    >
                      <a href="">Book Appointment</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-xl-4 list-with-number icon-top dsn-icon-theme-color text-center">
                <div
                  class="dsn-service  dsn-masonry-grid slider-grid "
                  data-dsn-iconsize="80px"
                >  <Fade bottom>

                    <div class="dsn-up service-item p-relative grid-item slider-cards">
                      <div class="service-item-inner itr-borders number-item h-100 background-section border-rdu second-bg plan-height">
                        <div class="dsn-icon">
                          <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service_title  title-block">Basic ITR </h4>
                          <h6>Total Income Above 25 Lakhs</h6>
                          <div className="plan-main">
                            <div className="offers">
                              <p>25%</p>
                              <p>₹ 2499 
                                <span>
                                  <del>3999</del>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div class="d-flex dsn-up justify-content-center">
                            <div
                              class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                              data-dsn="parallax"
                            >
                              <NavLink to="/BasicItrAbovePlan"> File Now</NavLink>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Fade>
                </div>
                <div class="text-calls">
                  <h3>Contact us today to schedule a consultation.</h3>
                  <p>
                    We look forward to helping you achieve your financial goals!
                  </p>
                  <div class="d-flex dsn-up justify-content-center">
                    <div
                      class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                      data-dsn="parallax"
                    >
                      <a href="">Book Appointment</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
