import React from "react";
import { Fade } from "react-reveal";
//import { Header } from "./Header";
import Footer from "./Footer";
export const ItrPlanning = () => {
  return (
    <>
      {/*<Header />*/}
      <div class="container  section-margin mt-section">
        <div class="d-grid grid-lg-2 ">
          <div class="left-box-image mb-section">
            <Fade bottom>
              <div className="user-input-form">
                <h5>ITR Planning</h5>
                <div class="dsn-form mt-20 form-box d-flex flex-column">
                  <form
                    id="contact-form"
                    class="form w-100"
                    method="post"
                    action="#"
                  >
                    <div class="messages"></div>
                    <div class="input__wrap controls">
                      <div class="d-grid ">
                        <div class="form-group dsn-up">
                          <div class="entry-box">
                            <label htmlFor="">Plan</label>
                            <input
                              id="form_name"
                              type="text"
                              name="name"
                              placeholder="Plan Name here"
                              required="required"
                              data-error="name is required."
                            />
                          </div>
                          <div class="entry-box entry-coupon">
                            <label htmlFor="">Apply Coupon Code (if any)</label>
                            <div className="apply-coupon-box">
                              <input
                                id="form_name"
                                type="text"
                                name="name"
                                placeholder="Coupon Code"
                                required="required"
                                data-error="name is required."
                              />
                              <a href="">Submit</a>
                            </div>
                          </div>
                          <div class="help-block with-errors"></div>
                        </div>
                        <div className="apply-coupan">
                          <p>
                            <del>₹ 10000 </del> <span>₹4999 </span>{" "}
                            <span>50% off</span>
                          </p>
                        </div>
                      </div>

                      <div class="d-flex dsn-up justify-content-center">
                        <div
                          class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                          data-dsn="parallax"
                        >
                          <a href="">Login to Proceed</a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <div class="box-info background-section decs-padding  ">
              <div class="section-title d-flex">
                <div class="sub-section-title">
                  <span class="title-h2 dsn-heading-title p-relative title-block-lg d-block    heading-color">
                    <span>
                      <b>ITR Planning</b>
                    </span>
                  </span>
                </div>
              </div>
              {/*<p class="mt-20">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit laborum. Sed ut perspiciatis unde omnis
              iste natus sit .<span class="d-block mt-10"></span>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt
            </p>*/}
              <ul class="d-grid dsn-list mt-20">
                <li class="list-item d-flex align-items-center ">
                  <span class="dsn-icon mr-15">
                    <i class="fas fa-check" aria-hidden="true"></i>
                  </span>
                  <p class="dsn-heading-title heading-color ">
                    Complete Planning
                  </p>
                </li>
                <li class="list-item d-flex align-items-center ">
                  <span class="dsn-icon mr-15">
                    <i class="fas fa-check" aria-hidden="true"></i>
                  </span>
                  <p class="dsn-heading-title heading-color ">
                    Mitigation of Tax{" "}
                  </p>
                </li>
                <li class="list-item d-flex align-items-center ">
                  <span class="dsn-icon mr-15">
                    <i class="fas fa-check" aria-hidden="true"></i>
                  </span>
                  <p class="dsn-heading-title heading-color ">Save Taxes</p>
                </li>
                <li class="list-item d-flex align-items-center ">
                  <span class="dsn-icon mr-15">
                    <i class="fas fa-check" aria-hidden="true"></i>
                  </span>
                  <p class="dsn-heading-title heading-color ">
                    Assured 10X Refund of fees paid
                  </p>
                </li>
              </ul>
              {/*<div class="dsn-def-btn dsn-icon-heading-color mt-20 d-flex">
              <a
                class="dsn-btn dsn-border border-color-default  effect-ajax has-icon-left"
                href="project-6.html"
                data-dsn-text="HEADPHONES"
              >
                <span class="dsn-icon dsn-bg-before btn-icon-left heading-color z-index-1">
                  <i class="fas fa-angle-right"></i>
                </span>
                <span class="title-btn p-relative  z-index-1 ">LEARN MORE</span>
              </a>
            </div>*/}
            </div>
          </Fade>
        </div>
      </div>
      <Footer />
    </>
  );
};
