import React from 'react'
import Fade from 'react-reveal/Fade';
import { Header } from './Header';
import Footer from './Footer';
import { PopupWidget ,InlineWidget} from 'react-calendly';
import teamImg1 from "../assets/img/team/1.jpg";
import teamImg2 from "../assets/img/team/2.jpg";




const BookAppointment = () => {
    return (
        <>
            <Header />
            <div class="about-section about-arc section-margin">
                <div class="container ">
                    <div className="popup-widget">
                        <div className="App">
                            <InlineWidget url="https://calendly.com/shaikhhamid039/we-give-you-vision" />
                        </div>
                    </div>

                </div>


            </div>
            <Footer />

        </>
    )
}

export default BookAppointment;