import React from "react";
//import { Header } from "./Header";
import Footer from "./Footer";
import { Fade } from "react-reveal";
export const BasicItrAbovePlan = () => {
    return (
        <>
            {/*
    <Header />*/}
            <div class="container  section-margin mt-section">
                <div class="d-grid grid-lg-2 ">

                    <Fade bottom>
                        <div class="box-info background-section decs-padding  ">
                            <div class="section-title d-flex">
                                <div class="sub-section-title">
                                    <span class="title-h2 dsn-heading-title p-relative title-block-lg d-block    heading-color">
                                        <span>
                                            <b>Basic ITR Plan</b>
                                        </span>
                                    </span>
                                </div>

                            </div>
                            <div className="itr-lists">
                                <label class="container-list">ESOPs/Foreign Share (Foreign Asset Reporting) - 499
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>

                                <label class="container-list">Capital Gain (Sale of Shares/Land/Other Asset) - 499
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>

                                <label class="container-list">Crypto Transactions - 499
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>

                                <label class="container-list">Future and Options - 499
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>

                                <label class="container-list">Free Lancing Income -499
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </Fade>
                    <div class="left-box-image mb-section">
                        <Fade bottom>
                            <div className="user-input-form">
                                <h5>Basic ITR Plan</h5>
                                <div class="dsn-form mt-20 form-box d-flex flex-column">
                                    <form
                                        id="contact-form"
                                        class="form w-100"
                                        method="post"
                                        action="#"
                                    >
                                        <div class="messages"></div>
                                        <div class="input__wrap controls">
                                            <div class="d-grid ">
                                                <div class="form-group dsn-up">
                                                    <div class="entry-box">
                                                        <label htmlFor="">Plan</label>
                                                        <input
                                                            id="form_name"
                                                            type="text"
                                                            name="name"
                                                            placeholder="Plan Name here"
                                                            required="required"
                                                            data-error="name is required."
                                                        />
                                                    </div>
                                                    <div class="entry-box entry-coupon">
                                                        <label htmlFor="">Apply Coupon Code (if any)</label>
                                                        <div className="apply-coupon-box">
                                                            <input
                                                                id="form_name"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Coupon Code"
                                                                required="required"
                                                                data-error="name is required."
                                                            />
                                                            <a href="">Submit</a>
                                                        </div>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                                <div className="apply-coupan">
                                                    <p>
                                                        <del>₹ 3999 </del> <span>₹ 2499 </span>{" "}
                                                        <span>50% off</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-flex dsn-up justify-content-center">
                                                <div
                                                    class="image-zoom move-circle border-color-default border-style border-rdu bookappointment"
                                                    data-dsn="parallax"
                                                >
                                                    <a href="">Login to Proceed</a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
