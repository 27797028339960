import React from "react";
import { Header } from "../Header";
import Footer from "../Footer";

export const Page3 = ({ formData, setForm, navigation }) => {
  const { address } = formData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    // navigation.next();
  };
  return (
    <>
      <Header />
      <div className="form-steppers mb-5">
        <form
          onSubmit={handleSubmit}
          style={{ width: "30%" }}

          encType="multipart/form-data"
          autoComplete="new-password"
          className="form-floating form-auto3 mb-3"
        >
          <div className="form-floating"></div>

          <div className="form-upload">
            <h2>UPLOAD YOUR FORM-16 PDF</h2>
            <p>Choose your Form-16 PDF to upload</p>
            <p>You can upload a password protected PDF too. Please choose one file at a time.</p>
      
          </div>
          <div className="form-floating mb-3">
            <input
              name="address"
              value={address}
              onChange={setForm}
              type="file"
              required
              className="form-control address-file"
              placeholder="  Drop pdf file here or click to select"
              autoComplete="off"
            />
            <label htmlFor="floatingInput">Name</label>
          </div>
          <input type="submit" value="submit"
            className="form-control start-fil-butn"
          />
        </form>
      </div>
      <Footer />
    </>
  );
};
