import React from "react";
import { Header } from "./Header";
import Footer from "./Footer";
import { PopupWidget, InlineWidget } from "react-calendly";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const PlanGoals =  ({ formData, setForm, navigation }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        alert("submited")

      };
    const handlereset = async (e) => {
        e.preventDefault();
        console.log(navigation);
        navigation.previous();
      };
  return (
    <>
      <Header />
      <div className="main-user-forms">
        <div className="container">
          <div className="row">
            <form  onSubmit={handleSubmit} onReset={handlereset}>
            <div class="step step-4">
              {/* <!-- Step 3 form fields here --> */}
              <h3>Step 4</h3>
              <div class="assets-form">
                <div class="assets-heading">
                  <h5>Select Goals</h5>
                  <p>
                    Your spendings help to determine, how much of Income you
                    save!{" "}
                  </p>
                </div>
                <div class="assets-grid-3">
                  <div class="assets-card ">
                    <div class="goalbox-content">
                      <p>Target Year</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>Present Cost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>FutureCost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="assets-card ">
                    <div class="goalbox-content">
                      <p>Target Year</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>Present Cost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>FutureCost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="assets-card ">
                    <div class="goalbox-content">
                      <p>Target Year</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>Present Cost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>FutureCost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="assets-form">
                <div class="assets-grid-3">
                  <div class="assets-card ">
                    <div class="goalbox-content">
                      <p>Target Year</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>Present Cost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>FutureCost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="assets-card ">
                    <div class="goalbox-content">
                      <p>Target Year</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>Present Cost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>FutureCost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="assets-card ">
                    <div class="goalbox-content">
                      <p>Target Year</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>Present Cost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="goalbox-content">
                      <p>FutureCost</p>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex dsn-up assets-form ">
                <div class="step-btn steps-all-btn" data-dsn="parallax">
                  <input type="reset" class="btn  prev-step" value="Previous" />

                  <input type="submit" value="next" class="btn  next-step" />
                </div>
              </div>
              {/* <!--<div class="mb-3">
                        <label for="field3" class="form-label">Field 3:</label>
                        <input type="text" class="form-control" id="field3" name="field3">
                    </div>-->
                    <!--<button type="button" class="btn  prev-step">Previous</button>
                    <button type="submit" class="btn btn-success">Submit</button>--> */}
            </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- The Modal --> */}
      <div class="modal register-inform-modal" id="register-inform-modal">
        <div class="modal-dialog">
          <div class="modal-content">
            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <h4 class="modal-title">Modal Heading</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <p>
                In order to access this functionality, you should be signed in
                to Finbingo. Click Sign in / Register to proceed.
              </p>
            </div>
            <div class="register-btns">
              <a href="">SIGN IN / REGISTER</a>
            </div>
            {/* <!-- Modal footer --> */}
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PlanGoals;
