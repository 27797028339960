import React from 'react'
import Fade from 'react-reveal/Fade';
import { Header } from './Header';
import Footer from './Footer';
import teamImg1 from "../assets/img/team/1.jpg";
import teamImg2 from "../assets/img/team/2.jpg";




const AboutUs = () => {
    return (
        <>
            <Header />
            <div class="about-section about-arc section-margin">
                <div class="container ">

                    <div class="box-left box-padding">
                        <Fade top>
                            <h2 class="title-h2 p-relative body-font border-section-bottom heading-color text-upper">
                                About us: -
                            </h2>
                        </Fade>

                        <Fade top>
                            <p class="max-w570 mt-20">
                                Zrotax is an online platform that facilitates the process of submitting tax returns electronically. These websites streamline the tax filing process by providing a user-friendly interface, guiding users through the necessary steps, and offering built-in calculators to ensure accurate calculations. Users can securely input their financial information, deductions, and credits, and generate the required tax forms. Additionally, these websites often provide instant error checking and offer assistance in resolving any discrepancies. By eliminating the need for paper forms and manual submissions, return filing websites save time and effort for taxpayers, while ensuring compliance with tax regulations..
                            </p>
                        </Fade>



                    </div>

                    <div class="root-blog container section-margin  dsn-blog margin-decrease-margin">
                        <Fade top>
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">Our vision: -    </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">
                                                To provide maximum tax benefits unknown to the client we strive to provide comprehensive and seamless solution. We provide curated services were filing return and paying taxes is no longer a burden but a simple and easy process.
                                            </span>
                                        </div>
                                    </div>


                                </div>

                            </article>
                        </Fade>
                        <Fade top>
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">Our mission: -     </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">
                                                Our mission is to simplify the process of managing and paying taxes for individuals in India we provide an easy platform where you upload document, we guide you and insure complete end to end services
                                            </span>
                                        </div>
                                    </div>


                                </div>

                            </article>
                        </Fade>
                        <Fade top>
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">Why US  </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">
                                                <ul>
                                                    <li>We value your data.</li>
                                                    <li>your privacy is priority.</li>
                                                    <li>Trust us same more

                                                    </li>
                                                    <li>Zero compliance risk.

                                                    </li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>


                                </div>

                            </article>
                        </Fade>
                        <Fade top>
                            <p className='mt-5 summart-text'>
                                <b>
                                    Save your hard earn money book a free tax session with us.
                                </b>
                                <br />
                                Save your hard earn money book a free tax session with us.
                            </p>
                        </Fade>

                    </div>


                </div>
                <Fade top>
                    <div className="container">
                        <div class="dsn-container  over-hidden background-section section-padding">
                            <div class="section-title d-flex  mb-70">
                                <div class="sub-section-title">

                                    <h2 class="title-h2 dsn-heading-title p-relative title-block-lg d-block    heading-color">
                                        Our Team
                                    </h2>
                                </div>
                            </div>
                            <div class="d-grid grid-md-3 grid-sm-2 dsn-team">
                                <div class="team-item p-relative grid-item box-hover-image">
                                    <div class="team-item-inner  background-main">
                                        <div class="avatar box-img" data-overlay="4">
                                            <img class="cover-bg-img" src={teamImg1} alt='' />
                                        </div>
                                        <div class="team-content border-before text-center">
                                            <h4 class="text-name sm-title-block">Ahmed Shawky</h4>
                                            <span class="text-position"> Web Designer</span>
                                            <ul class="team-socials dsn-socials box-social">
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-facebook-f"></i>
                                                        <span>Facebook</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-instagram"></i>
                                                        <span>Instagram</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-linkedin-in"></i>
                                                        <span>LinkedIn</span>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                                <div class="team-item p-relative grid-item box-hover-image">
                                    <div class="team-item-inner  background-main">
                                        <div class="avatar box-img" data-overlay="4">
                                            <img class="cover-bg-img" src={teamImg2} alt='' />
                                        </div>
                                        <div class="team-content border-before text-center">
                                            <h4 class="text-name sm-title-block">Hisham Megahed</h4>
                                            <span class="text-position">Founder</span>
                                            <ul class="team-socials dsn-socials box-social">
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-facebook-f"></i>
                                                        <span>Facebook</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-instagram"></i>
                                                        <span>Instagram</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-linkedin-in"></i>
                                                        <span>LinkedIn</span>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                                <div class="team-item p-relative grid-item box-hover-image">
                                    <div class="team-item-inner  background-main">
                                        <div class="avatar box-img" data-overlay="4">
                                            <img class="cover-bg-img" src={teamImg2} alt='' />
                                        </div>
                                        <div class="team-content border-before text-center">
                                            <h4 class="text-name sm-title-block">Blake Hamilton</h4>
                                            <span class="text-position">Engineer</span>
                                            <ul class="team-socials dsn-socials box-social">
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-facebook-f"></i>
                                                        <span>Facebook</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-instagram"></i>
                                                        <span>Instagram</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#0" target="_blank" rel="nofollow">
                                                        <i class="fab fa-linkedin-in"></i>
                                                        <span>LinkedIn</span>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Fade>


            </div>
            <Footer />

        </>
    )
}

export default AboutUs;