import React from "react";
import { Header } from "../Header";
import Footer from "../Footer";

export const Page2 = ({ formData, setForm, navigation }) => {
  const { dob, gender,otp } = formData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigation.next();
  };
  return (
    <>
      <Header />
      <div className="form-steppers mb-5">
        <form
          onSubmit={handleSubmit}
          // style={{ width: "30%" }}

          encType="multipart/form-data"
          autoComplete="new-password"
          className="form-floating  form-auto2 mb-5"
        >
          <div className="form-floating"></div>
          <div className="verify-your-detail">
            <h2>Verify your details (Mandatory step by IT Department)</h2>
          </div>
          <div className="form-inner fom-btn main-grid-form">
            <div className="form-floating mb-5">
              <input
                onChange={setForm}
                name="dob"
                value={dob}
                required
                type="date"
                className="form-control"
                placeholder="IT Department registered mobile number "
                autoComplete="new-password"
              />
              <label htmlFor="floatingInput">Date of Birth</label>
            </div>
            <div className="form-floating">
              <select
                name="gender"
                onChange={setForm}
                className="form-select"
                aria-label="Floating label select example"
                value={gender}
                autoComplete="new-password"
              >
                <option>select gender</option>
                <option>Male</option>
                <option>Female</option>
              </select>
              <label htmlFor="floatingSelect">Gender (Optional)</label>

            </div>
          </div>
          <div className="verify-your-detail">
            <h2>Choose a method for verifying OTP and we will prepare income tax returns in minutes</h2>
          </div>
          <div className="form-inner fom-btn  main-grid-form">
            <div className="form-floating mb-5">
              <input
                onChange={setForm}
                name="otp"
                value={otp}
                // required
                type="number"
                className="form-control"
                placeholder="IT Department registered mobile number                "
                autoComplete="new-password"
              />
              <label htmlFor="floatingInput">Date of Birth</label>
            </div>
            <div className="form-floating">
              <input
                onChange={setForm}
                // name="dob"
                // value={dob}
                required
                type="text"
                className="form-control"
                placeholder="Aadhaar registered mobile number"
                autoComplete="new-password"
              />
              <label htmlFor="floatingSelect">Gender (Optional)</label>

            </div>
          </div>

          <div className="form-inner fom-btn  main-grid-form">

            <div className="form-floating">

              <input type="submit" value="Skip & Proceed"
                className="form-control start-fil-butn2"
              />
            </div>


            <div className="form-floating">

              <input type="submit" value="Get OTP"
                className="form-control start-fil-butn2"
              />
            </div>

          </div>

        </form>
      </div>
      <Footer />
    </>
  );
};
