import React from "react";

import Fade from "react-reveal/Fade";
import logoblack from "../assets/img/3.png";
import whiteblack from "../assets/img/2.png";
import bg from "../assets/img/bg.jpg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer
        id="dsn_footer"
        class="dsn-footer p-relative footer-animate svg-animate background-main"
      >
        <svg
          class="dsn-separator dsn-separator-up  dsn-icon-assistant-color"
          width="100%"
          height="100%"
          viewBox="0 0 100 10"
          preserveAspectRatio="none"
        >
          <path
            class="path-anim separator__path"
            data-dsn-to="M 0 0 C 40 0 55 0 100 0 L 0 0 Z"
            vector-effect="non-scaling-stroke"
            d="M 0 0 C 40 16 75 10 100 0 L 0 0 Z"
          ></path>
        </svg>
        <div class="footer-content p-relative mt-section">
          <div class="text-center container footer-connect-headings">
            <Fade bottom>
              <h2 class="title-h2 ">
                Stay Connected with
                <span class="d-block">
                  <b>
                    <i> ZroTax </i>
                  </b>
                </span>
              </h2>
              <p>
                Follow us on social media for daily nuggets of financial wisdom, subscribe for exclusive insights, and explore our resources for a deeper understanding of the financial world.

              </p>
            </Fade>
          </div>
          <div class="dsn-container pt-60">
            <div class="d-grid grid-lg-4 grid-md-2 ">
              <Fade bottom>
                <div class="grid-col-1">
                  <div class="dsn-logo main-logo">
                    <div class="main-logo">
                      <a
                        href="#"
                        data-dsn-text="zrotax"
                        class="custom-logo-link main-brand effect-ajax"
                        rel="home"
                      >
                        <img
                          class="custom-logo logo-light"
                          src={logoblack}
                          alt="zrotax"
                        />
                        <img
                          class="custom-logo logo-dark"
                          src={whiteblack}
                          alt="zrotax"
                        />
                      </a>
                    </div>
                  </div>
                  <ul class="dsn-socials box-social mt-20 dsn-icon-heading-color">
                    <li>
                      <a
                        href="#0"
                        target="_blank"
                        rel="nofollow"
                        class="init-color move-circle border-color-default"
                        data-dsn="parallax"
                      >
                        <span class="dsn-icon">
                          <i class="fab fa-facebook-f"></i>
                        </span>
                        <span>FB</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#0"
                        target="_blank"
                        rel="nofollow"
                        class="init-color move-circle border-color-default"
                        data-dsn="parallax"
                      >
                        <span class="dsn-icon">
                          <i class="fab fa-twitter"></i>
                        </span>
                        <span>TW</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/search/results/all/?keywords=zrotax&origin=GLOBAL_SEARCH_HEADER&sid=oR6"
                        target="_blank"
                        rel="nofollow"
                        class="init-color move-circle border-color-default"
                        data-dsn="parallax"
                      >
                        <span class="dsn-icon">
                          <i class="fab fa-linkedin-in"></i>
                        </span>
                        <span>LN</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#0"
                        target="_blank"
                        rel="nofollow"
                        class="init-color move-circle border-color-default"
                        data-dsn="parallax"
                      >
                        <span class="dsn-icon">
                          <i class="fab fa-instagram"></i>
                        </span>
                        <span>IN</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Fade>
              <div class="grid-col-2">
                <h4 class="subtitle p-relative line-shape  line-shape-after ">
                  <span class="background-revere ">NAVIGATION</span>
                </h4>
                <Fade bottom>
                  <div class="menu-footer-menu-container">
                    <ul id="menu-footer-menu" class="menu">
                      <li class="menu-item">
                        <a href="index.php" class="effect-ajax">
                          HOME
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="index.php" class="effect-ajax">
                          Services
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="index.php" class="effect-ajax">
                          About Us
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="plan-now.php" class="effect-ajax">
                          Plan Now
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="#" class="effect-ajax">
                          Login
                        </a>
                      </li>
                      <li class="menu-item">
                        <NavLink to='/TermCondition'>
                          Term & Condition
                        </NavLink>
                      </li>
                      <li class="menu-item">
                        <NavLink to='/Privacy'>
                          Privacy Policy
                        </NavLink>

                      </li>
                    </ul>
                  </div>
                </Fade>
              </div>
              <div class="grid-col-3">
                <Fade bottom>
                  <h4 class="subtitle p-relative line-shape  line-shape-after ">
                    <span class="background-revere ">CONTACT</span>
                  </h4>

                  <div class="col-contact">
                    <p>
                      <strong>T</strong> : +001 225 3351
                    </p>
                    <p class="mt-10">
                      <strong>F</strong> :+001 225 3351
                    </p>
                    <p class="over-hidden mt-10">
                      <strong>E</strong> :{" "}
                      <a
                        class="link-hover"
                        href="#"
                        data-hover-text="info@dsngrid.com"
                      >
                        info@zrotax.com
                      </a>
                    </p>
                  </div>
                </Fade>
              </div>
              <div class="grid-col-4">
                <h4 class="subtitle p-relative line-shape  line-shape-after ">
                  <span class="background-revere ">ADDRESS</span>
                </h4>
                <div class="col-address">
                  <Fade bottom>
                    <p>
                      Lorem ipsum dolor sit amet consectetur ipsam vitae
                      doloribus odio beatae dicta culpa architecto vel deserunt
                      natus.
                      <br /> 1776 Damietta
                      <br />
                      adipisicing elit. Earum esse dicta sequi ea
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
            <div class="footer-bottom mt-60 pt-30 border-top pb-30 text-center">
              <Fade bottom>
                <p class="over-hidden">
                  © 2022 ZroTax Designed by
                  <a
                    href="#0"
                    class="link-hover"
                    data-hover-text="DSN Grid"
                    target="_blank"
                  >
                    Growth Metaverse
                  </a>
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ========== Eligiblity icon ========== --> */}
      <div class="eligible">
        <NavLink to="/PlanLife" className="eligi-button" >
          <i class="fa fa-hand-o-left"></i>
          Plan your Life</NavLink>

      </div>
    </>
  );
};

export default Footer;
