import React from "react";
import { NavLink } from "react-router-dom";
import logoblack from "../assets/img/3.png";
import whiteblack from "../assets/img/2.png";
import { Link } from "react-router-dom";

import { useState } from "react";

export const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsActive((current) => !current);
  };

  return (
    <>
      <header
        id="site_menu_header"
        class="site-header  dsn-container  dsn-hamburger desktop-menu-header"
      >
        <div class="main-logo">
          <Link
            to="/"
            data-dsn-text="zrotax"
            class="custom-logo-link main-brand effect-ajax"
            rel="home"
            aria-current="page"
          >
            <img src={logoblack} class="custom-logo logo-light" alt="zrotax" />
            <img
              src={whiteblack}
              class="custom-logo logo-dark"
              alt="zrotax"
            />
          </Link>
        </div>
        <div id="navbar_toggle" class="navbar-toggle mobile d-sm-none"  onClick={handleClick}>
          <div class="toggle-icon">
            <div class="toggle-line"></div>
            <div class="toggle-line"></div>
            <div class="toggle-line"></div>
          </div>
       
        </div>
        {/* <button
          onClick={handleClick}
          class="navbar-toggler "
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="icon-bar">
            <i class="fa fa-bars"></i>
          </span>
        </button> */}
        <nav
          className={
            isActive ? "openMobilMenu main-navigation-menu phone" : " d-flex main-navigation-menu phone"
          }
        >
          <div className="menu">
            <ul>
              <li>
                <NavLink to="/" onClick={handleClick}> Home</NavLink>
              </li>
              <li>
                <NavLink to="/Service" onClick={handleClick}> Services</NavLink>
              </li>
              <li>
                <NavLink to="/BookAppointment" onClick={handleClick}> Book Appointment</NavLink>
              </li>

              <li>
                <NavLink to="/PlanNow" onClick={handleClick}> Annual Subscription</NavLink>
              </li>
              <li>
                <NavLink to="/FileItr" onClick={handleClick}> File ITR</NavLink>
              </li>
              <li>
                <NavLink to="/PlanLife" onClick={handleClick}> Plan your Life</NavLink>
              </li>
              <li>
                <NavLink to="/AboutUs" onClick={handleClick}> About us</NavLink>
              </li>
              <li>
                <NavLink to="/login" onClick={handleClick}> Login</NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div class="social-side d-flex align-items-center flex-column justify-content-center">
        <ul class="socials d-flex flex-column-reverse">
          <li>
            <a href="#0" target="_blank" rel="nofollow">
              <i class="fab fa-facebook-f" aria-hidden="true"></i>{" "}
              <span>Fb</span>
            </a>
          </li>
          <li>
            <a href="#0" target="_blank" rel="nofollow">
              <i class="fab fa-instagram" aria-hidden="true"></i>
              <span>Instagram</span>
            </a>
          </li>
          <li>
            <a href="#0" target="_blank" rel="nofollow">
              <i class="fab fa-twitter" aria-hidden="true"></i>
              <span>Tw</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/search/results/all/?keywords=zrotax&amp;origin=GLOBAL_SEARCH_HEADER&amp;sid=oR6"
              target="_blank"
              rel="nofollow"
            >
              <i class="fab fa-linkedin-in" aria-hidden="true"></i>
              <span>Linkedin</span>
            </a>
          </li>
        </ul>
        <div class="icon">FOLLOW US</div>
      </div>
    </>
  );
};
