import React, { useState } from 'react'
import { useForm, useStep } from "react-hooks-helper";
import { Header } from '../Header.js';
import Footer from '../Footer.js';
import { Page1 } from "./Page1.jsx";
import { Page2 } from "./Page2.jsx";
import { Page3 } from "./Page3.jsx";

export const Stepform = () => {
  const [defaultData, setDefaultData] = useState({
    username: "",
    email: "",
    dob: "",
    password: "",
    address: "",
    otp: ""
  })
  const steps = [
    { id: "Page1" },
    { id: "Page2" },
    { id: "Page3" },

  ];
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });
  const props = { formData, setForm, navigation };


  switch (step.id) {
    case "Page1":
      return <Page1 {...props} />;
    case "Page2":
      return <Page2 {...props} />;
    case "Page3":
      return <Page3 {...props} />;

  }
  return (
    <>
    
      <div>Stepform</div>
    
    </>
  )
}
