import React from 'react'
import { Header } from './Header';
import Footer from './Footer';
import { PopupWidget, InlineWidget } from 'react-calendly';
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";





const PlanAssets = ({ formData, setForm, navigation }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(navigation);
        navigation.next();
      };
    const handlereset = async (e) => {
        e.preventDefault();
        console.log(navigation);
        navigation.previous();
      };
    return (
        <>
            <Header />
            <div className='main-user-forms'>
                <div className='container'>
                    <div className='row'>
                        <div class="step step-2">
                            <h3>Step 2</h3>
                                <form onSubmit={handleSubmit} onReset={handlereset} >
                            <div class="assets-form">
                                <div class="assets-heading">
                                    <h5>
                                        Assets
                                    </h5>
                                    <p>
                                        Your Investments & its value as on today, shall help to determine correct Asset Allocation !
                                    </p>
                                </div>
                                <div class="assets-grid">
                                    <div class="assets-card">
                                        <h2>
                                            Cash (₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            Fixed Deposit (₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            Mutual Fund
                                            Equity (₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            Mutual Fund
                                            Debt (₹)

                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            Gold (₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            National Pension
                                            Scheme (₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />

                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            EPF/PPF (₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />

                                        </div>
                                    </div>
                                    <div class="assets-card">
                                        <h2>
                                            ULIP Sum Assured(₹)
                                        </h2>
                                        <div class="assets-input">
                                            <input type="text" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex dsn-up">
                                <div class="step-btn steps-all-btn" data-dsn="parallax">
                                 <input type="reset" class="btn  prev-step"  value='Previous'/> 
                                     <input type="submit" value="next" class="btn  next-step"/>

                                </div>
                            </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
}

export default PlanAssets;