import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import { Createaccounts } from "./CreateAccounts";
import Footer from "./Footer";
export const Login = () => {
    return (
        <>
            <Header />

            <div className="form-login-main">
                <div className="container">
                    <div className="row">
                        <form className="form-page-login plan-shadow-border">
                            <div className="header-login">
                                <h2>
                                    USER LOGIN
                                </h2>
                            </div>
                            <div className="input-login1">
                                <input type="text" placeholder="Username"
                                    className="form-control"
                                />
                            </div>
                            <div className="input-login2">
                                <input type="password" placeholder="*****"
                                    className="form-control"
                                />
                                <a href="">Forget Password?</a>
                            </div>
                            <div className="input-login-button">
                                <input type="submit" value="Login"
                                    className="form-control start-fil-login-btn"
                                />
                            </div>
                            <div className="create-accounts">
                            <NavLink to="/Createaccounts">if you Don't  have account ? Create Account</NavLink>
                            

                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <Footer />
        </>
    );
};
