import React from "react";
import Fade from "react-reveal/Fade";

import ourpassion from "../assets/img/whychoose.jpg";

const WhyChooseUs = () => {
  return (
    <>
      <div class="about-section about-arc section-margin">
        <div class="container section-margin d-grid grid-md-2" data-dsn-gap="30px 120px">
          <div class="box-left p-relative  pinned-scroll" data-dsn-option='{"end":"250"}'>
            <Fade bottom>
              <div class="section-title  pinned-element">
                <h2 class="title-h2 text-upper">Why Choose Us?</h2>
                <div className="whyimg">
                  <img src={ourpassion} alt="" />
                </div>
              </div>
            </Fade>
          </div>
          <div class="box-right">
            <div class="dsn-accordion" role="tablist">
              <div class="accordion__wrapper">
                <Fade bottom>
                  <div class="accordion__item  active">
                    <div class="accordion__question user-no-selection expanded d-flex align-items-center  ">
                      <span class="number background-theme h6">1</span>
                      <h6 class="sm-title-block tab-title heading-color" role="tab" data-tab="0">
                        Expert Guidance</h6>
                    </div>
                    <div class="accordion__answer tab-description active " role="tabpanel" data-tab="0">
                      <p>
                        Our team brings a wealth of experience to the table, providing you with expert guidance and insights.
                      </p>
                    </div>
                  </div>

                </Fade>
                <Fade bottom>
                  <div class="accordion__item  ">
                    <div class="accordion__question user-no-selection  d-flex align-items-center  ">
                      <span class="number background-theme h6">2</span>
                      <h6 class="sm-title-block tab-title heading-color" role="tab" data-tab="1">Tailored Solutions</h6>
                    </div>
                    <div class="accordion__answer tab-description active " role="tabpanel" data-tab="1">
                      <p>We recognize that every financial journey is unique. Our solutions are personalized to align with your goals and aspirations.</p></div>
                  </div>
                </Fade>
                <Fade bottom>
                  <div class="accordion__item  ">
                    <div class="accordion__question user-no-selection  d-flex align-items-center  ">
                      <span class="number background-theme h6">3</span>
                      <h6 class="sm-title-block tab-title heading-color" role="tab" data-tab="2">Transparency</h6>
                    </div>
                    <div class="accordion__answer tab-description  active " role="tabpanel" data-tab="2">
                      <p>We believe in transparent communication to build trust and confidence.</p></div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
