import React from 'react'
import { Header } from './Header'
import Footer from './Footer'

export const BasicPlan = () => {
    return (
        <>
            <Header />
            <header id="dsn_header" class="dsn-header-animation header-normal">
                <div class="background-overlay bg-pattern p-absolute left-0 top-0 w-100 h-100"></div>

                <div class="entry-header p-relative over-hidden">

                    <div id="hero_content" class="d-flex p-relative h-100 dsn-hero-parallax-title container plan-main-card pb-section pb-0 ">
                        <div class="content p-relative ">
                            <div class="intro-project w-100">
                                <div class="intro-title ">
                                    {/* <p class="dsn-heading-title d-inline-block circle-before">BASIC PLAN </p> */}
                                    <div id="hero_title" class="mt-10">
                                        <h1 class="text-left">Basic Plan:
                                            <br />

                                        </h1>
                                        <h5>
                                            Price <del>5000</del> – Dis. Price 4249 Rs
                                        </h5>
                                    </div>  
                                    <p class="mt-20 max-w570">
                                        <h4 className='mt-4'>
                                            Key Features
                                        </h4>
                                        <div class="list-style1">
                                            <ul>
                                                <li> <i class="far fa-circle" aria-hidden="true"></i>Basic Plan is for the person who wants to plan his taxes.</li>
                                                <li> <i class="far fa-circle" aria-hidden="true"></i>Ideal for salaried person having salary upto 20 Lakhs.</li>
                                                <li> <i class="far fa-circle" aria-hidden="true"></i>2 Tax Planning session with CA</li>
                                                <li> <i class="far fa-circle" aria-hidden="true"></i>Salary restructuring for salary optimization.</li>
                                                <li> <i class="far fa-circle" aria-hidden="true"></i>Free ITR Filing</li>
                                            </ul>
                                        </div>
                                    </p>

                                </div>


                            </div>
                        </div>
                        <div class="root-blog container section-margin  dsn-blog margin-decrease-margin">
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">PURPOSE AND SCOPE       </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">The Basic Plan is designed for individuals who primarily wish to manage and optimize their taxes effectively. It caters to salaried individuals with an annual income of up to 20 Lakhs. </span>
                                        </div>
                                    </div>


                                </div>

                            </article>
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">TAX PLANNING SESSIONS WITH CA      </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">
                                                With the Basic Plan, you get the valuable opportunity to have two tax planning sessions with a Chartered Accountant (CA). These sessions are crucial for understanding your financial situation, assessing potential deductions, and planning your taxes efficiently.                                        </span>
                                        </div>
                                    </div>


                                </div>

                            </article>
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">SALARY RESTRUCTURING FOR SALARY OPTIMIZATION     </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">
                                                One of the key features of the Basic Plan is salary restructuring. This involves analyzing your current salary structure and recommending adjustments to optimize your earnings while reducing tax liabilities. The CA can provide guidance on components like allowances, bonuses, and other perks, ensuring you maximize your income within the legal framework.
                                            </span>
                                        </div>
                                    </div>


                                </div>

                            </article>
                            <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                                <div class="blog-item-inner p-relative">
                                    <div class="left-box">
                                        <a href="" class="effect-ajax word-wrap" rel="bookmark">
                                            <h4 class="title-block">FREE ITR FILING      </h4>
                                        </a>

                                        <div class="link">
                                            <span class="font-caps heading-color">
                                                As part of the BasIn summary, the Basic Plan is a comprehensive package tailored for salaried individuals
                                                looking to proactively manage their taxes and improve their financial well-being.
                                                It includes expert guidance, salary optmization, and the convenience of free ITR filing,
                                                all aimed at ensuring you make the most of your income while complying with tax laws.ic Plan,
                                                you benefit from free Income
                                                Tax Return (ITR) filing services. AŌer the tax planning sessions and salary
                                                restructuring, the CA can assist you in accurately
                                                filing your income tax returns, helping you stay compliant with tax regulations.
                                                <br />

                                            </span>

                                        </div>
                                    </div>


                                </div>

                            </article>
                            <p className='mt-5 summart-text'>
                                <b> In summary, the Basic Plan is a comprehensive package</b>  tailored for salaried individuals
                                looking to proactively manage their taxes and improve
                                their financial well-being. It includes expert guidance, salary optimization,
                                and the convenience of free ITR filing,
                                all aimed at ensuring you make the most of your income while complying with tax laws.
                            </p>


                        </div>

                    </div>
                    <div class="container">
                        <div class="d-grid grid-md-2" data-dsn-gap="30px 60px">
                            <div class="left">
                                <h5 class="title-h2 body-font text-upper">
                                    <b>inclusion</b>
                                </h5>
                                <p class="mt-20">
                                    <div class="list-style1">
                                        <ul>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Income from House Rent.</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Capital gain.</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Crypto</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Salary from multiple Employer</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Other source income like (interest, FD, etc)</li>
                                        </ul>
                                    </div>
                                </p>
                            </div>
                            <div class="right align-self-end">
                                <h5 class="title-h2 body-font text-upper">
                                    <b> Non inclusion</b>
                                </h5>
                                <p class="mt-20">
                                    <div class="list-style1">
                                        <ul>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>CA Certification</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Foreign Income/Exposure like stock units,etc.</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Guide on investments products</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Advance Tax Management</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Business Statements/Books of Accounts</li>
                                            <li> <i class="far fa-circle" aria-hidden="true"></i>Free Lancing income</li>
                                        </ul>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <a href="#page_wrapper" rel="nofollow" class="dsn-scroll-bottom" data-dsn-option='{"ease": "power4.inOut" , "duration" : 1.5}'>
                    <div class="text">SCROLL</div>
                </a>
            </header>


            <Footer />
        </>
    )
}
