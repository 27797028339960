import React from "react";
import { Header } from "./Header";
import Footer from "./Footer";
export const AdvancePlan = () => {
  return (
    <>
      <Header />

      <header id="dsn_header" class="dsn-header-animation header-normal">
        <div class="background-overlay bg-pattern p-absolute left-0 top-0 w-100 h-100"></div>

        <div class="entry-header p-relative over-hidden">
          <div
            id="hero_content"
            class="d-flex p-relative h-100 dsn-hero-parallax-title container plan-main-card pb-section pb-0 "
          >
            <div class="content p-relative ">
              <div class="intro-project w-100">
                <div class="intro-title ">
                  {/* <p class="dsn-heading-title d-inline-block circle-before">BASIC PLAN </p> */}
                  <div id="hero_title" class="mt-10">
                    <h1 class="text-left">
                      Advance Plan
                      <br />
                    </h1>
                    <h5>
                      Price <del>8000</del> – Dis. Price 6799 Rs
                    </h5>
                  </div>
                  <p class="mt-20 max-w570">
                    <h4 className="mt-4">Key Features</h4>
                    <div class="list-style1">
                      <ul>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Basic
                          Plan is for the person who wants to plan his taxes.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Guide
                          on investments products.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Ideal
                          for salaried person having salary upto 30 Lakhs.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>3 Tax
                          Planning session with CA
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Salary
                          restructuring for salary optimization.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Free
                          Lancing Income.
                        </li>
                        <li>
                          {" "}
                          <i class="far fa-circle" aria-hidden="true"></i>Free
                          ITR Filing
                        </li>
                      </ul>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div class="root-blog container section-margin  dsn-blog margin-decrease-margin">
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">PURPOSE AND SCOPE </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        The Advanced Plan is tailored for individuals seeking
                        comprehensive tax planning and investment guidance. It
                        caters to salaried individuals with an annual income of
                        up to 30 Lakhs.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">
                        TAX PLANNING SESSIONS WITH CA{" "}
                      </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        The Advanced Plan offers three dedicated tax planning
                        sessions with a Chartered Accountant (CA). These
                        sessions allow for in-depth analysis of your financial
                        situation and enable the CA to provide personalized
                        tax-saving strategies.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">
                        SALARY RESTRUCTURING FOR SALARY OPTIMIZATION{" "}
                      </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        Similar to the Basic Plan, the Advanced Plan includes
                        salary restructuring. The CA will assess your current
                        salary structure and suggest adjustments to maximize
                        your income while minimizing tax liabilities.
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">Investment Product Guidance </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        A distinctive feature of the Advanced Plan is guidance
                        on investment products. You will receive recommendations
                        on suitable investment options that align with your
                        financial goals, risk tolerance, and tax-saving
                        objectives.
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <article class="blog-item p-relative pt-50 pb-50 border-bottom">
                <div class="blog-item-inner p-relative">
                  <div class="left-box">
                    <a href="" class="effect-ajax word-wrap" rel="bookmark">
                      <h4 class="title-block">FREE ITR FILING </h4>
                    </a>

                    <div class="link">
                      <span class="font-caps heading-color">
                        Just like the Basic Plan, the Advanced Plan includes
                        free Income Tax Return (ITR) filing services. A􀅌er tax
                        planning and investment discussions, the CA will assist
                        you in accurately filing your income tax returns,
                        ensuring compliance with tax regulations.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <p className="mt-5 summart-text">
                <b>In summary, the Advanced Plan goes</b> beyond tax planning by
                offering investment guidance, making it an ideal choice for
                salaried individuals seeking a holistic approach to financial
                management. It includes multiple tax planning sessions, salary
                optimization, and free ITR filing services to help you achieve
                your financial goals.
              </p>
            </div>
          </div>
          <div class="container">
            <div class="d-grid grid-md-2" data-dsn-gap="30px 60px">
              <div class="left">
                <h5 class="title-h2 body-font text-upper">
                  <b>inclusion</b>
                </h5>
                <p class="mt-20">
                  <div class="list-style1">
                    <ul>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Income
                        from House Rent.
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Capital
                        gain.
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Crypto
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Salary
                        from multiple Employer
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Other
                        source income like (interest, FD, etc)
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Salary
                        Advance Tax Management
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Salary
                        Free Lancing income
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
              <div class="right align-self-end">
                <h5 class="title-h2 body-font text-upper">
                  <b> Non inclusion</b>
                </h5>
                <p class="mt-20">
                  <div class="list-style1">
                    <ul>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>CA
                        Certification
                      </li>
                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Foreign
                        Income/Exposure like stock units,etc.
                      </li>

                      <li>
                        {" "}
                        <i class="far fa-circle" aria-hidden="true"></i>Business
                        Statements/Books of Accounts
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#page_wrapper"
          rel="nofollow"
          class="dsn-scroll-bottom"
          data-dsn-option='{"ease": "power4.inOut" , "duration" : 1.5}'
        >
          <div class="text">SCROLL</div>
        </a>
      </header>

      <Footer />
    </>
  );
};
