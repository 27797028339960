import React from "react";
import { Header } from "./Header";
import Footer from "./Footer";
import { PopupWidget, InlineWidget } from "react-calendly";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const PlanMonthlyIncome =({ formData, setForm, navigation }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(navigation);
        navigation.next();
      };
    const handlereset = async (e) => {
        e.preventDefault();
        console.log(navigation);
        navigation.previous();
      };
  return (
    <>
      <Header />
      <div className="main-user-forms">
        <div className="container">
          <div className="row">
            <form  onSubmit={handleSubmit} onReset={handlereset}>
              <div class="step step-3">
                <h3>Step 3</h3>

                <div class="assets-form ">
                  <div class="assets-heading">
                    <h5>Monthly Income</h5>
                    <p>
                      Your Income helps determining your preparedness for
                      Emergencies!{" "}
                    </p>
                  </div>

                  <div class="assets-grid-3">
                    <div class="assets-card text-center">
                      <h2>Salary Income (₹)</h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="assets-card text-center">
                      <h2>Business Income (₹)</h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="assets-card text-center">
                      <h2>Other Income (₹)</h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="assets-form">
                  <div class="assets-heading">
                    <h5>Monthly Spendings On Expenses</h5>
                    <p>
                      Your spendings help to determine, how much of Income you
                      save!{" "}
                    </p>
                  </div>
                  <div class="assets-grid-3">
                    <div class="assets-card text-center">
                      <h2>
                        Household <br /> Expenses (₹)
                      </h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="assets-card text-center">
                      <h2>Loan EMI's (₹)</h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="assets-card text-center">
                      <h2>Other Monthly Expenses (₹)</h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="assets-form">
                  <div class="assets-heading">
                    <h5>Monthly Spendings On Future Investments</h5>
                    <p>
                      Your spendings help to determine, how much of Income you
                      save!
                    </p>
                  </div>
                  <div class="assets-grid-3">
                    <div class="assets-card text-center">
                      <h2>
                        ULIP <br /> Premium (₹)
                      </h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="assets-card text-center">
                      <h2>
                        Mutual Fund <br /> Equity SIP (₹)
                      </h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div class="assets-card text-center">
                      <h2>
                        Mutual Fund <br /> Debt SIP (₹)
                      </h2>
                      <div class="assets-input">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex dsn-up assets-form ">
                  <div class="step-btn steps-all-btn" data-dsn="parallax">
                   
                      {" "}
                      <input type="reset" class="btn  prev-step"  value='Previous'/> 
                  
                 
                      <input type="submit" value="next" class="btn  next-step"/>

                  
                  </div>
                </div>

                {/* <!--<div class="mb-3">
                    <label for="field3" class="form-label">Field 3:</label>
                    <input type="text" class="form-control" id="field3" name="field3">
                    </div>-->
                    <!--<button type="button" class="btn  prev-step">Previous</button>
                <button type="submit" class="btn btn-success">Submit</button>--> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PlanMonthlyIncome;
